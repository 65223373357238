import React, { useState, useEffect } from "react";
import Background from "../../assets/image.webp";
import logo from "../../assets/newlogo.png";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Button, IconButton, Input, InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loginApi } from "../../slices/loginslice";
import { setToken, setEmails } from "../../slices/authInfo";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailerror, setemailerror] = useState();
  const [passworderror, setPassworderror] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const inputStyle = {
    backgroundColor: "#ededed",
    border: "1px solid  #ededed",
    "&focused": {
      border: "1px solid #ededed !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderRadius: "125px",
      border: "1px solid #ededed !important",
    },
  };

  const dispatch = useDispatch();

  const click = () => {
    setemailerror();
    setPassworderror();
    dispatch(
      loginApi({
        email: email,
        password: password,
      })
    ).then((res) => {
      if (res?.payload?.status_code === 200) {
        navigate("/daily");
        dispatch(setToken(res.payload));
        dispatch(setEmails(email));
      } else {
        if (res.payload?.data?.message?.includes("Please provide an email")) {
          setemailerror("please enter  email id ");
          return;
        }
        if (res.payload?.data?.message?.includes("Invalid email Id")) {
          setemailerror("please enter a valid email id ");
          return;
        }
        if (
          res.payload?.data?.message?.includes("Please provide a password.")
        ) {
          setPassworderror("please enter password");
          return;
        }
        if (res.payload?.data?.message?.includes("Invalid password")) {
          setPassworderror("password incorrect");
          return;
        }
      }
    });
  };

  return (
    <Grid
      container
      style={{
        height: "100vh",
        width: "100vw",
        backgroundImage: `url(${Background})`,
        objectFit: "cover",
        backgroundPosition: "center",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Grid
        item
        container
        xs={8}
        md={4}
        sm={5}
        lg={3}
        xl={2.5}
        style={{ height: "55vh", marginTop: "10vh" }}
      >
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <img src={logo} alt="" style={{ width: "20rem" }} />
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <Grid
              style={{
                color: "red",
                fontWeight: "bold",
                fontSize: "20px",
                marginRight: "10px",
              }}
            >
              *
            </Grid>
            <Grid
              style={{ fontWeight: "480", color: "white", fontSize: "12px" }}
            >
              {" "}
              Email ID{" "}
            </Grid>{" "}
          </Grid>
          <Grid item xs={12} style={{ marginTop: "0.4rem" }}>
            <TextField
              required={true}
              placeholder="Enter your Email ID"
              fullWidth
              size={"small"}
              InputProps={{ style: inputStyle }}
              sx={{
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#ededed",
                  },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          {emailerror && (
            <Grid xs={12} style={{ color: "red", marginTop: "0.4rem" }}>
              {" "}
              {emailerror}
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <Grid
              style={{
                color: "red",
                fontWeight: "bold",
                fontSize: "20px",
                marginRight: "10px",
              }}
            >
              *{" "}
            </Grid>{" "}
            <Grid style={{ fontWeight: "480", color: "white" }}>
              {" "}
              Password{" "}
            </Grid>{" "}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: "0.4rem",
              backgroundColor: "#ededed",
              borderRadius: "4px",
              position: " relative",
            }}
            container
          >
            <Grid item xs={12}>
              {" "}
              <TextField
                required={true}
                type={showPassword ? "text" : "password"}
                placeholder="Enter your Password"
                fullWidth
                size={"small"}
                InputProps={{ style: inputStyle }}
                sx={{
                  borderRadius: "0px",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "rgba(0,0,0,0)",
                      borderRadius: "0px",
                    },
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid
              onClick={() => setShowPassword(!showPassword)}
              style={{
                position: "absolute",
                right: "5px",
                cursor: "pointer",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </Grid>
          </Grid>
          {passworderror && (
            <Grid xs={12} style={{ color: "red", marginTop: "0.4rem" }}>
              {" "}
              {passworderror}{" "}
            </Grid>
          )}
        </Grid>
        <Grid
          onClick={() => navigate("/forgotPassword")}
          style={{
            color: "#ffffff",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {" "}
          Forgot Password ?
        </Grid>
        <Grid item xs={12} style={{ alignItems: "center", display: "flex" }}>
          <Button
            style={{
              backgroundColor: "#102633",
              color: "white",
              height: "3rem",
              width: "100%",
              margin: "auto",
              textTransform: "none",
            }}
            onClick={click}
          >
            Sign In
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            color: "white",
            justifyContent: "center",
            display: "flex",
            alignContent: " flex-end",
            flexWrap: "wrap",
            fontWeight: "250",
            fontSize: "12px",
          }}
        >
          Copyright © DIMAAG 2023
        </Grid>
      </Grid>
    </Grid>
  );
}

import axios, { CancelToken, isCancel } from "axios";

const baseUrl = process.env.REACT_APP_MAIN_URL;

const urls = {
  upload_url: `${baseUrl}/Upload/video`,
  get_video_data_url: `${baseUrl}/getChunkData`,
  get_machine_status: `${baseUrl}/machine_availability`,
};

const uploadVideo = async (
  file,
  token,
  email,
  onUploadProgress,
  cancelFileUpload,
  filename
) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("email", email);
  const response = await axios.post(
    `${baseUrl}/Upload/video?filename=${filename}`,
    formData,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onUploadProgress(percentCompleted);
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel)
      ),
      params: {
        email: email,
      },
    }
  );
  return response;
};

const getVideoData = async (idx, videoId, token, email) => {
  try {
    const response = await axios.get(urls.get_video_data_url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        idx: idx,
        video_id: videoId,
      },
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.error("Resource not found. Handle this error gracefully.", error);
      return error;
      // You can return a specific value or throw a custom error if needed
    } else {
      // Handle other errors
      console.error("An error occurred:", error);
      throw error; // Re-throw the error or handle it as needed
    }
  }
};

const getMachineData = async ({ email, token }) => {
  const response = await axios.get(
    `${urls.get_machine_status}?email=${email}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export { uploadVideo, getVideoData, getMachineData };

import React, { useEffect } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
} from "@mui/material";

function SelectCameraModal({ open, handleClose, selectedCamera, onChange }) {
  useEffect(() => {
    // close modal if press esc
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 380,
          bgcolor: "background.paper",
          boxShadow: 24,
          padding: "1rem 2rem",
          borderRadius: "6px",
        }}
      >
        <Grid style={{ padding: "1.25rem 0rem" }}>
          please select a camera in the factory to continue
        </Grid>
        <FormControl fullWidth style={{ paddingBottom: "0.25rem" }}>
          <InputLabel id="demo-simple-select-label">camera</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedCamera}
            label="camera"
            onChange={(e) => onChange(e.target.value)}
            size={"small"}
          >
            <MenuItem value={"184"}>T3-184</MenuItem>

            <MenuItem value={"199"}>T3-199</MenuItem>
            <MenuItem value={"1100"}>T3-1100</MenuItem>
            <MenuItem value={"1101"}>T3-1101</MenuItem>
            <MenuItem value={"1102"}>T3-1102</MenuItem>
            <MenuItem value={"1103"}>T3-1103</MenuItem>
            <MenuItem value={"1104"}>T3-1104</MenuItem>
            <MenuItem value={"1105"}>T3-1105</MenuItem>
            <MenuItem value={"1106"}>T3-1106</MenuItem>
            <MenuItem value={"1108"}>T3-1108</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Modal>
  );
}

export default SelectCameraModal;

// Dependency imports
import { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Papa, { parse } from "papaparse";
import FileDownload from "js-file-download";
import { useSelector, useDispatch } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Modal } from "@mui/material";
import { DotLoader } from "react-spinners";
// Local imports
import Navbar from "../shared/Navbar";
import Appbar from "../shared/Appbar";
import Plotly from "./plotly";
import LifeCycle from "./lifeCycle";
import DateSelector from "../shared/DateSelectorDaily";
import {
  getGraphData,
  setInitialDate,
  setDaily,
  availabledate,
  setSecondUser,
  DailyReports,
  getProductLifeCycleData,
  setImprovementTime,
  setProductLifeCycle,
} from "../../slices/authInfo";
import Graph from "./graph";
import Improvement from "./Improvement";
import { ReactComponent as Capsule } from "../../assets/capsule.svg";
import { ReactComponent as Circle } from "../../assets/circle.svg";
// import DailyAvg from "./dailyavg";

// Styles imports

import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";

function Index() {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [allowedDates, setAllowedDates] = useState([]);

  const [avg, setavg] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [operatorGraph, setOperatorGraph] = useState(true);
  const [lifeCycleData, setLifeCycleData] = useState([]);
  const [improvementGraph, setImprovementGraph] = useState(true);
  const [lifeCycleGraph, setLifeCycleGraph] = useState(true);
  const [graphCount, setGraphCount] = useState(0);
  const [entry, setEntry] = useState();
  const dispatch = useDispatch();

  const email = useSelector((state) => state?.authInfo.email);
  const [workStationDataImp, setWorkStationDataImp] = useState(false);
  const [workStationDataLife, setWorkStationDataLife] = useState(false);
  const [loading, setLoading] = useState();

  useEffect(() => {
    dispatch(availabledate()).then((res) => {
      if (
        res?.payload?.response?.data?.message.includes("Another User logged in")
      ) {
        dispatch(setSecondUser(true));
      } else {
        setAllowedDates(
          res?.payload?.available_dates?.filter(
            (date) => date != "Invalid date"
          )
        );
      }
    });
  }, []);

  useEffect(() => {
    if (allowedDates) {
      let date = allowedDates[allowedDates?.length - 1];
      call(date);
      setSelectedDate(date);
    }
  }, [allowedDates]);

  function call(date) {
    if (date) {
      setLoading(true);
      dispatch(
        getGraphData({
          type: "operator efficiency",
          date: date,
        })
      ).then((res) => {
        if (
          res?.payload?.response?.data?.message.includes(
            "Another User logged in"
          )
        ) {
          dispatch(setSecondUser(true));
          setLoading(false);
        } else {
          setData(res?.payload?.data);
          dispatch(
            getGraphData({
              type: "total improvement time",
              date: date,
            })
          ).then((res) => {
            if (
              res?.payload?.response?.data?.message.includes(
                "Another User logged in"
              )
            ) {
              setLoading(false);
              dispatch(setSecondUser(true));
            } else {
              setLoading(false);
              setavg(res?.payload?.Averages);
              setData2(res?.payload?.data);
              dispatch(setImprovementTime(res?.payload?.data));
              dispatch(
                getProductLifeCycleData({ email: email, date: date })
              ).then((res) => {
                dispatch(setProductLifeCycle(res.payload));
                setLifeCycleData(res.payload);
                setEntry(
                  ConvertTime(res.payload.EntryandExit.entryTimeofFirst)
                );
                setLoading(false);
              });
            }
          });
        }
      });
    }
  }

  function ConvertTime(time) {
    const [hours, mins, sec] = time.split(":");
    let hours2;
    let min2;
    let sec2;
    if (hours.length == 1) {
      hours2 = `0${hours}`;
    } else {
      hours2 = hours;
    }
    if (mins.length == 1) {
      min2 = `0${mins}`;
    } else {
      min2 = mins;
    }
    if (sec.length == 1) {
      sec2 = `0${sec}`;
    } else {
      sec2 = sec;
    }

    return `${hours2}:${min2}:${sec2}`;
  }

  useEffect(() => {
    const graphcountfunc = () => {
      if (operatorGraph && improvementGraph && lifeCycleGraph) {
        setGraphCount(3);
        return;
      }
      if (operatorGraph && improvementGraph) {
        setGraphCount(2);
        return;
      }
      if (lifeCycleGraph && improvementGraph) {
        setGraphCount(2);
        return;
      }
      if (lifeCycleGraph && operatorGraph) {
        setGraphCount(2);
        return;
      }
      if (operatorGraph) {
        setGraphCount(1);
        return;
      }
      if (lifeCycleGraph) {
        setGraphCount(1);
        return;
      }
      if (improvementGraph) {
        setGraphCount(1);
        return;
      }
    };
    graphcountfunc();
  }, [operatorGraph, lifeCycleGraph, improvementGraph]);

  return (
    <Grid
      style={{
        height: "100vh",
        width: "100vw",
        minWidth: "1024px",
        minHeight: "640px",
      }}
    >
      {loading && (
        <Grid
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.25)",
            zIndex: 555,
          }}
        >
          <DotLoader color="#141414" />
        </Grid>
      )}

      <div style={{ height: "10%", width: "100%" }}>
        <Appbar />
        <Navbar />
      </div>

      <Grid xs={12} style={{ height: "90%", backgroundColor: "#E3E3E3" }}>
        {/* date selector */}
        <Grid
          item
          xs={12}
          container
          style={{
            width: "100%",
            height: "12%",
            padding: "1rem 1rem 0.5rem 1rem",
            boxShadow:
              "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;",
          }}
        >
          <Grid
            container
            item
            xs={12}
            style={{
              border: "1px solid #9A9A9A",
              height: "100%",
              padding: "0px 1rem",
              backgroundColor: "white",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={7}
              style={{
                height: "100%",
                alignItems: "center",
                display: "flex",
              }}
            >
              <DateSelector
                setData={setData}
                setLifeCycleData={setLifeCycleData}
                setData2={setData2}
                label={"Choose Date"}
                setSelectedDate={setSelectedDate}
                availabledate={allowedDates}
                setavg={setavg}
                setEntry={setEntry}
                selectedDate={selectedDate}
                call={call}
              />
            </Grid>

            <Grid
              item
              xs={5}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <Button
                disabled={data?.length == 0 || data2?.length == 0}
                onClick={() => {
                  const date = selectedDate.split("-");
                  dispatch(DailyReports({ email, selectedDate })).then(
                    (res) => {
                      const blob = new Blob([res.payload], {
                        type: "application/zip",
                      });
                      const url = window.URL.createObjectURL(blob);
                      const a = document.createElement("a");
                      a.href = url;
                      a.download = `Daily_${date[1]}-${date[0]}-${date[2]}.zip`;
                      a.click();
                      window.URL.revokeObjectURL(url);
                    }
                  );
                }}
                style={{
                  color: "white",
                  fontSize: "0.6vw",
                  border: "1px solid black",
                  background: "#102633",
                  width: "7vw",
                }}
                startIcon={<DownloadIcon />}
              >
                download
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {graphCount < 3 && (
          <Grid
            style={{
              height: "5%",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {!operatorGraph && (
              <Grid
                onClick={() => setOperatorGraph(true)}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  borderRadius: "20px",
                  width: "240px",
                  cursor: "pointer",
                  margin: "0px 25px",
                  fontSize: "14px",
                }}
              >
                Operator Efficiency
                <VisibilityOffIcon />
              </Grid>
            )}

            {!improvementGraph && (
              <Grid
                onClick={() => setImprovementGraph(true)}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  borderRadius: "20px",
                  width: "240px",
                  cursor: "pointer",
                  margin: "0px 25px",
                }}
              >
                Total Improvement Time
                <VisibilityOffIcon />
              </Grid>
            )}

            {!lifeCycleGraph && (
              <Grid
                onClick={() => setLifeCycleGraph(true)}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  borderRadius: "20px",
                  width: "240px",

                  cursor: "pointer",
                  margin: "0px 25px",
                }}
              >
                Product Life Cycle
                <VisibilityOffIcon />
              </Grid>
            )}
          </Grid>
        )}

        {graphCount == 3 && (
          <Grid style={{ height: "88%", width: "100%" }}>
            <Grid
              style={{
                height: "50%",
                display: "flex",
                width: "100%",
              }}
            >
              <Grid
                style={{
                  width: "50%",
                  height: "100%",
                  // padding: "0.25rem 0.25rem 0.25rem 0.5rem",
                }}
              >
                <Improvement
                  graphdata={data2}
                  avg={avg}
                  setImprovementGraph={setImprovementGraph}
                  graphCount={graphCount}
                  workStationData={workStationDataImp}
                  setWorkStationData={setWorkStationDataImp}
                />
              </Grid>
              <Grid
                style={{
                  width: "50%",
                  height: "100%",
                  //padding: "0.25rem 0.25rem 0.25rem 0.5rem",
                }}
              >
                <Graph
                  graphdata={data}
                  type={"MAIN LINE"}
                  setOperatorGraph={setOperatorGraph}
                  graphCount={graphCount}
                />
              </Grid>
            </Grid>
            <Grid style={{ height: "50%" }}>
              <LifeCycle
                graphdata={lifeCycleData}
                improvementData={data2}
                type={"MAIN LINE"}
                setOperatorGraph={setLifeCycleGraph}
                graphCount={graphCount}
                entry={entry}
                workStationData={workStationDataLife}
                setWorkStationData={setWorkStationDataLife}
              />
            </Grid>
          </Grid>
        )}

        {graphCount == 2 && (
          <Grid style={{ height: "83%", width: "100%" }}>
            {lifeCycleGraph && (
              <Grid
                style={{
                  height: "50%",
                  display: "flex",
                  width: "100%",
                }}
              >
                <LifeCycle
                  graphdata={lifeCycleData}
                  improvementData={data2}
                  type={"MAIN LINE"}
                  setOperatorGraph={setLifeCycleGraph}
                  graphCount={graphCount}
                  entry={entry}
                  workStationData={workStationDataLife}
                  setWorkStationData={setWorkStationDataLife}
                />
              </Grid>
            )}
            {improvementGraph && (
              <Grid style={{ height: "50%" }}>
                <Improvement
                  workStationData={workStationDataImp}
                  setWorkStationData={setWorkStationDataImp}
                  graphdata={data2}
                  avg={avg}
                  setImprovementGraph={setImprovementGraph}
                  graphCount={graphCount}
                />
              </Grid>
            )}
            {operatorGraph && (
              <Grid style={{ height: "50%" }}>
                <Graph
                  graphdata={data}
                  type={"MAIN LINE"}
                  setOperatorGraph={setOperatorGraph}
                  graphCount={graphCount}
                />
              </Grid>
            )}
          </Grid>
        )}

        {graphCount == 1 && (
          <Grid style={{ height: "83%", width: "100%" }}>
            <Grid style={{ height: "100%", display: "flex", width: "100%" }}>
              <Grid style={{ width: "100%", height: "100%" }}>
                {lifeCycleGraph && (
                  <LifeCycle
                    graphdata={lifeCycleData}
                    improvementData={data2}
                    type={"MAIN LINE"}
                    setOperatorGraph={setLifeCycleGraph}
                    graphCount={graphCount}
                    entry={entry}
                    workStationData={workStationDataLife}
                    setWorkStationData={setWorkStationDataLife}
                  />
                )}
                {improvementGraph && (
                  <Improvement
                    graphdata={data2}
                    avg={avg}
                    setImprovementGraph={setImprovementGraph}
                    graphCount={graphCount}
                    workStationData={workStationDataImp}
                    setWorkStationData={setWorkStationDataImp}
                  />
                )}
                {operatorGraph && (
                  <Graph
                    graphdata={data}
                    type={"MAIN LINE"}
                    setOperatorGraph={setOperatorGraph}
                    graphCount={graphCount}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default Index;

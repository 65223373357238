// Dependencies imports
import { useState, useEffect } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";

function OperatorGraph() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const data = useSelector((state) => state?.authInfo?.operatorEfficency);
  const dispatch = useDispatch();
  const matchesLg = useMediaQuery("(min-width:1536px)");

  const chartData = {
    labels: ["P-Left", "P-Right"],
    datasets: [
      {
        label: "Improvement Time",
        data: [data?.left?.function || null, data?.right?.function || null],
        backgroundColor: "#4AB6DF",
        borderColor: "#04739E",
        borderWidth: 1,
      },
      {
        label: "Wait Time",
        data: [data?.left?.wait || null, data?.right?.wait || null],
        backgroundColor: "#7CFDA7",
        borderColor: "#0AA53E",
        borderWidth: 1,
      },
      {
        label: "Idle Time",
        data: [data?.left?.Idle || null, data?.right?.Idle || null],
        backgroundColor: "#F26565",
        borderColor: "#033A31",
        borderWidth: 1,
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    indexAxis: "y",
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
        title: {
          display: false,

          text: "Time (sec)  → ",
          color: "#000000",
          font: {
            size: matchesLg ? 12 : 8,
            padding: 0,
            weight: "bold",
          },
          position: "bottom",
        },
      },
      y: {
        grid: {
          display: true,
        },

        ticks: {
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
        title: {
          display: true,

          text: "Operators  →",
          color: "#000000",
          font: {
            size: matchesLg ? 16 : 10,
            padding: 0,
          },
          position: "bottom",
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        align: "end",
        labels: {
          boxWidth: 10,
          usePointStyle: true,
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
        itemMargin: 10,
      },
    },
  };
  return (
    <Grid
      style={{
        height: "100%",
        position: "relative",
        backgroundColor: "white",
        border: "1px solid  #9A9A9A",
      }}
    >
      <Grid
        style={{
          height: "10%",
          textAlign: "center",
          fontWeight: "500",
          fontSize: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        OPERATOR EFFICIENCY
      </Grid>
      <Grid style={{ height: "90%" }}>
        <Bar
          key={1}
          options={options}
          data={chartData}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Grid>

      <Grid
        style={{
          position: "absolute",
          bottom: 8,
          left: "10%",
          width: "15vw",
          fontSize: matchesLg ? 16 : 12,
        }}
      >
        Time (sec) →{" "}
      </Grid>
    </Grid>
  );
}

export default OperatorGraph;

import React, { useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import logo from "../../assets/newlogo.png";
import Background from "../../assets/image.webp";
import { useNavigate } from "react-router-dom";
import { generateOtp, resetPassword, verifyOtp } from "../../slices/loginslice";
import { useDispatch, useSelector } from "react-redux";
import { MuiOtpInput } from "mui-one-time-password-input";
import "./login.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const [newpassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassoword] = useState(false);
  const [confirmPassword, setconfirmPassword] = useState("");
  const [showconfirmPassword, setshowconfirmPassword] = useState(false);
  const [matchError, setMatchError] = useState("");
  const [emailerror, setemailerror] = useState();
  const [otpError, setOtpError] = useState();
  const [otp, setOtp] = useState("");
  const [state, setstate] = useState(1);
  const inputStyle = {
    backgroundColor: "#ededed",
    border: "1px solid  #ededed",
    "&focused": {
      border: "1px solid #ededed !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderRadius: "125px",
      border: "1px solid #ededed !important",
    },
  };
  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  return (
    <Grid
      container
      style={{
        height: "100vh",
        width: "100vw",
        backgroundImage: `url(${Background})`,
        backgroundBlendMode: "lighten",
        objectFit: "cover",
        backgroundPosition: "center",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Grid
        item
        container
        xs={8}
        md={4}
        sm={5}
        lg={3}
        xl={2.5}
        style={{ height: "55vh", marginTop: "10vh" }}
      >
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <img src={logo} alt="" style={{ width: "20rem" }} />
        </Grid>

        {state == 1 && (
          <>
            <Grid item xs={12}>
              <Grid style={{ color: "#FFFFFF" }}> Forgot Password</Grid>
              <Grid
                style={{
                  color: "#FFFFFF",
                  fontSize: "12px",
                  marginTop: "12px",
                }}
              >
                {" "}
                please enter your Email ID to receive verification code{" "}
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "12px",
                }}
              >
                {" "}
                <Grid
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginRight: "10px",
                  }}
                >
                  *{" "}
                </Grid>{" "}
                <Grid
                  style={{
                    fontWeight: "480",
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  Email ID{" "}
                </Grid>{" "}
              </Grid>
              <Grid item xs={12} style={{ marginTop: "0.5rem" }}>
                <TextField
                  required={true}
                  placeholder="Type here"
                  fullWidth
                  size={"small"}
                  InputProps={{ style: inputStyle }}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#ededed",
                      },
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              {emailerror && (
                <Grid xs={12} style={{ color: "red", marginTop: "0.4rem" }}>
                  {" "}
                  {emailerror}
                </Grid>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              style={{ alignItems: "center", display: "flex" }}
            >
              <Button
                disabled={!email}
                onClick={() => {
                  setemailerror(null);
                  dispatch(generateOtp({ email })).then((res) => {
                    if (res.payload.status == 400) {
                      setemailerror("Email not exits");
                      return;
                    }
                    // if (res.payload.status != 200) {
                    //   setemailerror("Something went wrong");
                    //   return;
                    // }
                    if (res?.payload?.OTP) {
                      alert(
                        `One Time Verification Code to Reset Password: ${res?.payload?.OTP}`
                      );
                    }
                    setstate(2);
                  });
                }}
                style={{
                  backgroundColor: "#102633",
                  color: "white",
                  height: "3rem",
                  width: "100%",
                  margin: "auto",
                }}
              >
                Send
              </Button>
            </Grid>
            <Grid
              style={{
                color: "#ffffff",
                textDecoration: "underline",
                cursor: "pointer ",
                fontSize: "12px",
                width: "100%",
                textAlign: "center",
              }}
              onClick={() => navigate("/")}
            >
              back to Sign in ?{" "}
            </Grid>
          </>
        )}

        {state == 2 && (
          <>
            <Grid container item xs={12}>
              <Grid item xs={12} style={{ color: "#FFFFFF" }}>
                {" "}
                Enter Verification Code
              </Grid>
              {/* <Grid
                item
                xs={12}
                style={{
                  color: "#FFFFFF",
                  fontSize: "12px",
                  marginTop: "12px",
                }}
              >
                {`  we have sent you the verification code to ${email} `}
              </Grid> */}

              <MuiOtpInput
                value={otp}
                onChange={handleChange}
                length={6}
                TextFieldsProps={{ size: "small" }}
                className="otp"
              />

              {/* <Grid style={{ color: "#ffffff", fontSize: "10px" }}>
                {" "}
                didnt receved the OTP change email id or click here to resend{" "}
              </Grid> */}

              <Grid
                item
                xs={12}
                style={{ alignItems: "center", display: "flex" }}
              >
                <Button
                  disabled={!email}
                  onClick={() => {
                    dispatch(verifyOtp({ email, otp: otp })).then((res) => {
                      console.log();
                      if (res.payload.message == "Otp is verified") {
                        setstate(3);
                      } else {
                        setOtpError("Wrong OTP");
                      }
                    });
                  }}
                  style={{
                    backgroundColor: "#102633",
                    color: "#ffffff",
                    height: "3rem",
                    width: "100%",
                    margin: "auto",
                  }}
                >
                  verify
                </Button>
              </Grid>
              {otpError && (
                <Grid style={{ color: "red", fontSize: "12px" }}>
                  *{otpError}
                </Grid>
              )}
            </Grid>
          </>
        )}

        {state == 3 && (
          <>
            <Grid container item xs={12}>
              <Grid item xs={12} style={{ color: "#FFFFFF" }}>
                Set New Password
              </Grid>

              <Grid item xs={12}>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {" "}
                  <Grid
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "20px",
                      marginRight: "10px",
                    }}
                  >
                    *{" "}
                  </Grid>{" "}
                  <Grid
                    style={{
                      fontWeight: "480",
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    New Password{" "}
                  </Grid>{" "}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: "0.4rem",
                    backgroundColor: "#ededed",
                    borderRadius: "4px",
                    position: " relative",
                  }}
                  container
                >
                  <Grid item xs={12}>
                    {" "}
                    <TextField
                      required={true}
                      type={showNewPassword ? "text" : "password"}
                      placeholder="Type here"
                      fullWidth
                      size={"small"}
                      InputProps={{ style: inputStyle }}
                      sx={{
                        borderRadius: "0px",
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "rgba(0,0,0,0)",
                            borderRadius: "0px",
                          },
                      }}
                      value={newpassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </Grid>

                  <Grid
                    onClick={() => setShowNewPassoword(!showNewPassword)}
                    style={{
                      position: "absolute",
                      right: "5px",
                      cursor: "pointer",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {showNewPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {" "}
                  <Grid
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "20px",
                      marginRight: "10px",
                    }}
                  >
                    *{" "}
                  </Grid>{" "}
                  <Grid
                    style={{
                      fontWeight: "480",
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    Confirm Password{" "}
                  </Grid>{" "}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: "0.4rem",
                    backgroundColor: "#ededed",
                    borderRadius: "4px",
                    position: " relative",
                  }}
                  container
                >
                  <Grid item xs={12}>
                    {" "}
                    <TextField
                      required={true}
                      type={showconfirmPassword ? "text" : "password"}
                      placeholder="Type here"
                      fullWidth
                      size={"small"}
                      InputProps={{ style: inputStyle }}
                      sx={{
                        borderRadius: "0px",
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "rgba(0,0,0,0)",
                            borderRadius: "0px",
                          },
                      }}
                      value={confirmPassword}
                      onChange={(e) => setconfirmPassword(e.target.value)}
                    />
                  </Grid>
                  <Grid
                    onClick={() => setshowconfirmPassword(!showconfirmPassword)}
                    style={{
                      position: "absolute",
                      right: "5px",
                      cursor: "pointer",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {showconfirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ alignItems: "center", display: "flex" }}
              >
                <Button
                  onClick={() => {
                    if (newpassword == confirmPassword) {
                      dispatch(
                        resetPassword({ email, newpassword: newpassword })
                      ).then((res) => {
                        if (
                          res.payload.message == "password updated sucessfully"
                        ) {
                          navigate("/");
                        }
                      });
                    } else {
                      setMatchError("passwords dose not matchs ");
                    }
                  }}
                  style={{
                    backgroundColor: "#102633",
                    color: "white",
                    height: "3rem",
                    width: "100%",
                    margin: "auto",
                  }}
                >
                  set password
                </Button>
              </Grid>
              {matchError && (
                <Grid style={{ color: "red", fontSize: "14px" }}>
                  {" "}
                  {matchError}{" "}
                </Grid>
              )}
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          style={{
            color: "white",
            justifyContent: "center",
            display: "flex",
            alignContent: " flex-end",
            flexWrap: "wrap",
            fontWeight: "250",
            fontSize: "12px",
          }}
        >
          Copyright © DIMAAG 2023
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ForgotPassword;

// Dependency imports configuration
import { useState, useRef, useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  Button,
  Grid,
  Modal,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import EditIcon from "@mui/icons-material/Edit";
import DatePicker from "react-datepicker";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useDispatch, useSelector } from "react-redux";
import ScheduleIcon from "@mui/icons-material/Schedule";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Swal from "sweetalert2";

// Local imports
import DaysSelector from "./DaysSelector";
import Appbar from "../shared/Appbar";
import Navbar from "../shared/Navbar";
import DateSelector from "../shared/DateSelector2";
import {
  getConfiguration,
  updateConfiguration,
  deleteConfiguration,
} from "../../slices/authInfo";

// Styles imports
import "react-datepicker/dist/react-datepicker.css";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  gap: "0.2vw",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#102633",
  color: "white",
  borderColor: "grey",
  "&:hover": {
    backgroundColor: "#102658",
    borderColor: "grey",
    boxShadow: "none",
    cursor: "pointer",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "grey",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const Config = () => {
  const [batchType, setBatchType] = useState("daily");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedDays, setSelectedDays] = useState([]);
  const [disableEdit, setDisableEdit] = useState(true);
  const [editButtonDisabled, setEditButtonDisabled] = useState(true);
  const [scheduleButtonDisabled, setScheduleButtonDisabled] = useState(true);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const role = useSelector((state) => state?.authInfo.loginToken.role);

  useEffect(() => {
    dispatch(getConfiguration()).then((res) => {
      setAllConfiguration(res.payload.data);
    });
  }, []);

  const handleDelete = () => {
    dispatch(deleteConfiguration()).then((res) => {
      setDeleteModal(false);

      Swal.fire({
        icon: "success",
        title: "Success",
        text: res.payload.data.message,
        showCloseButton: true,
        showCancelButton: false,
        closeButtonAriaLabel: "Got it!",
        allowOutsideClick: false,
      }).then((result) => {
        setStartDate();
        setEndDate();
        setBatchType("daily");
        setSelectedDays([]);
        setDisableEdit(false);
        setEditButtonDisabled(true);
        setDeleteButtonDisabled(true);
        setScheduleButtonDisabled(false);
      });
    });
  };

  const setAllConfiguration = (data) => {
    if (data?.data.mode !== "") {
      setDeleteButtonDisabled(false);
      setDisableEdit(true);
      setScheduleButtonDisabled(true);
      setEditButtonDisabled(false);
      setBatchType(data?.data.mode);
      setStartDate(convertDateFormatToYYMMDD(data.data.start_date));
      setEndDate(convertDateFormatToYYMMDD(data.data.end_date));
      setSelectedDays(data.data.week_days);
    } else {
      setDisableEdit(false);
      setEditButtonDisabled(true);
      setDeleteButtonDisabled(true);
      setScheduleButtonDisabled(false);
    }
  };

  function convertDateFormatToYYMMDD(inputDate) {
    const parsedDate = moment(inputDate, "YYYY-MM-DD");
    const formattedDate = parsedDate.format("DD-MM-YYYY");
    return formattedDate;
  }

  function convertDateFormatToDDMMYY(inputDate) {
    const parsedDate = moment(inputDate, "DD-MM-YYYY");
    const formattedDate = parsedDate.format("YYYY-MM-DD");
    return formattedDate;
  }

  function convertDateFormatToMMDDYY(inputDate) {
    const parsedDate = moment(inputDate, "DD-MM-YYYY");
    const formattedDate = parsedDate.format("MM-DD-YYYY");
    return formattedDate;
  }

  const handleSchedule = () => {
    const updatedData = {};
    updatedData["start_date"] = convertDateFormatToDDMMYY(startDate);
    updatedData["end_date"] = convertDateFormatToDDMMYY(endDate);
    updatedData["type"] = batchType;

    if (batchType === "weekly") {
      updatedData["week_days"] = selectedDays;
    }

    const startDateMoment = moment(updatedData.start_date, "YYYY-MM-DD");
    const endDateMoment = moment(updatedData.end_date, "YYYY-MM-DD");

    if (
      startDateMoment.isBefore(endDateMoment) ||
      updatedData.start_date == updatedData.end_date
    ) {
      dispatch(updateConfiguration(updatedData)).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.payload.message,
          showCloseButton: true,
          showCancelButton: false,
          closeButtonAriaLabel: "Got it!",
          allowOutsideClick: false,
        }).then((result) => {
          setDisableEdit(true);
          setEditButtonDisabled(false);
          setScheduleButtonDisabled(true);
          setDeleteButtonDisabled(false);
          dispatch(getConfiguration()).then((res) => {
            setAllConfiguration(res.payload.data);
          });
        });
      });
    } else if (startDateMoment.isAfter(endDateMoment)) {
      Swal.fire({
        icon: "warning",
        title: "warning",
        text: "end date should be greater than or equal to start date",
        showCloseButton: true,
        showCancelButton: false,
        closeButtonAriaLabel: "Got it!",
        allowOutsideClick: false,
      });
    }
  };

  function generateText(daysArray, startDay, endDate) {
    const dayValues = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    };

    const sortedDays = daysArray.sort((a, b) => dayValues[a] - dayValues[b]);
    const daysText = sortedDays.join(", ");
    return `Occurs every ${daysText} from ${startDay} to ${endDate}.`;
  }

  const handleModalClose = () => {
    setDeleteModal(false);
  };

  return (
    <div style={{ height: "100vh", minWidth: "1024px", minHeight: "640px" }}>
      <div style={{ height: "10%" }}>
        <Appbar />
        <Navbar />
      </div>

      <div
        style={{
          padding: "1vw",
          background: "#ededed",
        }}
      >
        <Accordion expanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Batch Inference Preference</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  style={{ gap: "5vw" }}
                >
                  <FormControlLabel
                    value={batchType}
                    onChange={(e) => {
                      setBatchType("daily");
                      setScheduleButtonDisabled(false);
                    }}
                    control={<Radio />}
                    disabled={disableEdit || role !== "admin"}
                    label="Daily"
                    checked={batchType === "daily" ? true : false}
                  />
                  <FormControlLabel
                    value={batchType}
                    disabled={disableEdit || role !== "admin"}
                    onChange={(e) => {
                      setBatchType("weekly");
                      setScheduleButtonDisabled(false);
                    }}
                    control={<Radio />}
                    label="Weekly"
                    checked={batchType === "weekly" ? true : false}
                  />
                </RadioGroup>
              </FormControl>

              <Grid container style={{ width: "100%", margin: "1.5rem 0rem" }}>
                <Grid item xs={4}>
                  {" "}
                  <DateSelector
                    label={"Start date"}
                    setSelectedDate={setStartDate}
                    selectedDate={startDate}
                    disabled={disableEdit || role !== "admin"}
                    maxDate={endDate}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DateSelector
                    minDate={startDate}
                    label={"End date"}
                    setSelectedDate={setEndDate}
                    selectedDate={endDate}
                    disabled={disableEdit || role !== "admin"}
                  />{" "}
                </Grid>
              </Grid>

              {batchType === "weekly" && !disableEdit ? (
                <DaysSelector
                  selectedDays={selectedDays}
                  setSelectedDays={setSelectedDays}
                  setScheduleButtonDisabled={setScheduleButtonDisabled}
                />
              ) : null}
              {batchType === "weekly" && selectedDays.length === 0 ? (
                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    marginTop: "30px",
                    color: "#d50000",
                  }}
                >
                  Please select the days in the week
                </h3>
              ) : null}
              {disableEdit && batchType === "weekly" ? (
                <h3
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    marginTop: "30px",
                  }}
                >
                  {generateText(
                    selectedDays,
                    convertDateFormatToMMDDYY(startDate),
                    convertDateFormatToMMDDYY(endDate)
                  )}
                </h3>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "3vw",
              }}
            >
              <BootstrapButton
                disabled={editButtonDisabled || role !== "admin"}
                variant="contained"
                startIcon={<EditIcon />}
                onClick={() => {
                  setDisableEdit(false);
                  setDeleteButtonDisabled(true);
                  setScheduleButtonDisabled(false);
                  setEditButtonDisabled(true);
                }}
              >
                Edit
              </BootstrapButton>
              <BootstrapButton
                disabled={deleteButtonDisabled || role !== "admin"}
                variant="contained"
                startIcon={<DeleteOutlineIcon />}
                onClick={() => setDeleteModal(true)}
              >
                Remove Schedule
              </BootstrapButton>
              <BootstrapButton
                disabled={scheduleButtonDisabled || role !== "admin"}
                variant="contained"
                startIcon={<ScheduleIcon />}
                onClick={handleSchedule}
              >
                Schedule
              </BootstrapButton>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion disabled>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Saved Video Location</Typography>
          </AccordionSummary>
        </Accordion>
        <Accordion disabled>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>Camera Configuration</Typography>
          </AccordionSummary>
        </Accordion>
      </div>
      <Modal
        open={deleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleModalClose}
      >
        <Grid
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 420,
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          <Typography
            id="modal-modal-description"
            style={{ textAlign: "center", padding: "1.5rem" }}
          >
            Are you sure that you want to delete the configuration?
          </Typography>

          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              onClick={handleModalClose}
              style={{
                backgroundColor: "white",
                border: "1px solid #102633",
                width: "50%",
                borderRadius: 0,
                color: "#102633",
              }}
            >
              cancel
            </Button>
            <Button
              style={{
                color: "white",
                border: "1px solid #102633",
                width: "50%",
                borderRadius: 0,
                backgroundColor: "#102633",
              }}
              onClick={handleDelete}
            >
              delete
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default Config;

import { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { useMediaQuery, Button } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import "chartjs-adapter-date-fns";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
ChartJS.register(
  TimeScale,
  LinearScale,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

function Lifecycle({
  graphdata,
  type,
  setOperatorGraph,
  graphCount,
  entry,
  improvementData,
  workStationData,
  setWorkStationData,
}) {
  // const graphdata = useSelector((state) => state?.authInfo.productLifeCycle);

  const chartRef = useRef(null);
  const [chartKey, setChartKey] = useState(0);
  const matchesLg = useMediaQuery("(min-width:1300px)");
  const matchesXlg = useMediaQuery("(min-width:1600px)");
  const [index, setindex] = useState(0);
  const [filters, setFilters] = useState({
    Z200: true,
    "Z200 small": true,
    Z400: true,
  });
  const [filterws, setfilterws] = useState({
    "SELECT ALL": true,
    1106: true,
    1105: true,
    1104: true,
    1108: true,
    1103: true,
    1102: true,
    1101: true,
    184: true,
    1100: true,
    199: true,
  });
  const handleButtonClick2 = (modelName) => {
    if (modelName == "SELECT ALL") {
      if (filterws["SELECT ALL"]) {
        setfilterws({
          "SELECT ALL": false,
          1106: false,
          1105: false,
          1104: false,
          1108: false,
          1103: false,
          1102: false,
          1101: false,
          184: false,
          1100: false,
          199: false,
        });
      } else {
        setfilterws({
          "SELECT ALL": true,
          1106: true,
          1105: true,
          1104: true,
          1108: true,
          1103: true,
          1102: true,
          1101: true,
          184: true,
          1100: true,
          199: true,
        });
      }
    } else {
      setfilterws((prevFilters) => ({
        ...prevFilters,
        [modelName]: !prevFilters[modelName],
      }));
    }
  };
  const [filteredData, setFilteredData] = useState(graphdata.data);

  const GreySwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: grey[900],
      "&:hover": {
        backgroundColor: alpha(grey[900], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: grey[900],
    },
  }));

  useEffect(() => {
    const updatedData = graphdata?.data?.filter((item) => filters[item.Model]);
    setFilteredData(updatedData);
  }, [graphdata, filters]);

  const handleButtonClick = (modelName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [modelName]: !prevFilters[modelName],
    }));
  };

  useEffect(() => {
    setindex(0);
  }, [graphdata]);
  function convertToDateTime(timeString, type) {
    const hardcodedDate = "2022-11-01";
    const [hours, minutes, seconds] = timeString?.split(":");
    let hours2;
    let minutes2;
    let seconds2;
    if (hours?.length == 1) {
      hours2 = `0${hours}`;
    } else {
      hours2 = `${hours}`;
    }

    if (minutes?.length == 1) {
      minutes2 = `0${minutes}`;
    } else {
      minutes2 = `${minutes}`;
    }

    if (seconds?.length == 1) {
      seconds2 = `0${seconds}`;
    } else {
      seconds2 = `${seconds}`;
    }
    if (type) {
      return `${hours2}:${minutes2}:${seconds2}`;
    }

    return `${hardcodedDate}T${hours2}:${minutes2}:${seconds2}`;
  }

  useEffect(() => {
    setFilters({
      Z200: true,
      "Z200 small": true,
      Z400: true,
    });
  }, [workStationData]);
  const WorkstationColors = {
    1106: "rgb(130, 203, 255)",
    1105: "rgb(14, 110, 197)",
    1104: "rgb(255, 169, 176)",
    1108: "rgb(251, 46, 53)",
    1103: "rgb(129, 238, 161)",
    1102: "rgb(52, 173, 161)",
    1101: "rgb(251, 209, 115)",
    184: "rgb(255, 136, 31)",
    1100: "rgb(162, 135, 210)",

    199: "rgb(108, 70, 184)",
  };
  const workStationColorArray = [
    { name: "SELECT ALL", color: "#5E5E5E" },
    { name: 199, color: "rgb(108, 70, 184)" },
    { name: 1100, color: "rgb(162, 135, 210)" },
    { name: 184, color: "rgb(255, 136, 31)" },
    { name: 1101, color: "rgb(251, 209, 115)" },
    { name: 1102, color: "rgb(52, 173, 161)" },
    { name: 1103, color: "rgb(129, 238, 161)" },
    { name: 1108, color: "rgb(251, 46, 53)" },
    { name: 1104, color: "rgb(255, 169, 176)" },
    { name: 1105, color: "rgb(14, 110, 197)" },
    { name: 1106, color: "rgb(130, 203, 255)" },
  ];
  const GenerateGraphData = (data1) => {
    const units = [];

    const dataset = [];
    const backgroundColor = [];
    if (workStationData) {
      if (data1?.length > 1 && entry) {
        data1?.forEach((data, index) => {
          units.push(index);
          data.timings.forEach((element) => {
            if (element.entry && element.exit) {
              if (element.workstation == "199" && filterws[199]) {
                dataset.push({
                  x: [
                    convertToDateTime(element.entry),
                    convertToDateTime(element.exit),
                  ],
                  y: index,
                  ws: element.workstation,
                  unit: index,
                });
                if (data.completed) {
                  backgroundColor.push(WorkstationColors[element.workstation]);
                } else {
                  backgroundColor.push(
                    createDiagonalPattern(
                      WorkstationColors[element.workstation]
                    )
                  );
                }
              }
              if (element.workstation == "1100" && filterws[1100]) {
                dataset.push({
                  x: [
                    convertToDateTime(element.entry),
                    convertToDateTime(element.exit),
                  ],
                  y: index,
                  ws: element.workstation,
                  unit: index,
                });
                if (data.completed) {
                  backgroundColor.push(WorkstationColors[element.workstation]);
                } else {
                  backgroundColor.push(
                    createDiagonalPattern(
                      WorkstationColors[element.workstation]
                    )
                  );
                }
              }
              if (element.workstation == "184" && filterws[184]) {
                dataset.push({
                  x: [
                    convertToDateTime(element.entry),
                    convertToDateTime(element.exit),
                  ],
                  y: index,
                  ws: element.workstation,
                  unit: index,
                });
                if (data.completed) {
                  backgroundColor.push(WorkstationColors[element.workstation]);
                } else {
                  backgroundColor.push(
                    createDiagonalPattern(
                      WorkstationColors[element.workstation]
                    )
                  );
                }
              }
              if (element.workstation == "1101" && filterws[1101]) {
                dataset.push({
                  x: [
                    convertToDateTime(element.entry),
                    convertToDateTime(element.exit),
                  ],
                  y: index,
                  ws: element.workstation,
                  unit: index,
                });
                if (data.completed) {
                  backgroundColor.push(WorkstationColors[element.workstation]);
                } else {
                  backgroundColor.push(
                    createDiagonalPattern(
                      WorkstationColors[element.workstation]
                    )
                  );
                }
              }
              if (element.workstation == "1102" && filterws[1102]) {
                dataset.push({
                  x: [
                    convertToDateTime(element.entry),
                    convertToDateTime(element.exit),
                  ],
                  y: index,
                  ws: element.workstation,
                  unit: index,
                });
                if (data.completed) {
                  backgroundColor.push(WorkstationColors[element.workstation]);
                } else {
                  backgroundColor.push(
                    createDiagonalPattern(
                      WorkstationColors[element.workstation]
                    )
                  );
                }
              }
              if (element.workstation == "1103" && filterws[1103]) {
                dataset.push({
                  x: [
                    convertToDateTime(element.entry),
                    convertToDateTime(element.exit),
                  ],
                  y: index,
                  ws: element.workstation,
                  unit: index,
                });
                if (data.completed) {
                  backgroundColor.push(WorkstationColors[element.workstation]);
                } else {
                  backgroundColor.push(
                    createDiagonalPattern(
                      WorkstationColors[element.workstation]
                    )
                  );
                }
              }

              if (element.workstation == "1108" && filterws[1108]) {
                dataset.push({
                  x: [
                    convertToDateTime(element.entry),
                    convertToDateTime(element.exit),
                  ],
                  y: index,
                  ws: element.workstation,
                  unit: index,
                });
                if (data.completed) {
                  backgroundColor.push(WorkstationColors[element.workstation]);
                } else {
                  backgroundColor.push(
                    createDiagonalPattern(
                      WorkstationColors[element.workstation]
                    )
                  );
                }
              }

              if (element.workstation == "1104" && filterws[1104]) {
                dataset.push({
                  x: [
                    convertToDateTime(element.entry),
                    convertToDateTime(element.exit),
                  ],
                  y: index,
                  ws: element.workstation,
                  unit: index,
                });
                if (data.completed) {
                  backgroundColor.push(WorkstationColors[element.workstation]);
                } else {
                  backgroundColor.push(
                    createDiagonalPattern(
                      WorkstationColors[element.workstation]
                    )
                  );
                }
              }

              if (element.workstation == "1105" && filterws[1105]) {
                dataset.push({
                  x: [
                    convertToDateTime(element.entry),
                    convertToDateTime(element.exit),
                  ],
                  y: index,
                  ws: element.workstation,
                  unit: index,
                });
                if (data.completed) {
                  backgroundColor.push(WorkstationColors[element.workstation]);
                } else {
                  backgroundColor.push(
                    createDiagonalPattern(
                      WorkstationColors[element.workstation]
                    )
                  );
                }
              }
              if (element.workstation == "1106" && filterws[1106]) {
                dataset.push({
                  x: [
                    convertToDateTime(element.entry),
                    convertToDateTime(element.exit),
                  ],
                  y: index,
                  ws: element.workstation,
                  unit: index,
                });
                if (data.completed) {
                  backgroundColor.push(WorkstationColors[element.workstation]);
                } else {
                  backgroundColor.push(
                    createDiagonalPattern(
                      WorkstationColors[element.workstation]
                    )
                  );
                }
              }
            }
            // backgroundColor.push(getColorWorkStation(data.Model, element.rank));
          });
        });
      }
      return {
        labels: units,
        datasets: [
          {
            label: "365",
            data: dataset,
            backgroundColor: backgroundColor,
          },
        ],
      };
    } else {
      if (data1?.length > 1 && entry) {
        data1?.forEach((data, index) => {
          // console.log(data);
          units.push(index);
          backgroundColor.push(getColor(data.Model, data.completed));
          dataset.push({
            x: [
              convertToDateTime(data.EntryTime),
              convertToDateTime(data.ExitTime),
            ],
            y: index,
            completed: data.completed,
          });
        });
      }

      return {
        labels: units,
        datasets: [
          {
            label: "365",
            data: dataset,
            backgroundColor: backgroundColor,
          },
        ],
      };
    }
  };

  function createDiagonalPattern(color) {
    let shape = document.createElement("canvas");
    shape.width = 10;
    shape.height = 10;

    let c = shape.getContext("2d");

    c.fillStyle = color;
    c.fillRect(0, 0, shape.width, shape.height);

    c.strokeStyle = "#666";
    c.lineWidth = 1;
    c.beginPath();
    c.moveTo(2, 0);
    c.lineTo(10, 8);
    c.stroke();

    c.beginPath();
    c.moveTo(0, 8);
    c.lineTo(2, 10);
    c.stroke();

    return c.createPattern(shape, "repeat");
  }
  function getColor(model, completed) {
    if (model == "Z400") {
      if (completed) {
        return "rgba(251, 201, 155)";
      } else {
        return createDiagonalPattern("rgba(251, 201, 155)");
      }
    }
    if (model == "Z200") {
      if (completed) {
        return "rgba(255, 181, 197, 1)";
      } else {
        return createDiagonalPattern("rgba(255, 181, 197)");
      }
    }
    if (model == "Z200 small") {
      if (completed) {
        return "rgba(164, 223, 223, 1)";
      } else {
        return createDiagonalPattern("rgba(164, 223, 223)");
      }
    }
  }

  function getColorWorkStation(model, rank) {
    if (model == "Z400") {
      return Z400colors[rank];
    }
    if (model == "Z200") {
      return Z200colors[rank];
    }
    if (model == "Z200 small") {
      return Z200_smallcolors[rank];
    }
  }

  const Z200colors = {
    1: "rgb(101, 4, 19)",
    2: "rgb(134, 5, 25)",
    3: "rgb(177, 17, 41)",
    4: "rgb(207, 42, 67)",
    5: "rgb(232, 67, 92)",
    6: "rgb(236, 110, 128)",
    7: "rgb(232, 138, 152)",
    8: "rgb(245, 175, 185)",
    9: "rgb(247, 202, 208)",
    10: "rgb(250, 224, 228)",
  };

  const Z400colors = {
    1: "rgb(157, 91, 17)",
    2: "rgb(208, 114, 9)",
    3: "rgb(215, 133, 19)",
    4: "rgb(222, 152, 30)",
    5: "rgb(226, 162, 36)",
    6: "rgb(232, 179, 45)",
    7: "rgb(237, 194, 53)",
    8: "rgb(244, 211, 63)",
    9: "rgb(249, 225, 70)",
    10: "rgb(255, 245, 124)",
  };

  const Z200_smallcolors = {
    1: "rgb(27, 82, 103)",
    2: "rgb(44, 98, 116)",
    3: "rgb(70, 124, 135)",
    4: "rgb(90, 143, 150)",
    5: "rgb(88, 163, 171)",
    6: "rgb(115, 187, 185)",
    7: "rgb(131, 202, 194)",
    8: "rgb(148, 218, 203)",
    9: "rgb(164, 233, 211)",
    10: "rgb(203, 255, 234)",
  };

  function calculateDuration(startTime, endTime, type) {
    const startDate = new Date(`1970-01-01T${startTime}Z`);
    const endDate = new Date(`1970-01-01T${endTime}Z`);

    const timeDifference = endDate - startDate;

    const hours = `${Math.floor(timeDifference / 3600000)}`;
    const minutes = `${Math.floor((timeDifference % 3600000) / 60000)}`;
    const seconds = `${Math.floor((timeDifference % 60000) / 1000)}`;

    if (type) {
      return timeDifference / 1000;
    }

    let hours2;
    let minutes2;
    let seconds2;
    if (hours?.length == 1) {
      hours2 = `0${hours}`;
    } else {
      hours2 = `${hours}`;
    }

    if (minutes?.length == 1) {
      minutes2 = `0${minutes}`;
    } else {
      minutes2 = `${minutes}`;
    }

    if (seconds?.length == 1) {
      seconds2 = `0${seconds}`;
    } else {
      seconds2 = `${seconds}`;
    }

    return `${hours2}:${minutes2}:${seconds2} `;
  }
  function convertSecondsToTime(seconds) {
    if (isNaN(seconds) || seconds < 0) {
      return "Invalid input";
    }

    const hours = `${Math.floor(seconds / 3600)}`;
    const minutes = `${Math.floor((seconds % 3600) / 60)}`;
    const sec = `${Math.floor(seconds % 60)}`;

    let hours2;
    let minutes2;
    let seconds2;
    if (hours?.length == 1) {
      hours2 = `0${hours}`;
    } else {
      hours2 = `${hours}`;
    }

    if (minutes?.length == 1) {
      minutes2 = `0${minutes}`;
    } else {
      minutes2 = `${minutes}`;
    }

    if (sec?.length == 1) {
      seconds2 = `0${sec}`;
    } else {
      seconds2 = `${sec}`;
    }

    return `${hours2}:${minutes2}:${seconds2}`;
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    indexAxis: "y",
    scales: {
      y: {
        ticks: {
          callback: function (val, index) {
            if (filteredData[val].completed) {
              return filteredData[val].id;
            } else return "WIP";
          },
        },
      },
      x: {
        type: "time",
        time: {
          unit: "hour",
          //unit: "minute",
        },
        min: `2022-11-01 ${entry}`,
      },
    },
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          title: function (tooltipItem, data) {
            const data2 = tooltipItem[0].dataset.data;
            const index = tooltipItem[0].dataIndex;
            const current2 = data2[index];
            const current = filteredData[tooltipItem[0].label];
            const currentWS =
              tooltipItem[0].dataset.data[tooltipItem[0].dataIndex];
            const currentItem = filteredData[currentWS.unit];

            if (workStationData) {
              return (
                "Unit number : " +
                (currentItem?.completed ? currentItem?.id : "WIP") +
                ", Model : " +
                currentItem.Model +
                ", WorkStation : Cam" +
                currentWS.ws
              );
            } else
              return (
                "Unit number : " +
                (current.completed ? current.id : "WIP") +
                ", Model : " +
                current.Model
              );
          },
          afterTitle: function (tooltipItem, data) {
            const current = filteredData[tooltipItem[0].dataIndex];

            if (workStationData) {
              const currentWS =
                tooltipItem[0].dataset.data[tooltipItem[0].dataIndex];
              const currentItem = filteredData[currentWS.unit];
              const currentWstimings = currentItem.timings.find((element) => {
                return element.workstation == currentWS.ws;
              });

              return (
                "Entry : " +
                convertToDateTime(currentWstimings.entry, true) +
                ", Exit  : " +
                convertToDateTime(currentWstimings.exit, true) +
                ", Duration : " +
                calculateDuration(
                  convertToDateTime(currentWstimings.entry, true),
                  convertToDateTime(currentWstimings.exit, true),
                  false
                )
              );
            } else
              return (
                "Entry : " +
                current.EntryTime +
                ", Exit : " +
                current.ExitTime +
                ", Duration : " +
                calculateDuration(
                  convertToDateTime(current.EntryTime, true),
                  convertToDateTime(current.ExitTime, true),
                  false
                )
              );
          },
          label: function (tooltipItem, data) {
            const current = filteredData[tooltipItem.label];
            const curremtimp = improvementData.find((element) => {
              return element.unit == current.id;
            });
            if (workStationData) {
              const currentWS = tooltipItem.dataset.data[tooltipItem.dataIndex];
              const currentWSTimingsArr = filteredData[currentWS.unit].timings;

              const currentWorkstationTime = currentWSTimingsArr.find(
                (element) => {
                  return element.workstation == currentWS.ws;
                }
              );
              const currentWSimp = improvementData.find((element) => {
                return element.unit == filteredData[currentWS.unit].id;
              });

              return (
                "Improvement time : Left " +
                convertSecondsToTime(
                  currentWSimp.improvementTime[currentWS.ws].L
                ) +
                ", Right " +
                convertSecondsToTime(
                  currentWSimp.improvementTime[currentWS.ws].R
                ) +
                ", Total " +
                convertSecondsToTime(
                  currentWSimp.improvementTime[currentWS.ws].total
                )
              );
            } else {
              return (
                "Improvement Time : " +
                convertSecondsToTime(curremtimp.total_improvement)
              );
            }
          },
          afterLabel: function (tooltipItem, data) {
            const current = filteredData[tooltipItem.label];
            const curremtimp = improvementData.find((element) => {
              return element.unit == current.id;
            });
            const Ratio =
              curremtimp.total_improvement /
              calculateDuration(
                convertToDateTime(current.EntryTime, true),
                convertToDateTime(current.ExitTime, true),
                true
              );
            if (workStationData) {
              const currentWS = tooltipItem.dataset.data[tooltipItem.dataIndex];

              const currentWSimp = improvementData.find((element) => {
                return element.unit == filteredData[currentWS.unit].id;
              });
              const currentItem = filteredData[currentWS.unit];
              const currentTimings = currentItem.timings.find((element) => {
                return element.workstation == currentWS.ws;
              });

              const duration = calculateDuration(
                convertToDateTime(currentTimings.entry, true),
                convertToDateTime(currentTimings.exit, true),
                true
              );

              return (
                "Improvement Time / Duration:  left " +
                Math.round(
                  (
                    currentWSimp.improvementTime[currentWS.ws].L / duration
                  ).toFixed(2) * 100
                ) +
                "%" +
                ", Right " +
                Math.round(
                  (
                    currentWSimp.improvementTime[currentWS.ws].R / duration
                  ).toFixed(2) * 100
                ) +
                "%"
              );
            } else
              return (
                "Improvement Time / Duration : " +
                Math.round(Ratio.toFixed(2) * 100) +
                "%"
              );
          },
        },
        position: "myCustomPositioner",
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "xy",

          onPanStart({ chart, event, point }) {},
        },

        zoom: {
          mode: "y",
          wheel: {
            enabled: true,
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };
  Tooltip.positioners.myCustomPositioner = function (elements, eventPosition) {
    const tooltip = this;
    let pos;
    if (eventPosition.y > 70) {
      pos = "bottom";
    } else {
      pos = "top";
    }

    return {
      x: eventPosition.x,
      y: eventPosition.y,
      yAlign: pos,
    };
  };

  function padding() {
    if (graphCount == 1) return "0.5rem 1rem 1rem 1rem";
    if (graphCount == 3) return "0.5rem 1rem 1rem 1rem";
    if (graphCount == 2) return "0.5rem 1rem 1rem 1rem";
  }

  return (
    <Grid
      container
      style={{
        padding: padding(),
        backgroundColor: "#E3E3E3",
        height: "100%",
      }}
    >
      <Grid
        container
        style={{
          width: "100%",
          height: "100%",
          paddingBottom: "0.25rem",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          border: "1px solid #9A9A9A",
          backgroundColor: "white",
        }}
      >
        <Grid
          container
          style={{
            height: "10%",
          }}
        >
          <Grid item xs={4.5}></Grid>

          <Grid
            item
            xs={4}
            style={{
              fontWeight: "500",
              fontSize: "16px",
              paddingLeft: "0.8rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            Product Life Cycle
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <GreySwitch
              size="small"
              checked={workStationData}
              onChange={(e) => setWorkStationData(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
          <Grid
            item
            xs={1.5}
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            Workstation Data
          </Grid>

          <Grid
            item
            xs={1}
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                color: graphCount == 1 ? "#666" : "black",
                height: "100%",
                cursor: graphCount == 1 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (graphCount > 1) {
                  setOperatorGraph(false);
                }
              }}
            >
              <VisibilityIcon />
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            height: "80%",
            position: "relative",
            backgroundColor: "white",
          }}
        >
          <Grid container style={{ width: "100%", height: "100%" }}>
            <Grid
              item
              xs={0.25}
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "6vh",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Grid
                style={{
                  position: "absolute",
                  transform: "rotate(-90deg)",
                  bottom: "30%",
                  fontSize: "12px",
                  width: "12vh",
                  minWidth: "80px",
                }}
              >
                {"Units →"}
              </Grid>
            </Grid>
            <Grid item xs={11.5} style={{ width: "100%", height: "100%" }}>
              <Bar
                onContextMenu={(e) => {
                  e.preventDefault();
                  chartRef.current.resetZoom();
                }}
                key={chartKey}
                ref={chartRef}
                options={options}
                data={GenerateGraphData(filteredData)}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </Grid>
            <Grid
              item
              xs={0.25}
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "6vh",
                justifyContent: "center",
              }}
            ></Grid>
          </Grid>
        </Grid>

        {!workStationData ? (
          <Grid
            style={{
              height: "7%",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ fontSize: "12px", paddingLeft: "4rem" }}>
              {"Time →"}
            </div>
            <div
              style={{
                display: "flex",
                width: graphCount == 3 ? "210px" : "300px",
                justifyContent: "space-between",
                paddingRight: "12px",
              }}
            >
              <div
                onClick={() => {
                  if (filters.Z200 || filters["Z200 small"]) {
                    handleButtonClick("Z400");
                  }
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: graphCount == 3 ? "45px" : "60px",
                  cursor:
                    filters.Z200 || filters["Z200 small"]
                      ? "pointer"
                      : "not-allowed",
                }}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: "rgba(251, 201, 155, 1)",
                    borderRadius: "999px",
                    marginRight: "5px",

                    cursor:
                      filters.Z200 || filters["Z200 small"]
                        ? "pointer"
                        : "not-allowed",
                  }}
                ></div>
                <div
                  style={{
                    color: "#666",
                    textDecoration: filters.Z400 ? "none" : "line-through",
                    fontSize: graphCount == 3 ? "10px" : "14px",
                  }}
                >
                  {" "}
                  Z400{" "}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: graphCount == 3 ? "45px" : "60px",

                  cursor:
                    filters["Z200 small"] || filters.Z400
                      ? "pointer"
                      : "not-allowed",
                }}
                onClick={() => {
                  if (filters["Z200 small"] || filters.Z400) {
                    handleButtonClick("Z200");
                  }
                }}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: "rgba(255, 181, 197, 1)",
                    borderRadius: "999px",
                    marginRight: "5px",
                    cursor:
                      filters["Z200 small"] || filters.Z400
                        ? "pointer"
                        : "not-allowed",
                  }}
                ></div>
                <div
                  style={{
                    color: "#666",
                    fontSize: graphCount == 3 ? "10px" : "14px",

                    textDecoration: filters.Z200 ? "none" : "line-through",
                  }}
                >
                  Z200
                </div>
              </div>
              <div
                onClick={() => {
                  if (filters.Z200 || filters.Z400) {
                    handleButtonClick("Z200 small");
                  }
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: graphCount == 3 ? "80px" : "100px",
                  marginRight: "5px",
                  cursor:
                    filters.Z200 || filters.Z400 ? "pointer" : "not-allowed",
                }}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: "rgba(164, 223, 223, 1)",
                    borderRadius: "999px",
                    marginRight: "5px",

                    cursor:
                      filters.Z200 || filters.Z400 ? "pointer" : "not-allowed",
                  }}
                ></div>
                <div
                  style={{
                    color: "#666",
                    fontSize: graphCount == 3 ? "10px" : "12px",
                    textDecoration: filters["Z200 small"]
                      ? "none"
                      : "line-through",
                  }}
                >
                  Z200 SMALL
                </div>
              </div>
            </div>
          </Grid>
        ) : (
          <Grid
            style={{
              height: "7%",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                fontSize: "12px",
                paddingLeft: "4rem",
              }}
            >
              {"Time →"}
            </div>
            <div
              style={{
                display: "flex",
                width: graphCount == 3 ? "600px" : "800px",
                justifyContent: "space-between",
                paddingRight: "12px",
              }}
            >
              {workStationColorArray.map((element) => {
                return (
                  <div
                    onClick={() => handleButtonClick2(element.name)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: element.name == "SELECT ALL" ? "70px" : "40px",

                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        width: "12px",
                        height: "12px",
                        backgroundColor: element.color,
                        borderRadius: "999px",
                        cursor: "pointer",
                        marginRight: "1px",
                        border: element.color,
                      }}
                    ></div>
                    <div
                      style={{
                        color: "#666",
                        textDecoration: filterws[element.name]
                          ? "none"
                          : "line-through",
                        fontSize: "10px",
                      }}
                    >
                      {element.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default Lifecycle;

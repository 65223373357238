import React, { useState } from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";

import BootstrapButton from "../../components/button";
import SelectCameraModal from "./selectCameraModal";

function StartLiveButton({ disabled, onSelectLive }) {
  const [modalOpen, setModalOpen] = useState(false);

  const onSelectCamera = (camera) => {
    console.log(camera);
    setModalOpen(false);
    onSelectLive(camera);
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  const handleUploadClick = () => {
    if (disabled) return;
    setModalOpen(true);
  };

  return (
    <>
      <BootstrapButton onClick={handleUploadClick} disabled={disabled}>
        Start Live <VideocamIcon color="white" />
      </BootstrapButton>
      <SelectCameraModal
        open={modalOpen}
        onClose={onModalClose}
        onChange={onSelectCamera}
      />
    </>
  );
}

export default StartLiveButton;

//improvement
import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { Bar } from "react-chartjs-2";
import { useMediaQuery, Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  zoomPlugin,
  Title,
  Tooltip,
  Legend
);

function Improvement({
  graphdata,
  avg,
  setImprovementGraph,
  graphCount,
  workStationData,
  setWorkStationData,
  selectedDate,
}) {
  const chartRef = useRef();

  const [chartKey, setChartKey] = useState(0);
  const matchesLg = useMediaQuery("(min-width:1300px)");
  const matchesXlg = useMediaQuery("(min-width:1600px)");
  const [Z400Count, setZ400Count] = useState(0);
  const [Z200Count, setZ200Count] = useState(0);
  const [Z200_smallCount, setZ200_smallCount] = useState(0);
  const [max, setMax] = useState();
  const [min, setMin] = useState();

  //  const graphdata = useSelector((state) => state?.authInfo.improvementTime);

  const GreySwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: grey[900],
      "&:hover": {
        backgroundColor: alpha(grey[900], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: grey[900],
    },
  }));

  const [filters, setFilters] = useState({
    Z200: true,
    "Z200 SMALL": true,
    Z400: true,
  });

  const [filterws, setfilterws] = useState({
    "SELECT ALL": true,
    1106: true,
    1105: true,
    1104: true,
    1108: true,
    1103: true,
    1102: true,
    1101: true,
    184: true,
    1100: true,
    199: true,
  });

  const handleButtonClick = (modelName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [modelName]: !prevFilters[modelName],
    }));
  };
  const handleButtonClick2 = (modelName) => {
    if (modelName == "SELECT ALL") {
      if (filterws["SELECT ALL"]) {
        setfilterws({
          "SELECT ALL": false,
          1106: false,
          1105: false,
          1104: false,
          1108: false,
          1103: false,
          1102: false,
          1101: false,
          184: false,
          1100: false,
          199: false,
        });
      } else {
        setfilterws({
          "SELECT ALL": true,
          1106: true,
          1105: true,
          1104: true,
          1108: true,
          1103: true,
          1102: true,
          1101: true,
          184: true,
          1100: true,
          199: true,
        });
      }
    } else {
      setfilterws((prevFilters) => ({
        ...prevFilters,
        [modelName]: !prevFilters[modelName],
      }));
    }
  };

  useEffect(() => {
    setFilters({
      Z200: true,
      "Z200 SMALL": true,
      Z400: true,
    });
  }, [workStationData]);

  const maxArr = [];
  const maxOpr = [];
  if (graphdata?.length > 0) {
    for (let x of graphdata) {
      maxArr?.push(x?.max_value / 60);
      let temp = [];
      for (let y of x?.operator) {
        const arr = y.split(" ");
        temp.push(arr[1]);
      }
      maxOpr.push(temp);
    }
  }

  function count() {
    for (let i = 0; i < graphdata?.length; i++) {
      if (graphdata[i]?.completed == true) {
        if (graphdata[i]?.model == "Z400") {
          setZ400Count((prev) => prev + 1);
        }
        if (graphdata[i]?.model == "Z200") {
          setZ200Count((prev) => prev + 1);
        }
        if (graphdata[i]?.model == "Z200 SMALL") {
          setZ200_smallCount((prev) => prev + 1);
        }
      }
    }
  }

  useEffect(() => {
    count();
    return () => {
      setZ400Count(0);
      setZ200Count(0);
      setZ200_smallCount(0);
    };
  }, [graphdata]);

  const options = {
    maintainAspectRatio: false,

    responsive: true,

    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          callback: function (val, index) {
            let current = graphdata.find((element) => {
              return element.unit == this.getLabelForValue(val);
            });

            if (current.completed) {
              return this.getLabelForValue(val);
            } else return "WIP";
          },
          maxRotation: 0,
          minRotation: 0,
          font: {
            size: matchesLg ? 10 : 8,
          },
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
        title: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 12,
        },

        displayColors: false,
        filter: (tooltipItem) =>
          tooltipItem.dataset.data[tooltipItem.dataIndex] > 0,
        callbacks: {
          title: function (tooltipItem, data) {
            const current = tooltipItem[0]?.label;
            const currentElement = graphdata.find((element, index) => {
              return element.unit == current;
            });
            if (currentElement) {
              return (
                "Unit number : " +
                (currentElement?.completed ? currentElement?.unit : "WIP")
              );
            } else return null;
          },
          afterTitle: function (tooltipItem, data) {
            if (graphdata[tooltipItem[0]?.dataIndex]?.model) {
              return (
                "Unit type : " + graphdata[tooltipItem[0]?.dataIndex]?.model
              );
            } else return null;
          },
          label: function (tooltipItem, data) {
            const workstation = workstationNumber[tooltipItem.datasetIndex];

            if (workStationData) {
              return "WorkStation : Cam" + workstation;
            } else {
              return (
                "Total time : " + convertSecondsToTime(tooltipItem.raw * 60)
              );
            }
          },
          afterLabel: function (tooltipItem, data) {
            const workstation = workstationNumber[tooltipItem.datasetIndex];
            const current = graphdata.find((element) => {
              return element.unit == tooltipItem.dataIndex + 1;
            });

            if (workStationData) {
              return (
                "Improvement time: Left : " +
                convertSecondsToTime(current?.improvementTime[workstation]?.L) +
                ", Right : " +
                convertSecondsToTime(current?.improvementTime[workstation]?.R) +
                ", Total : " +
                convertSecondsToTime(
                  current?.improvementTime[workstation]?.total
                )
              );
            } else {
              return (
                "Max time : " +
                convertSecondsToTime(maxArr[tooltipItem?.dataIndex] * 60) +
                " by Cam" +
                maxOpr[tooltipItem.dataIndex]
              );
            }
          },
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          mode: "x",
          wheel: {
            speed: 0.1,
            enabled: true,
          },
        },
      },
    },
  };

  function convertSecondsToTime(seconds) {
    if (isNaN(seconds) || seconds < 0) {
      return "Invalid input";
    }

    const hours = `${Math.floor(seconds / 3600)}`;
    const minutes = `${Math.floor((seconds % 3600) / 60)}`;
    const sec = `${Math.floor(seconds % 60)}`;

    let hours2;
    let minutes2;
    let seconds2;
    if (hours?.length == 1) {
      hours2 = `0${hours}`;
    } else {
      hours2 = `${hours}`;
    }

    if (minutes?.length == 1) {
      minutes2 = `0${minutes}`;
    } else {
      minutes2 = `${minutes}`;
    }

    if (sec?.length == 1) {
      seconds2 = `0${sec}`;
    } else {
      seconds2 = `${sec}`;
    }

    return `${hours2}:${minutes2}:${seconds2}`;
  }

  function createDiagonalPattern(color = "black") {
    let shape = document.createElement("canvas");
    shape.width = 10;
    shape.height = 10;

    let c = shape.getContext("2d");

    c.fillStyle = color;
    c.fillRect(0, 0, shape.width, shape.height);

    c.strokeStyle = "#000000";
    c.lineWidth = 1;
    c.beginPath();
    c.moveTo(2, 0);
    c.lineTo(10, 8);
    c.stroke();

    c.moveTo(0, 8);
    c.lineTo(2, 10);
    c.stroke();

    return c.createPattern(shape, "repeat");
  }

  const WorkstationColors = {
    1106: "rgb(130, 203, 255)",
    1105: "rgb(14, 110, 197)",
    1104: "rgb(255, 169, 176)",
    1108: "rgb(251, 46, 53)",
    1103: "rgb(129, 238, 161)",
    1102: "rgb(52, 173, 161)",
    1101: "rgb(251, 209, 115)",
    184: "rgb(255, 136, 31)",
    1100: "rgb(162, 135, 210)",
    199: "rgb(108, 70, 184)",
  };

  const workStationColorArray = [
    { name: "SELECT ALL", color: "#5E5E5E" },
    { name: 199, color: "rgb(108, 70, 184)" },
    { name: 1100, color: "rgb(162, 135, 210)" },
    { name: 184, color: "rgb(255, 136, 31)" },
    { name: 1101, color: "rgb(251, 209, 115)" },
    { name: 1102, color: "rgb(52, 173, 161)" },
    { name: 1103, color: "rgb(129, 238, 161)" },
    { name: 1108, color: "rgb(251, 46, 53)" },
    { name: 1104, color: "rgb(255, 169, 176)" },
    { name: 1105, color: "rgb(14, 110, 197)" },
    { name: 1106, color: "rgb(130, 203, 255)" },
  ];

  const workstationNumber = {
    9: 1106,
    8: 1105,
    7: 1104,
    6: 1108,
    5: 1103,
    4: 1102,
    3: 1101,
    2: 184,
    1: 1100,
    0: 199,
  };

  const generateChartData = (data) => {
    if (workStationData) {
      const ws199 = [];
      const ws199Col = [];

      const ws1100 = [];
      const ws1100Col = [];

      const ws184 = [];
      const ws184Col = [];

      const ws1101 = [];
      const ws1101Col = [];

      const ws1102 = [];
      const ws1102Col = [];

      const ws1103 = [];
      const ws1103Col = [];

      const ws1108 = [];
      const ws1108Col = [];

      const ws1104 = [];
      const ws1104Col = [];

      const ws1105 = [];
      const ws1105Col = [];

      const ws1106 = [];
      const ws1106Col = [];

      const Unit = [];
      const type = [];

      data?.forEach((operator, index) => {
        Unit.push(operator.unit);
        type.push(operator.model);

        if (operator.improvementTime[199] && filterws[199]) {
          if (operator.completed) {
            ws199Col.push(WorkstationColors[199]);
          } else {
            ws199Col.push(createDiagonalPattern(WorkstationColors[199]));
          }
          ws199.push(operator.improvementTime[199].total / 60);
        }
        if (operator.improvementTime[1100] && filterws[1100]) {
          if (operator.completed) {
            ws1100Col.push(WorkstationColors[1100]);
          } else {
            ws1100Col.push(createDiagonalPattern(WorkstationColors[1100]));
          }

          ws1100.push(operator.improvementTime[1100].total / 60);
        }
        if (operator.improvementTime[184] && filterws[184]) {
          if (operator.completed) {
            ws184Col.push(WorkstationColors[184]);
          } else {
            ws184Col.push(createDiagonalPattern(WorkstationColors[184]));
          }
          ws184.push(operator.improvementTime[184].total / 60);
        }
        if (operator.improvementTime[1101] && filterws[1101]) {
          if (operator.completed) {
            ws1101Col.push(WorkstationColors[1101]);
          } else {
            ws1101Col.push(createDiagonalPattern(WorkstationColors[1101]));
          }
          ws1101.push(operator.improvementTime[1101].total / 60);
        }
        if (operator.improvementTime[1102] && filterws[1102]) {
          if (operator.completed) {
            ws1102Col.push(WorkstationColors[1102]);
          } else {
            ws1102Col.push(createDiagonalPattern(WorkstationColors[1102]));
          }
          ws1102.push(operator.improvementTime[1102].total / 60);
        }
        if (operator.improvementTime[1103] && filterws[1103]) {
          if (operator.completed) {
            ws1103Col.push(WorkstationColors[1103]);
          } else {
            ws1103Col.push(createDiagonalPattern(WorkstationColors[1103]));
          }
          ws1103.push(operator.improvementTime[1103].total / 60);
        }

        if (operator.improvementTime[1108] && filterws[1108]) {
          if (operator.completed) {
            ws1108Col.push(WorkstationColors[1108]);
          } else {
            ws1108Col.push(createDiagonalPattern(WorkstationColors[1108]));
          }
          ws1108.push(operator.improvementTime[1108].total / 60);
        }

        if (operator.improvementTime[1104] && filterws[1104]) {
          if (operator.completed) {
            ws1104Col.push(WorkstationColors[1104]);
          } else {
            ws1104Col.push(createDiagonalPattern(WorkstationColors[1104]));
          }
          ws1104.push(operator.improvementTime[1104].total / 60);
        }

        if (operator.improvementTime[1105] && filterws[1105]) {
          if (operator.completed) {
            ws1105Col.push(WorkstationColors[1105]);
          } else {
            ws1105Col.push(createDiagonalPattern(WorkstationColors[1105]));
          }
          ws1105.push(operator.improvementTime[1105].total / 60);
        }

        if (operator.improvementTime[1106] && filterws[1106]) {
          if (operator.completed) {
            ws1106Col.push(WorkstationColors[1106]);
          } else {
            ws1106Col.push(createDiagonalPattern(WorkstationColors[1106]));
          }
          ws1106.push(operator.improvementTime[1106].total / 60);
        }
      });

      return {
        labels: Unit,
        datasets: [
          {
            label: Unit,
            data: ws199,
            backgroundColor: ws199Col,
            type: "bar",
          },
          {
            label: Unit,
            data: ws1100,
            backgroundColor: ws1100Col,
            type: "bar",
          },
          {
            label: Unit,
            data: ws184,
            backgroundColor: ws184Col,
            type: "bar",
          },
          {
            label: Unit,
            data: ws1101,
            backgroundColor: ws1101Col,
            type: "bar",
          },
          {
            label: Unit,
            data: ws1102,
            backgroundColor: ws1102Col,
            type: "bar",
          },
          {
            label: Unit,
            data: ws1103,
            backgroundColor: ws1103Col,
            type: "bar",
          },
          {
            label: Unit,
            data: ws1108,
            backgroundColor: ws1108Col,
            type: "bar",
          },
          {
            label: Unit,
            data: ws1104,
            backgroundColor: ws1104Col,
            type: "bar",
          },
          {
            label: Unit,
            data: ws1105,
            backgroundColor: ws1105Col,
            type: "bar",
          },
          {
            label: Unit,
            data: ws1106,
            backgroundColor: ws1106Col,
            type: "bar",
          },
        ],
      };
    } else {
      const values = [];
      const type = [];
      const Unit = [];

      data?.forEach((operator, index) => {
        if (operator.model == "Z400" && filters.Z400) {
          values.push(operator.total_improvement / 60);
          type.push({ model: operator.model, completed: operator.completed });

          Unit.push(operator.unit);
        }
        if (operator.model == "Z200" && filters.Z200) {
          values.push(operator.total_improvement / 60);
          type.push({ model: operator.model, completed: operator.completed });
          Unit.push(operator.unit);
        }
        if (operator.model == "Z200 SMALL" && filters["Z200 SMALL"]) {
          values.push(operator.total_improvement / 60);
          type.push({ model: operator.model, completed: operator.completed });
          Unit.push(operator.unit);
        }
      });
      const backgroundColor = type.map((type, index) => {
        if (type.model === "Z400") {
          if (type.completed == true) {
            return "rgba(251, 201, 155, 0.6)";
          } else {
            return createDiagonalPattern("rgba(251, 201, 155, 0.6)");
          }
        }
        if (type.model === "Z200") {
          if (type.completed == true) {
            return "rgba(255, 181, 197, 0.6)";
          } else {
            return createDiagonalPattern("rgba(255, 181, 197, 0.6)");
          }
        }
        if (type.model === "Z200 SMALL") {
          if (type.completed == true) {
            return "rgba(164, 223, 223, 0.6)";
          } else {
            return createDiagonalPattern("rgba(164, 223, 223, 0.6)");
          }
        }
      });

      let final = [
        {
          label: Unit,
          data: values,
          backgroundColor: backgroundColor,
          borderWidth: 1,
          type: "bar",
          minBarLength: "2",
        },
      ];

      return {
        labels: Unit,
        datasets: final,
      };
    }
  };

  const graphCount3 = useMediaQuery("(min-width:1200px)");
  const graphCount2 = useMediaQuery("(min-width:1350px)");
  const graphCount1 = useMediaQuery("(min-width:1450px)");

  function fontSize() {
    if (graphCount == 3) {
      if (graphCount3) {
        return "9px";
      } else {
        return "7px";
      }
    }

    if (graphCount == 2) {
      if (graphCount2) {
        return "12px";
      } else {
        return "9px";
      }
    }
    if (graphCount == 1) {
      if (graphCount1) {
        return "13px";
      } else {
        return "9px";
      }
    }
  }
  function padding() {
    if (graphCount == 1) return "0.5rem 1rem 1rem 1rem";
    if (graphCount == 3) return "0.5rem 0.5rem 0.5rem 1rem";
    if (graphCount == 2) return "0.5rem 1rem 1rem 1rem";
  }
  return (
    <Grid
      item
      xs={12}
      container
      style={{
        padding: padding(),
        backgroundColor: "#E3E3E3",
        height: "100%",
      }}
    >
      <Grid
        item
        container
        xs={12}
        style={{
          height: "100%",
          position: "relative",
          backgroundColor: "white",

          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          border: "1px solid #9A9A9A",
        }}
      >
        <Grid
          container
          style={{
            height: graphCount == 1 ? "5%" : "10%",
          }}
        >
          <Grid item xs={graphCount == 3 ? (!graphCount1 ? 0 : 3) : 4.5}></Grid>
          <Grid
            item
            xs={graphCount == 3 ? (!graphCount1 ? 5 : 4) : 4}
            style={{
              fontWeight: "500",
              fontSize: "16px",
              paddingLeft: "0.8rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            Total Improvement Time
          </Grid>
          <Grid item xs={graphCount == 3 ? (!graphCount1 ? 2 : 0) : 0}></Grid>

          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <GreySwitch
              size="small"
              checked={workStationData}
              onChange={(e) => setWorkStationData(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
          <Grid
            item
            xs={graphCount == 3 ? 3 : 1.5}
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            Workstation Data
          </Grid>

          <Grid
            item
            xs={1}
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                color: graphCount == 1 ? "#666" : "black",
                height: "100%",
                cursor: graphCount == 1 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (graphCount > 1) {
                  setImprovementGraph(false);
                }
              }}
            >
              <VisibilityIcon />
            </Button>
          </Grid>
        </Grid>
        {graphdata && (
          <Grid
            container
            style={{
              height: graphCount == 1 ? "6%" : "15%",
              display: "flex",
            }}
          >
            <Grid
              container
              style={{
                width: "100%",
                display: "flex",
                padding: "0rem 1vw",
                margin: "0.25rem 0rem",
              }}
            >
              {filters.Z400 && (
                <Grid
                  style={{
                    width: graphCount == 3 ? "50%" : "25%",
                    display: "flex",
                    border: "1px solid black",
                  }}
                >
                  <Grid
                    style={{
                      width: "8%",
                      backgroundColor: "rgba( 245, 120, 5, 0.4)",
                      borderRight: "1px solid black",
                    }}
                  ></Grid>
                  <Grid
                    style={{
                      width: "90%",
                      textAlign: "center",
                      alignItem: "center",
                      display: "flex",
                    }}
                  >
                    <Grid
                      style={{
                        width: "15%",
                        fontSize: fontSize(),

                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Z400
                    </Grid>
                    <Grid
                      style={{
                        width: "25%",
                        fontSize: fontSize(),
                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        padding: "1px",
                      }}
                    >
                      <Grid
                        style={{
                          borderRight: "1px solid #000000",
                          borderLeft: "1px solid #000000",
                          width: "100%",
                        }}
                      >
                        {` Units : ${Z400Count}`}
                      </Grid>
                    </Grid>
                    <Grid
                      style={{
                        width: "65%",
                        fontSize: fontSize(),
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      Avg Time/Unit : {avg?.Z400}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {filters["Z200 SMALL"] && (
                <Grid
                  item
                  style={{
                    display: "flex",
                    width: graphCount == 3 ? "50%" : "25%",
                    border: "1px solid black",
                  }}
                >
                  <Grid
                    style={{
                      width: "8%",
                      backgroundColor: "rgba(75, 192, 192, 0.5)",
                      borderRight: "1px solid black",
                    }}
                  ></Grid>
                  <Grid
                    style={{
                      width: "92%",
                      textAlign: "center",
                      alignItem: "center",
                      display: "flex",
                    }}
                  >
                    <Grid
                      style={{
                        width: "25%",
                        fontSize: fontSize(),
                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Z200 small
                    </Grid>
                    <Grid
                      style={{
                        width: "20%",
                        fontSize: fontSize(),

                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        padding: "1px",
                      }}
                    >
                      <Grid
                        style={{
                          borderRight: "1px solid #000000",
                          borderLeft: "1px solid #000000",
                          width: "100%",
                        }}
                      >
                        {` Units : ${Z200_smallCount}`}
                      </Grid>
                    </Grid>
                    <Grid
                      style={{
                        width: "55%",
                        fontSize: fontSize(),
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Avg Time/Unit : {avg?.["Z200 Small"]}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {filters.Z200 && (
                <Grid
                  style={{
                    width: graphCount == 3 ? "50%" : "25%",
                    display: "flex",
                    border: "1px solid black",
                  }}
                >
                  <Grid
                    style={{
                      width: "8%",
                      backgroundColor: "rgba(255, 99, 132, 0.5)",
                      borderRight: "1px solid black",
                    }}
                  ></Grid>
                  <Grid
                    style={{
                      width: "90%",
                      textAlign: "center",
                      alignItem: "center",
                      display: "flex",
                    }}
                  >
                    <Grid
                      style={{
                        width: "15%",
                        fontSize: fontSize(),

                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Z200
                    </Grid>
                    <Grid
                      style={{
                        width: "25%",
                        fontSize: fontSize(),
                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        padding: "1px",
                      }}
                    >
                      <Grid
                        style={{
                          borderRight: "1px solid #000000",
                          borderLeft: "1px solid #000000",
                          width: "100%",
                        }}
                      >
                        {` Units : ${Z200Count}`}
                      </Grid>
                    </Grid>
                    <Grid
                      style={{
                        width: "65%",
                        fontSize: fontSize(),
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      Avg Time/Unit : {avg?.Z200}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {
                <Grid
                  item
                  style={{
                    display: "flex",
                    width: graphCount == 3 ? "50%" : "25%",
                    border: "1px solid black",
                  }}
                >
                  <Grid
                    style={{
                      width: "8%",
                    }}
                  ></Grid>
                  <Grid
                    style={{
                      width: "92%",
                      textAlign: "center",
                      alignItem: "center",
                      display: "flex",
                    }}
                  >
                    <Grid
                      style={{
                        width: graphCount == 3 ? "25%" : "20%",
                        fontSize: fontSize(),
                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Total
                    </Grid>
                    <Grid
                      style={{
                        width: graphCount == 3 ? "20%" : "25%",
                        fontSize: fontSize(),
                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        padding: "1px",
                      }}
                    >
                      <Grid
                        style={{
                          borderRight: "1px solid #000000",
                          borderLeft: "1px solid #000000",
                          width: "100%",
                        }}
                      >
                        {` Units : ${Z200Count + Z400Count + Z200_smallCount}`}
                      </Grid>
                    </Grid>
                    <Grid
                      style={{
                        width: "55%",
                        fontSize: fontSize(),
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Avg Time/Unit : {avg?.Total}
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
        )}

        {/* {graphdata && workStationData && (
          <Grid
            container
            style={{
              height: graphCount == 1 ? "6%" : "15%",
              padding: "2px 10px",
              width: "100%",
            }}
          >
            <Grid item xs={6}>
              <Grid style={{ fontSize: fontSize() }}>
                Max Total Improvement Time
              </Grid>

              <Grid container style={{ border: "1px solid #000000" }}>
                <Grid
                  item
                  xs={3}
                  style={{
                    fontSize: fontSize(),
                    borderBottom: "1px solid #000",
                  }}
                >
                  {" "}
                  Unit ID : 26
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    fontSize: fontSize(),
                    borderBottom: "1px solid #000",
                  }}
                >
                  Workstation : 1104
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    fontSize: fontSize(),
                    borderBottom: "1px solid #000",
                  }}
                >
                  {" "}
                  Entry/Exit Time:00:12:14/00:12:45
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    fontSize: fontSize(),
                  }}
                >
                  {" "}
                  Unit ID : 26
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    fontSize: fontSize(),
                  }}
                >
                  Workstation : 1104
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    fontSize: fontSize(),
                  }}
                >
                  {" "}
                  Entry/Exit Time:00:12:14/00:12:45
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid style={{ fontSize: fontSize() }}>
                {" "}
                Min Total Improvement Time
              </Grid>
              <Grid container style={{ border: "1px solid #000000" }}>
                <Grid
                  item
                  xs={3}
                  style={{
                    fontSize: fontSize(),
                    borderBottom: "1px solid #000",
                  }}
                >
                  {" "}
                  Unit ID : 26
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    fontSize: fontSize(),
                    borderBottom: "1px solid #000",
                  }}
                >
                  Workstation : 1104
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    fontSize: fontSize(),
                    borderBottom: "1px solid #000",
                  }}
                >
                  {" "}
                  Entry/Exit Time:00:12:14/00:12:45
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    fontSize: fontSize(),
                  }}
                >
                  {" "}
                  Unit ID : 26
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    fontSize: fontSize(),
                  }}
                >
                  Workstation : 1104
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    fontSize: fontSize(),
                  }}
                >
                  {" "}
                  Entry/Exit Time:00:12:14/00:12:45
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )} */}
        <Grid
          container
          style={{
            width: "100%",
            height: graphCount == 1 ? "84%" : "65%",
          }}
        >
          <Grid
            item
            xs={graphCount == 3 ? 0.5 : 0.25}
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "6vh",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Grid
              style={{
                position: "absolute",
                transform: "rotate(-90deg)",
                bottom: "50%",
                fontSize: graphCount3 ? "12px" : "10px",
                width: "12vh",
                minWidth: "80px",
                fontWeight: "400",
              }}
            >
              {"Time (min) →"}
            </Grid>
          </Grid>
          <Grid
            item
            xs={graphCount == 3 ? 11.25 : 11.5}
            style={{ width: "100%", height: "100%" }}
          >
            <Bar
              onContextMenu={(e) => {
                e.preventDefault();
                chartRef.current.resetZoom();
              }}
              ref={chartRef}
              key={chartKey}
              options={options}
              data={generateChartData(graphdata)}
              style={{
                width: "100%",
                height: "95%",
              }}
            />
          </Grid>
          <Grid
            item
            xs={0.25}
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "6vh",
              justifyContent: "center",
            }}
          ></Grid>
        </Grid>
        {!workStationData ? (
          <Grid
            style={{
              height: graphCount == 1 ? "5%" : "10%",

              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: graphCount3 ? "12px" : "10px",
                paddingLeft: "4rem",
              }}
            >
              {"Units ->"}
            </div>
            <div
              style={{
                display: "flex",
                width: graphCount == 3 ? "210px" : "300px",
                justifyContent: "space-between",
                paddingRight: "12px",
              }}
            >
              <div
                onClick={() => {
                  if (filters.Z200 || filters["Z200 SMALL"]) {
                    handleButtonClick("Z400");
                  }
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: graphCount == 3 ? "45px" : "60px",
                  cursor:
                    filters.Z200 || filters["Z200 SMALL"]
                      ? "pointer"
                      : "not-allowed",
                }}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: "rgba(251, 201, 155, 1)",
                    marginRight: "5px",
                    borderRadius: "999px",
                    cursor:
                      filters["Z200 SMALL"] || filters.Z200
                        ? "pointer"
                        : "not-allowed",
                  }}
                ></div>
                <div
                  style={{
                    color: "#666",
                    textDecoration: filters.Z400 ? "none" : "line-through",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  Z400{" "}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: graphCount == 3 ? "45px" : "60px",

                  cursor:
                    filters["Z200 SMALL"] || filters.Z200
                      ? "pointer"
                      : "not-allowed",
                }}
                onClick={() => {
                  if (filters["Z200 SMALL"] || filters.Z200) {
                    handleButtonClick("Z200");
                  }
                }}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: "rgba(255, 181, 197, 1)",
                    borderRadius: "999px",
                    marginRight: "5px",
                    cursor:
                      filters["Z200 SMALL"] || filters.Z400
                        ? "pointer"
                        : "not-allowed",
                  }}
                ></div>
                <div
                  style={{
                    color: "#666",
                    fontSize: graphCount == 3 ? "10px" : "12px",

                    textDecoration: filters.Z200 ? "none" : "line-through",
                  }}
                >
                  Z200
                </div>
              </div>
              <div
                onClick={() => {
                  if (filters.Z200 || filters.Z400) {
                    handleButtonClick("Z200 SMALL");
                  }
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: graphCount == 3 ? "80px" : "100px",

                  cursor:
                    filters.Z200 || filters.Z400 ? "pointer" : "not-allowed",
                }}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: "rgba(164, 223, 223, 1)",
                    marginRight: "5px",
                    borderRadius: "999px",
                    cursor:
                      filters.Z200 || filters.Z400 ? "pointer" : "not-allowed",
                  }}
                ></div>
                <div
                  style={{
                    color: "#666",
                    fontSize: graphCount == 3 ? "10px" : "12px",
                    textDecoration: filters["Z200 SMALL"]
                      ? "none"
                      : "line-through",
                  }}
                >
                  Z200 SMALL
                </div>
              </div>
            </div>
          </Grid>
        ) : (
          <Grid
            style={{
              height: graphCount == 1 ? "5%" : "10%",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: graphCount3 ? "12px" : "10px",
                paddingLeft: "4rem",
              }}
            >
              {"Units ->"}
            </div>
            <div
              style={{
                display: "flex",
                width: graphCount == 3 ? "600px" : "800px",
                justifyContent: "space-between",
                paddingRight: "12px",
              }}
            >
              {workStationColorArray.map((element) => {
                return (
                  <div
                    onClick={() => handleButtonClick2(element.name)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: element.name == "SELECT ALL" ? "70px" : "40px",

                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        width: graphCount == 3 ? "12px" : "12px",
                        height: graphCount == 3 ? "12px" : "12px",
                        backgroundColor: element.color,
                        borderRadius: "999px",
                        cursor: "pointer",
                        marginRight: "1px",
                        border: element.color,
                      }}
                    ></div>
                    <div
                      style={{
                        color: "#666",
                        textDecoration: filterws[element.name]
                          ? "none"
                          : "line-through",
                        fontSize: "10px",
                      }}
                    >
                      {element.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
export default Improvement;

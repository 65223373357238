import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useMediaQuery, Button } from "@mui/material";
import { getHistoryGraphData } from "../../slices/authInfo";
import { useDispatch } from "react-redux";
import { setLoading } from "../../slices/authInfo";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DatePicker from "react-datepicker";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VisibilityIcon from "@mui/icons-material/Visibility";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
function UnitGraph({ graphdata, setUnitGraph, unitGraph, graphCount }) {
  const [chartKey, setChartKey] = useState(0);
  const matchesLg = useMediaQuery("(min-width:1300px)");
  const matchesXlg = useMediaQuery("(min-width:1600px)");

  const [data2, setData] = useState({
    Z200: true,
    Z200_small: true,
    Z400: true,
    Total: true,
  });

  function call(check) {
    if (check === "Z400") {
      setData({
        ...data2,
        Z400: !data2.Z400,
      });
    } else if (check === "Z200") {
      setData({
        ...data2,
        Z200: !data2.Z200,
      });
    } else if (check === "Z200 SMALL") {
      setData({
        ...data2,
        Z200_small: !data2.Z200_small,
      });
    } else if (check === "Total") {
      setData({
        ...data2,
        Total: !data2.Total,
      });
    }
  }

  const [index, setindex] = useState(0);

  function change(a) {
    if (a && index < Math.floor(graphdata?.length / 30)) {
      setindex(index + 1);
    }
    if (!a && index > 0) {
      setindex(index - 1);
    }
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        maxRotation: 0,
        minRotation: 0,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
        title: {
          display: false,
          text: "Units → ",
          color: "#000000",
          font: {
            size: matchesLg ? 12 : 8,
            padding: 0,
            weight: "bold",
          },
        },
      },
    },
    plugins: {
      legend: {
        onClick: (e, legendItem) => {
          const index = legendItem.datasetIndex;
          const chart = e.chart;
          const meta = chart.getDatasetMeta(index);
          let label = meta._dataset.label;
          call(label);
          meta.hidden =
            meta.hidden === null ? !chart.data.datasets[index].hidden : null;
          chart.update();
        },
        position: "bottom",
        align: "end",

        labels: {
          usePointStyle: true,
          boxWidth: 40,
          padding: 20,
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (tooltipItem, data) {
            return ` ${tooltipItem.dataset.label}: ${tooltipItem.formattedValue} Units`;
          },
        },
      },
    },
  };
  function convertDateFormat(inputDateString) {
    const dateParts = inputDateString.split("-");
    const year = dateParts[0];
    const month = dateParts[1]; // Month is zero-based
    const day = dateParts[2];

    const outputDateString = `${month}-${day}-${String(year).slice(2)}`;

    return outputDateString;
  }
  const generateChartData = (data) => {
    let Z200 = [];
    let Z400 = [];
    let Z200_small = [];
    let Total = [];
    let Total2 = [];
    let Dates = [];
    data?.forEach((operator, index) => {
      Z200.push(operator.Z200);
      Z400.push(operator.Z400);
      Z200_small.push(operator.Z200_small);
      Total.push(operator.total);
      Total2.push(operator.total);
      Dates.push(convertDateFormat(operator.date));
    });

    let final = [
      {
        label: "Z200 SMALL",
        data: Z200_small,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        minBarLength: "2",
      },
      {
        label: "Z400",
        data: Z400,
        backgroundColor: " rgba( 245, 120, 5, 0.4)",
        borderColor: " rgba( 245, 120, 5, 1)",
        borderWidth: 1,
        minBarLength: "2",
      },
      {
        label: "Z200",
        data: Z200,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        minBarLength: "2",
      },
      {
        label: "Total",
        data: Total,
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
        minBarLength: "2",
      },
    ];

    return {
      labels: Dates,
      datasets: final,
    };
  };

  const chartRef = useRef();
  let Z200_small = 0;
  let Z400 = 0;
  let Z200 = 0;
  let total = 0;
  for (let x in graphdata) {
    Z200_small += graphdata[x]?.Z200_small * 1;
    Z400 += graphdata[x]?.Z400 * 1;
    Z200 += graphdata[x]?.Z200 * 1;
    total += graphdata[x]?.total * 1;
  }
  const chartData = generateChartData(
    graphdata?.slice(0 + index * 30, (index + 1) * 30)
  );

  useEffect(() => {
    setindex(0);
  }, [graphdata]);

  const afterDrawHandler = (chart) => {
    const ctx = chart.ctx;
    const dataset = chart.data.datasets;
    const totalDatasetIndex = dataset.findIndex(
      (data) => data?.label === "Total"
    );

    if (totalDatasetIndex !== -1) {
      const meta = chart.getDatasetMeta(totalDatasetIndex);

      if (!meta.hidden) {
        ctx.save();

        // Draw trendline
        ctx.beginPath();
        ctx.strokeStyle = dataset[totalDatasetIndex].borderColor;
        ctx.lineWidth = 1;
        ctx.moveTo(meta.data[0]?.x, meta.data[0]?.y);
        for (let i = 1; i < meta.data.length; i++) {
          ctx.lineTo(meta.data[i].x, meta.data[i].y);
        }
        ctx.stroke();
        ctx.closePath();

        // Draw circles on the data points
        meta.data.forEach((datapoint) => {
          const { x, y } = datapoint.tooltipPosition();
          ctx.beginPath();
          ctx.arc(x, y, 4, 0, Math.PI * 2, false);
          ctx.fillStyle = dataset[totalDatasetIndex].borderColor;
          ctx.fill();
          ctx.closePath();
        });

        ctx.restore();
      }
    }
  };

  return (
    <Grid
      item
      xs={12}
      container
      style={{
        border: "1px solid #9A9A9A",
        backgroundColor: "#E3E3E3",
        height: "100%",
      }}
    >
      <Grid
        item
        container
        xs={12}
        style={{
          height: "100%",
          position: "relative",
          backgroundColor: "#ffffff",
          alignItems: "center",
        }}
      >
        <Grid
          container
          style={{
            height: "10%",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={11}
            style={{
              width: "90%",
              textAlign: "center",
              fontWeight: "500",
              fontSize: "16px",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid>UNIT NUMBER</Grid>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              height: "100%",
              fontWeight: "500",
              fontSize: "14px",
              textAlign: "right",
            }}
          >
            <Button
              style={{
                height: "80%",
                paddingRight: "2rem",
                color: graphCount == 1 ? "#666" : "black",
                cursor: graphCount == 1 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (graphCount > 1) setUnitGraph(false);
              }}
            >
              <VisibilityIcon />
            </Button>
          </Grid>
        </Grid>

        <Grid container style={{ height: "10%", display: "flex" }}>
          {graphdata && (
            <Grid
              style={{
                width: matchesXlg ? "100%" : "100%",
                display: "flex",
                padding: "0rem 1.25vw",
              }}
            >
              {data2.Z200_small && (
                <Grid
                  style={{
                    width: "25%",
                    display: "flex",
                    border: "1px solid black",
                  }}
                >
                  <Grid
                    style={{
                      width: "8%",
                      backgroundColor: "rgba(75, 192, 192, 0.5)",
                      borderRight: "1px solid black",
                    }}
                  ></Grid>
                  <Grid
                    style={{
                      width: "90%",
                      textAlign: "center",
                      alignItem: "center",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Grid
                      style={{
                        fontSize: matchesXlg ? "10px" : "8px",
                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Z200 Small Average
                    </Grid>
                    <Grid
                      style={{
                        fontSize: matchesXlg ? "10px" : "8px",
                        alignItems: "center",
                        display: "flex",
                        fontWeight: "bold",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      :{" "}
                    </Grid>
                    <Grid
                      style={{
                        fontSize: matchesXlg ? "10px" : "8px",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {` ${
                        Math.round((Z200_small / graphdata?.length) * 100) / 100
                      }  units/day`}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {data2.Z400 && (
                <Grid
                  style={{
                    width: "25%",
                    display: "flex",
                    border: "1px solid black",
                  }}
                >
                  <Grid
                    style={{
                      width: "8%",
                      backgroundColor: "rgba( 245, 120, 5, 0.4)",
                      borderRight: "1px solid black",
                    }}
                  ></Grid>
                  <Grid
                    style={{
                      width: "90%",
                      textAlign: "center",
                      alignItem: "center",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Grid
                      style={{
                        fontSize: matchesXlg ? "10px" : "8px",
                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      Z400 Average
                    </Grid>
                    <Grid
                      style={{
                        fontSize: matchesXlg ? "10px" : "8px",
                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      :
                    </Grid>
                    <Grid
                      style={{
                        fontSize: matchesXlg ? "10px" : "8px",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      {Math.round((Z400 / graphdata?.length) * 100) / 100} units
                      /day{" "}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {data2.Z200 && (
                <Grid
                  style={{
                    width: "25%",
                    display: "flex",
                    border: "1px solid black",
                  }}
                >
                  <Grid
                    style={{
                      width: "8%",
                      backgroundColor: "rgba(255, 99, 132, 0.5)",
                      borderRight: "1px solid black",
                    }}
                  ></Grid>
                  <Grid
                    style={{
                      width: "90%",
                      textAlign: "center",
                      alignItem: "center",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Grid
                      style={{
                        width: "50%",
                        fontSize: matchesXlg ? "10px" : "8px",
                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      Z200 Average
                    </Grid>
                    <Grid
                      style={{
                        fontSize: matchesXlg ? "10px" : "8px",
                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      :
                    </Grid>
                    <Grid
                      style={{
                        width: "50%",
                        fontSize: matchesXlg ? "10px" : "8px",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      {Math.round((Z200 / graphdata?.length) * 100) / 100} units
                      /day
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {data2.Total && (
                <Grid
                  style={{
                    width: "25%",
                    display: "flex",
                    border: "1px solid black",
                  }}
                >
                  <Grid
                    style={{
                      width: "8%",
                      backgroundColor: "rgba(153, 102, 255, 0.5)",
                      borderRight: "1px solid black",
                    }}
                  ></Grid>
                  <Grid
                    style={{
                      width: "90%",
                      textAlign: "center",
                      alignItem: "center",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Grid
                      style={{
                        width: "50%",
                        fontSize: matchesXlg ? "10px" : "8px",
                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      Total
                    </Grid>
                    <Grid
                      style={{
                        fontSize: matchesXlg ? "10px" : "8px",
                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      :
                    </Grid>
                    <Grid
                      style={{
                        width: "50%",
                        fontSize: matchesXlg ? "10px" : "8px",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {Math.round((total / graphdata?.length) * 100) / 100}{" "}
                      units/day
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} style={{ height: "80%", position: "relative" }}>
          <Grid container style={{ width: "100%", height: "100%" }}>
            <Grid
              item
              xs={0.35}
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "12vh",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Button
                disabled={index == 0}
                sx={{
                  width: "1.5vw",
                  height: "1.5vw",
                  minWidth: "0px",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor:
                    index === 0 ? "lightgray" : "rgba(16, 38, 51, 1)",
                  "&:hover": {
                    backgroundColor: "rgba(16, 38, 51, 1) !important",
                  },
                }}
                onClick={() => change(false)}
              >
                {" "}
                <ChevronLeftIcon sx={{ color: "white" }} />
              </Button>
              <Grid
                style={{
                  position: "absolute",
                  transform: "rotate(-90deg)",
                  bottom: "45%",
                  fontWeight: "bold",
                  fontSize: matchesXlg ? 16 : 12,
                  width: "60px",
                }}
              >
                {" "}
                Units →{" "}
              </Grid>
            </Grid>
            <Grid item xs={11.3} style={{ width: "100%", height: "100%" }}>
              <Bar
                plugins={[
                  {
                    afterDraw: afterDrawHandler,
                  },
                ]}
                ref={chartRef}
                key={chartKey}
                options={options}
                data={chartData}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </Grid>
            <Grid
              item
              xs={0.35}
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "12vh",
                justifyContent: "center",
              }}
            >
              <Button
                disabled={
                  index == Math.floor((graphdata?.length - 1) / 30) ||
                  graphdata == undefined
                }
                sx={{
                  width: "1.5vw",
                  height: "1.5vw",
                  minWidth: "0px",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor:
                    index >= Math.floor((graphdata?.length - 1) / 30)
                      ? "lightgray"
                      : "rgba(16, 38, 51, 1)",
                  "&:hover": {
                    backgroundColor: "rgba(16, 38, 51, 1) !important",
                  },
                }}
                onClick={() => change(true)}
              >
                {" "}
                <ChevronRightIcon sx={{ color: "white" }} />
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          style={{
            position: "absolute",
            bottom: 15,
            width: "12%",
            left: "8vw",
            fontWeight: "500",
            fontSize: matchesXlg ? "16px" : "13px",
          }}
        >
          Dates →
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UnitGraph;

import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { PointElement } from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import { useMediaQuery, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

function Mangraph({ graphdata, manAvg, manGraph, setManGraph, graphCount }) {
  const [chartKey, setChartKey] = useState(0);
  const matchesLg = useMediaQuery("(min-width:1300px)");
  const matchesXlg = useMediaQuery("(min-width:1600px)");

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        maxRotation: 0,
        minRotation: 0,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
        title: {
          display: false,
          text: "Units → ",
          color: "#000000",
          font: {
            size: matchesLg ? 12 : 8,
            padding: 0,
            weight: "bold",
          },
        },
      },
    },
    plugins: {
      legend: {
        onClick: (e, legendItem) => {
          const index = legendItem.datasetIndex;
          const chart = e.chart;
          // const meta = chart.getDatasetMeta(index);
          // let label = meta._dataset.label

          // meta.hidden = meta.hidden === null ? !chart.data.datasets[index].hidden : null;
          chart.update();
        },
        position: "bottom",
        align: "end",

        labels: {
          font: {
            size: matchesLg ? 12 : 8,
          },
          usePointStyle: true,
          boxWidth: 10,
          filter: function (legendItem) {
            return legendItem.datasetIndex !== 1;
          },
        },
      },
      tooltip: {
        displayColors: false,
      },
    },
  };
  function convertDateFormat(inputDateString) {
    const dateParts = inputDateString.split("-");
    const year = dateParts[0];
    const month = dateParts[1]; // Month is zero-based
    const day = dateParts[2];

    const outputDateString = `${month}-${day}-${String(year).slice(2)}`;

    return outputDateString;
  }
  const generateChartData = (data) => {
    let date = [];
    let work = [];
    data?.forEach((operator) => {
      date.push(convertDateFormat(operator.date));
      work.push(operator.hours);
    });

    return {
      labels: date,
      datasets: [
        {
          label: "Man Hour Value ",
          data: work,
          backgroundColor: "rgba(153, 102, 255, 0.4)",
          borderColor: "rgba(153, 102, 255, 1)",
          borderWidth: 1,
          type: "bar",
        },
        {
          label: "Trend Line",
          data: work,
          borderColor: "rgba(153, 102, 255, 0.4)",
          backgroundColor: "rgba(153, 102, 255, 1)",
          borderWidth: 2,
          type: "line",
        },
      ],
    };
  };
  const [index, setindex] = useState(0);

  const chartData = generateChartData(
    graphdata?.slice(0 + index * 30, (index + 1) * 30)
  );

  function change(a) {
    if (a && index < Math.floor(graphdata?.length / 30)) {
      setindex(index + 1);
    }
    if (!a && index > 0) {
      setindex(index - 1);
    }
  }
  useEffect(() => {
    setindex(0);
  }, [graphdata]);
  return (
    <Grid
      item
      xs={12}
      container
      style={{
        border: "1px solid #9A9A9A",
        backgroundColor: "#E3E3E3",
        height: "100%",
      }}
    >
      <Grid
        item
        container
        xs={12}
        style={{
          height: "100%",
          padding: "0.25rem",
          position: "relative",
          backgroundColor: "#ffffff",
        }}
      >
        <Grid
          container
          style={{
            height: "10%",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={11}
            style={{
              width: "100%",
              textAlign: "center",
              fontWeight: "500",
              fontSize: "16px",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid> MAN HOUR VALUE</Grid>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              height: "100%",
              fontWeight: "500",
              fontSize: "14px",
              textAlign: "right",
            }}
          >
            <Button
              style={{
                color: "black",
                height: "100%",
                color: graphCount == 1 ? "#666" : "black",
                cursor: graphCount == 1 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (graphCount > 1) setManGraph(false);
              }}
            >
              <VisibilityIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            height: "10%",
            display: "flex",
            height: "10%",
            padding: "0rem 2vw",
          }}
        >
          {graphdata && (
            <Grid
              style={{
                width: matchesXlg ? "35%" : "45%",
                display: "flex",
                border: "1px solid black",
              }}
            >
              <Grid
                style={{
                  width: "8%",
                  backgroundColor: "rgba(153, 102, 255, 0.5)",
                  borderRight: "1px solid black",
                }}
              ></Grid>
              <Grid
                style={{
                  width: "92%",
                  textAlign: "center",
                  alignItem: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid
                  style={{
                    width: "50%",
                    fontSize: matchesXlg ? "10px" : "8px",
                    fontWeight: "bold",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Average Man Hour
                </Grid>
                <Grid style={{ fontWeight: "bold", padding: "0px 5px" }}>
                  :
                </Grid>
                <Grid
                  style={{
                    width: "50%",
                    fontSize: matchesXlg ? "10px" : "8px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {manAvg} Hours per day
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} style={{ height: "80%" }}>
          <Grid container style={{ width: "100%", height: "100%" }}>
            <Grid
              item
              xs={0.35}
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "12vh",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Button
                disabled={index == 0}
                sx={{
                  width: "1.5vw",
                  height: "1.5vw",
                  minWidth: "0px",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor:
                    index === 0 ? "lightgray" : "rgba(16, 38, 51, 1)",
                  "&:hover": {
                    backgroundColor: "rgba(16, 38, 51, 1) !important",
                  },
                }}
                onClick={() => change(false)}
              >
                {" "}
                <ChevronLeftIcon sx={{ color: "white" }} />
              </Button>
              <Grid
                style={{
                  position: "absolute",
                  transform: "rotate(-90deg)",
                  bottom: "45%",
                  fontWeight: "bold",

                  fontSize: matchesXlg ? 16 : 12,
                  width: "70px",
                }}
              >
                Hours →
              </Grid>
            </Grid>
            <Grid item xs={11.3} style={{ width: "100%", height: "100%" }}>
              <Bar
                key={chartKey}
                options={options}
                data={chartData}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </Grid>
            <Grid
              item
              xs={0.35}
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "12vh",
                justifyContent: "center",
              }}
            >
              {console.log(Math.floor(graphdata?.length / 30), index)}
              <Button
                disabled={
                  index == Math.floor((graphdata?.length - 1) / 30) ||
                  graphdata == undefined
                }
                sx={{
                  width: "1.5vw",
                  height: "1.5vw",
                  minWidth: "0px",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor:
                    index >= Math.floor((graphdata?.length - 1) / 30)
                      ? "lightgray"
                      : "rgba(16, 38, 51, 1)",
                  "&:hover": {
                    backgroundColor: "rgba(16, 38, 51, 1) !important",
                  },
                }}
                onClick={() => change(true)}
              >
                {" "}
                <ChevronRightIcon sx={{ color: "white" }} />
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          style={{
            position: "absolute",
            bottom: 15,
            width: "15%",
            left: "8vw",
            fontWeight: "500",
            fontSize: matchesXlg ? "16px" : "13px",
          }}
        >
          Dates →
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Mangraph;

import React, { useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import moment from "moment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import {
  getHistoryGraphData,
  setLoading,
  downloadHistoricalCSV,
  setSecondUser,
} from "../../slices/authInfo";
function DateSelector({
  startDate,
  endDate,
  setStartDate,
  availabledate,
  setEndDate,
  minDate,
  maxDate,
  disabled,
  setGraphdataAVG,
  setGraphdataUNIT,
  setManAvg,
  setGraphdataMAN,
  callData,
}) {
  const datePickerRef = useRef(null);
  const datePickerRef2 = useRef(null);
  const email = useSelector((state) => state?.authInfo.email);

  const dispatch = useDispatch();

  useEffect(() => {
    const input = datePickerRef.current && datePickerRef.current.input;
    if (input) {
      input.addEventListener("keydown", function (e) {
        e.preventDefault();
      });
    }
  }, []);
  const isDayBlocked = (date) => {
    return availabledate.some((disabledDate) => {
      return moment(date).format("DD-MM-YYYY") === disabledDate;
    });
  };

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const handleIconClick2 = () => {
    if (datePickerRef2.current) {
      datePickerRef2.current.setOpen(true);
    }
  };

  return (
    <Grid
      style={{
        padding: "1rem",
        width: "100%",
        height: "13%",
        boxShadow:
          "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;",
      }}
    >
      <Grid
        style={{
          border: "1px solid #9A9A9A",
          height: "100%",
          padding: "0px 1rem",
          backgroundColor: "white",
          alignItems: "center",
        }}
        item
        container
        xs={12}
      >
        <Grid
          item
          xs={3}
          style={{
            height: "100%",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "200px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-5px",
                left: "5px",
                backgroundColor: !disabled ? "#ffffff" : "#F8F8F8",
                zIndex: "5",
                padding: "1px 5px",
                fontSize: "9px",
              }}
            >
              Start Date
            </div>
            <div
              onClick={handleIconClick}
              style={{
                position: "absolute",
                zIndex: "5",
                right: "0px",
                top: "2.5px",
                cursor: "pointer",
                height: "4vh",
                minHeight: "25px",
                maxHeight: "35px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CalendarTodayIcon
                sx={{ color: !disabled ? "#000000" : "#c8c8c8" }}
              />
            </div>

            <DatePicker
              placeholderText="MM-DD-YYYY"
              icon={
                <CalendarTodayIcon
                  sx={{ color: !disabled ? "#000000" : "#c8c8c8" }}
                />
              }
              ref={datePickerRef}
              dateFormat="MM/dd/yyyy"
              className="custom-datepicker"
              style={{ cursor: "pointer", width: "100%" }}
              selected={startDate && moment(startDate, "DD-MM-YYYY").toDate()}
              onChange={(date) => {
                callData(moment(date).format("DD-MM-YYYY"), endDate);
                //dispatch(setLoading(true));

                setStartDate(moment(date).format("DD-MM-YYYY"));
              }}
              minDate={moment(minDate, "DD-MM-YYYY").toDate()}
              maxDate={moment(maxDate, "DD-MM-YYYY").toDate()}
              disabled={disabled}
              filterDate={availabledate && isDayBlocked}
              //shouldCloseOnSelect={false}
            />
          </div>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid
          item
          xs={3}
          style={{
            height: "100%",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "200px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-5px",
                left: "5px",
                backgroundColor: !disabled ? "#ffffff" : "#F8F8F8",
                zIndex: "5",
                padding: "1px 5px",
                fontSize: "9px",
              }}
            >
              End Date
            </div>
            <div
              onClick={handleIconClick2}
              style={{
                position: "absolute",
                zIndex: "5",
                right: "0px",
                top: "2.5px",
                cursor: "pointer",
                height: "4vh",
                minHeight: "25px",
                maxHeight: "35px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CalendarTodayIcon
                sx={{ color: !disabled ? "#000000" : "#c8c8c8" }}
              />
            </div>

            <DatePicker
              placeholderText="MM-DD-YYYY"
              icon={
                <CalendarTodayIcon
                  sx={{ color: !disabled ? "#000000" : "#c8c8c8" }}
                />
              }
              ref={datePickerRef2}
              dateFormat="MM/dd/yyyy"
              className="custom-datepicker"
              style={{ cursor: "pointer", width: "100%" }}
              selected={endDate && moment(endDate, "DD-MM-YYYY").toDate()}
              onChange={(date) => {
                callData(startDate, moment(date).format("DD-MM-YYYY"));

                setEndDate(moment(date).format("DD-MM-YYYY"));
              }}
              minDate={moment(minDate, "DD-MM-YYYY").toDate()}
              maxDate={moment(maxDate, "DD-MM-YYYY").toDate()}
              disabled={disabled}
              filterDate={availabledate && isDayBlocked}
              //shouldCloseOnSelect={false}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={5}
          style={{ justifyContent: "flex-end", display: "flex" }}
        >
          <Button
            onClick={() => {
              dispatch(
                downloadHistoricalCSV({
                  email: email,
                  startDate: startDate,
                  endDate: endDate,
                })
              ).then((res) => {
                const blob = new Blob([res.payload], {
                  type: "application/zip",
                });
                const start = startDate.split("-");
                const end = endDate.split("-");

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `Historical_${start[1]}-${start[0]}-${start[2]}_${end[1]}-${end[0]}-${end[2]}.zip`;
                a.click();
                window.URL.revokeObjectURL(url);
              });
            }}
            style={{
              color: "white",
              fontSize: "0.6vw",
              border: "1px solid black",
              background: "#102633",
              width: "7vw",
            }}
            startIcon={<DownloadIcon />}
          >
            Download
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DateSelector;

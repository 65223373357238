import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authInfoReducer from "./authInfo";
import loginReducer from "./authInfo";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const reducers = combineReducers({
  authInfo: authInfoReducer,
  login: loginReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["authInfo"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistedStore = persistStore(store);

// window.onbeforeunload = () => {
//   localStorage.clear();
// };

import React, { useRef, useEffect, useState } from "react";
import { Grid, Button, Modal, Typography } from "@mui/material";
import Webcam from "react-webcam";
import { useSelector } from "react-redux";
import Novideo from "../../assets/novideo.png";
import VideoPlayer from "./videoPlayer";
import Papa from "papaparse";

function Player({ inferenceState, video, setInferenceState }) {
  const divRef = useRef(null);
  const livewebcamRef = useRef(null);
  const clickRef = useRef();

  const playerContainerRef = useRef(null);

  const [isHovered, setIsHovered] = useState(false);
  const [frameData, setFrameData] = useState({});

  const [endState, setEndState] = useState(0);
  const improvementTime = useSelector(
    (state) => state?.authInfo?.improvementTime
  );
  const operatorEfficency = useSelector(
    (state) => state?.authInfo?.operatorEfficency
  );

  const renderMessage = (message) => {
    if (inferenceState === 0) {
      return (
        <Grid style={{ width: "100%", zIndex: "999" }}>
          <Grid style={{ textAlign: "center" }}>
            <img
              src={Novideo}
              alt="img"
              style={{ width: "100px", height: "100px" }}
            />
          </Grid>

          <Grid style={{ width: "100%", textAlign: "center" }}>
            please upload a video or select a camera to start inference
          </Grid>
        </Grid>
      );
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const downloadCSV = (data, type) => {
    // Convert the data to an array of objects
    let dataArray;

    if (type === "improvement") {
      dataArray = Object.keys(data).map((key) => ({
        Name: key,
        Class: data[key].class,
        Function: data[key].function,
      }));
    } else if (type === "operator") {
      dataArray = Object.keys(data).map((key) => ({
        Name: key,
        Idle: data[key].idle,
        Function: data[key].function,
        Wait: data[key].wait,
      }));
    } else {
      console.error("Unsupported type: " + type);
      return;
    }

    // Use PapaParse to convert the array of objects to a CSV string
    const csv = Papa.unparse(dataArray);

    // Create a Blob containing the CSV data
    const blob = new Blob([csv], { type: "text/csv" });

    // Create a download link and trigger a click event to download the CSV
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = `${type}.csv`;
    a.click();
  };

  const handelClose = () => {
    setEndState(0);
  };
  return (
    <Grid
      ref={divRef}
      style={{
        height: "78%",
        padding: "0.625rem 0.625rem 1.25rem 1.25rem",
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <div style={{ position: "absolute" }}>
        <div>
          {inferenceState == 2 && (
            <Webcam
              audio={false}
              mirrored={true}
              ref={livewebcamRef}
              // width={width}
              // height={height}
              forceScreenshotSourceSize="true"
              // videoConstraints={{ deviceId: selectedCamera?.deviceId }}
              position="relative"
            />
          )}
          {inferenceState == 3 && (
            <VideoPlayer
              playerContainerRef={playerContainerRef}
              reference={clickRef}
              video={video}
              frameData={frameData}
              setFrameData={setFrameData}
              inferenceState={inferenceState}
              setInferenceState={setInferenceState}
              setEndState={setEndState}
            />
          )}
          {inferenceState == 1 && (
            <div style={{ marginTop: "20vh" }}>
              {" "}
              an error has occurred please start inference again{" "}
            </div>
          )}
          {inferenceState == 4 && (
            <div style={{ marginTop: "20vh" }}>
              machines are not availabe please try again later
            </div>
          )}
        </div>
      </div>

      <Grid
        ref={playerContainerRef}
        style={{
          backgroundColor: "transparent",
          width: "100%",
          height: "100%",
          border: "1px solid #9A9A9A",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          zIndex: "999",
        }}
      >
        <Grid
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: "5rem",
            display: "flex",
            justifyContent: "center",
            zIndex: "999",
          }}
        >
          {/* //stop button */}
          {isHovered && inferenceState == 3 && (
            <Button
              onClick={() => {
                setEndState(2);
              }}
              style={{
                border: "1px solid black",
                color: "white",
                backgroundColor: "black",
                height: "70%",
                position: "absolute",
                zIndex: "999",
              }}
            >
              stop inference
            </Button>
          )}
          {inferenceState == 1 && (
            <Button
              onClick={() => {
                setEndState(1);
              }}
              style={{
                border: "1px solid black",
                color: "white",
                backgroundColor: "black",
                height: "70%",
                position: "absolute",
                zIndex: "999",
              }}
            >
              reset
            </Button>
          )}
          {inferenceState == 4 && (
            <Button
              onClick={() => {
                setInferenceState(0);
              }}
              style={{
                border: "1px solid black",
                color: "white",
                backgroundColor: "black",
                height: "70%",
                position: "absolute",
                zIndex: "999",
              }}
            >
              reset
            </Button>
          )}
        </Grid>
        {renderMessage()}
      </Grid>

      <Modal
        open={endState != 0}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handelClose}
      >
        <Grid
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 320,
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          <Typography
            id="modal-modal-description"
            style={{ textAlign: "center", padding: "10px" }}
          >
            {endState == 1 ? "reset everything" : ""}
            {endState == 2 ? "inference will be stopped " : ""}
            {endState == 3 ? "inference has been completed" : ""}
          </Typography>

          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              onClick={() => {
                clickRef.current.click();
                setFrameData({});
                setEndState(0);
                setTimeout(() => {
                  setInferenceState(0);
                }, 100);
              }}
              style={{
                backgroundColor: "#ffffff",
                border: "1px solid #102633",
                width: "50%",
                borderRadius: 0,
                color: "#102633",
              }}
            >
              proceed
            </Button>
            <Button
              onClick={() => {
                clickRef.current.click();
                downloadCSV(improvementTime, "improvement");
                downloadCSV(operatorEfficency, "operator");
                setFrameData({});
                setEndState(0);
                setTimeout(() => {
                  setInferenceState(0);
                }, 100);
              }}
              style={{
                color: "white",
                border: "1px solid #102633",
                width: "50%",
                borderRadius: 0,
                backgroundColor: "#102633",
              }}
            >
              download CSV
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
}
export default Player;

import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, Modal } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Snack from "../shared/Snack";

import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  createuser,
  getUsers,
  deleteUsers,
} from "../../slices/loginslice";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function Index({
  setSettingsModal,
  profileExpanded,
  setProfileExpanded,
  setChangePasswordExpanded,
  ChangePasswordExpanded,
  setForgotPasswordModal,
}) {
  const dispatch = useDispatch();
  const role = useSelector((state) => state?.authInfo.loginToken.role);

  const [currentPassword, setCurrentPassword] = useState("");
  const [showCurrentPassword, showSetCurrentPassword] = useState(false);

  const [newpassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassoword] = useState(false);

  const [confirmPassword, setconfirmPassword] = useState("");
  const [showconfirmPassword, setshowconfirmPassword] = useState(false);

  const [name, setName] = useState("");
  const email = useSelector((state) => state?.authInfo.email);

  const [createPassword, setCreatePassword] = useState();
  const [showCreatePassword, setShowCreatePassword] = useState();

  const [confirmPassword2, setconfirmPassword2] = useState("");
  const [showconfirmPassword2, setshowconfirmPassword2] = useState(false);

  const [creteEmail, SetCreteEmail] = useState();
  const [usersList, setUserList] = useState([]);

  const email2 = useSelector((state) => state?.authInfo.loginToken);
  const [emailtoDelete, setEmailToDelete] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const handleClose = () => {
    setEmailToDelete("");
    setDeleteModal(false);
  };

  const inputStyle = {
    backgroundColor: "#ededed",
    border: "1px solid  #ededed",
    "&focused": {
      border: "1px solid #ededed !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderRadius: "125px",
      border: "1px solid #ededed !important",
    },
  };

  const [open, setOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [LicenseOpen, setLicenseOpen] = useState(false);
  const [ViewerOpen, setViewerOpen] = useState(false);

  const handleCustomAlert = (severity, message) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setOpen(true);
  };

  useEffect(() => {
    dispatch(getUsers({ email })).then((res) => {
      setUserList(res.payload.data.users);
    });
  }, []);

  const handleChangeAccordion = (panel) => {
    setProfileExpanded(panel === "profile" ? !profileExpanded : false);
    setLicenseOpen(panel === "license" ? !LicenseOpen : false);
    setChangePasswordExpanded(
      panel === "changePassword" ? !ChangePasswordExpanded : false
    );
    setViewerOpen(panel === "viewerAccount" ? !ViewerOpen : false);
  };

  return (
    <Grid style={{}}>
      <Grid style={{ padding: "2rem" }}>
        {/* Profile  */}
        <Accordion
          expanded={profileExpanded}
          onChange={() => handleChangeAccordion("profile")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography> Profile </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container style={{ height: "7rem" }}>
              <Grid item xs={1}>
                <AccountCircleIcon />
              </Grid>
              <Grid item xs={3} style={{ height: "100%" }} container>
                <Grid
                  item
                  xs={6}
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <Grid> Name :</Grid>
                  <Grid> Email ID : </Grid>
                  <Grid> Role :</Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <Grid>{email2.name}</Grid>
                  <Grid> {email}</Grid>
                  <Grid> {email2.role}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        {/* License  */}

        <Accordion
          expanded={LicenseOpen}
          onChange={() => handleChangeAccordion("license")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography> License </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={2.5}>
                <Grid style={{ margin: "10px 0px" }}> License NO</Grid>
                <Grid style={{ margin: "10px 0px" }}> Purchased By</Grid>
                <Grid style={{ margin: "10px 0px" }}> Purchased Date</Grid>
                <Grid style={{ margin: "10px 0px" }}> Expairy Date</Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid style={{ margin: "10px 0px" }}>: 123456789</Grid>
                <Grid style={{ margin: "10px 0px" }}>: Deepak Pingalay</Grid>
                <Grid style={{ margin: "10px 0px" }}>: 01-01-2024</Grid>
                <Grid style={{ margin: "10px 0px" }}>: 03-31-2024</Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* change password  */}

        <Accordion
          expanded={ChangePasswordExpanded}
          onChange={() => handleChangeAccordion("changePassword")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography> Change Password </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <Grid
                style={{
                  marginTop: "0.4rem",
                  backgroundColor: "#ededed",
                  borderRadius: "4px",
                  position: " relative",
                  width: "20%",
                }}
                container
              >
                <Grid item xs={12}>
                  <TextField
                    required={true}
                    label="Current Password"
                    type={showCurrentPassword ? "text" : "password"}
                    placeholder="Current Password"
                    fullWidth
                    size={"small"}
                    InputProps={{ style: inputStyle }}
                    sx={{
                      borderRadius: "0px",
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "rgba(0,0,0,0)",
                          borderRadius: "0px",
                        },
                    }}
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                </Grid>
                <Grid
                  onClick={() => {
                    showSetCurrentPassword(!showCurrentPassword);
                  }}
                  style={{
                    position: "absolute",
                    right: "5px",
                    cursor: "pointer",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {showCurrentPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </Grid>
              </Grid>
              <Grid
                style={{
                  marginTop: "0.4rem",
                  backgroundColor: "#ededed",
                  borderRadius: "4px",
                  position: " relative",
                  width: "20%",
                }}
                container
              >
                <Grid item xs={12}>
                  <TextField
                    required={true}
                    label="New Password"
                    type={showNewPassword ? "text" : "password"}
                    placeholder="Current Password"
                    fullWidth
                    size={"small"}
                    InputProps={{ style: inputStyle }}
                    sx={{
                      borderRadius: "0px",
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "rgba(0,0,0,0)",
                          borderRadius: "0px",
                        },
                    }}
                    value={newpassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Grid>
                <Grid
                  onClick={() => setShowNewPassoword(!showNewPassword)}
                  style={{
                    position: "absolute",
                    right: "5px",
                    cursor: "pointer",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </Grid>
              </Grid>
              <Grid
                style={{
                  marginTop: "0.4rem",
                  backgroundColor: "#ededed",
                  borderRadius: "4px",
                  position: " relative",
                  width: "20%",
                }}
                container
              >
                <Grid item xs={12}>
                  <TextField
                    required={true}
                    label="Confirm password"
                    type={showconfirmPassword ? "text" : "password"}
                    placeholder="Current Password"
                    fullWidth
                    size={"small"}
                    InputProps={{ style: inputStyle }}
                    sx={{
                      borderRadius: "0px",
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "rgba(0,0,0,0)",
                          borderRadius: "0px",
                        },
                    }}
                    value={confirmPassword}
                    onChange={(e) => setconfirmPassword(e.target.value)}
                  />
                </Grid>
                <Grid
                  onClick={() => setshowconfirmPassword(!showconfirmPassword)}
                  style={{
                    position: "absolute",
                    right: "5px",
                    cursor: "pointer",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {showconfirmPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </Grid>
              </Grid>

              <Grid>
                <Button
                  onClick={() => {
                    if (newpassword == confirmPassword) {
                      dispatch(
                        changePassword({
                          email,
                          password: currentPassword,
                          newpassword: newpassword,
                        })
                      ).then((res) => {
                        if (res?.payload?.data?.message == "Invalid password") {
                          setCurrentPassword("");
                          setNewPassword("");
                          setconfirmPassword("");
                          handleCustomAlert(
                            "error",
                            "Invalid current password"
                          );
                        }
                        if (
                          res?.payload?.message ==
                          "password updated sucessfully"
                        ) {
                          handleCustomAlert(
                            "success",
                            "password has been updated successfully"
                          );

                          setCurrentPassword("");
                          setNewPassword("");
                          setconfirmPassword("");
                        }
                      });
                    } else {
                      setCurrentPassword("");
                      setNewPassword("");
                      setconfirmPassword("");
                      handleCustomAlert("warning", "passwords dosent match");
                    }
                  }}
                  style={{ backgroundColor: "#102633", color: "#ffffff" }}
                >
                  change{" "}
                </Button>
              </Grid>
            </Grid>
            <Grid
              onClick={() => {
                setSettingsModal(false);
                setForgotPasswordModal(true);
              }}
              style={{
                textDecoration: "underline",
                textUnderlineOffset: "6px",
                marginTop: "10px",
                cursor: "pointer",
              }}
            >
              {" "}
              Forgot Password ?
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Viewer account   */}
        <Accordion
          disabled={role !== "admin"}
          expanded={ViewerOpen}
          onChange={() => handleChangeAccordion("viewerAccount")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography> Viewer Account </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              style={{
                height: " 100px",
                overflowY: usersList?.length > 2 ? "scroll" : "",
              }}
            >
              <Grid
                container
                style={{
                  display: "flex",

                  backgroundColor: "#102633",
                  color: "#fff",
                  padding: "5px",
                }}
              >
                <Grid item xs={3}>
                  {" "}
                  Name
                </Grid>
                <Grid item xs={3}>
                  {" "}
                  Email
                </Grid>
                <Grid item xs={2}>
                  {" "}
                  Created By
                </Grid>
                <Grid item xs={3}>
                  {" "}
                  Latest Login date
                </Grid>
              </Grid>

              {usersList?.map((element, index) => {
                return (
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      backgroundColor: index % 2 == 0 ? "#ffffff" : "#E6E9EA",
                      color: "#102633",
                      padding: "5px",
                    }}
                  >
                    <Grid item xs={3}>
                      {element.name}
                    </Grid>
                    <Grid item xs={3}>
                      {element.email}
                    </Grid>
                    <Grid item xs={2}>
                      {element.created_by}
                    </Grid>
                    <Grid item xs={3}>
                      {element.last_login_at || "new user"}
                    </Grid>
                    <Grid
                      item
                      style={{ cursor: "pointer" }}
                      xs={1}
                      onClick={() => {
                        setDeleteModal(true);
                        setEmailToDelete(element.email);
                        // dispatch(deleteUsers({ email: element.email })).then(
                        //   () => {
                        //     dispatch(getUsers({ email })).then((res) => {
                        //       setUserList(res.payload.data.users);
                        //     });
                        //   }
                        // );
                      }}
                    >
                      <DeleteOutlineIcon />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            <Grid
              container
              style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid item xs={8} container>
                  <Grid item xs={6}>
                    <TextField
                      required={true}
                      label="Name"
                      type="text"
                      placeholder="Name"
                      fullWidth
                      size={"small"}
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      InputProps={{ style: inputStyle }}
                      sx={{
                        borderRadius: "0px",
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "rgba(0,0,0,0)",
                            borderRadius: "0px",
                          },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required={true}
                      label="Email ID"
                      type="text"
                      placeholder="Email ID"
                      fullWidth
                      size={"small"}
                      value={creteEmail}
                      onChange={(e) => {
                        SetCreteEmail(e.target.value);
                      }}
                      InputProps={{ style: inputStyle }}
                      sx={{
                        borderRadius: "0px",
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "rgba(0,0,0,0)",
                            borderRadius: "0px",
                          },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      marginTop: "0.4rem",
                      backgroundColor: "#ededed",
                      borderRadius: "4px",
                      position: " relative",
                    }}
                    container
                  >
                    <Grid item xs={12}>
                      <TextField
                        required={true}
                        label="Create Password"
                        type={showCreatePassword ? "text" : "password"}
                        placeholder="Current Password"
                        fullWidth
                        size={"small"}
                        InputProps={{ style: inputStyle }}
                        sx={{
                          borderRadius: "0px",
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "rgba(0,0,0,0)",
                              borderRadius: "0px",
                            },
                        }}
                        value={createPassword}
                        onChange={(e) => {
                          setCreatePassword(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid
                      onClick={() => setShowCreatePassword(!showCreatePassword)}
                      style={{
                        position: "absolute",
                        right: "5px",
                        cursor: "pointer",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {showCreatePassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      marginTop: "0.4rem",
                      backgroundColor: "#ededed",
                      borderRadius: "4px",
                      position: " relative",
                    }}
                    container
                  >
                    <Grid item xs={12}>
                      <TextField
                        required={true}
                        label="Confirm Password"
                        type={showconfirmPassword2 ? "text" : "password"}
                        placeholder="Confirm Password"
                        fullWidth
                        size={"small"}
                        InputProps={{ style: inputStyle }}
                        sx={{
                          borderRadius: "0px",
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "rgba(0,0,0,0)",
                              borderRadius: "0px",
                            },
                        }}
                        value={confirmPassword2}
                        onChange={(e) => setconfirmPassword2(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      onClick={() =>
                        setshowconfirmPassword2(!showconfirmPassword2)
                      }
                      style={{
                        position: "absolute",
                        right: "5px",
                        cursor: "pointer",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {showconfirmPassword2 ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "end",
                  }}
                >
                  <Button
                    onClick={() => {
                      if (
                        name == "" ||
                        email == "" ||
                        createPassword == "" ||
                        creteEmail == "" ||
                        confirmPassword2 == ""
                      ) {
                      } else {
                        if (createPassword == confirmPassword2) {
                          dispatch(
                            createuser({
                              username: name,
                              email: email,
                              useremail: creteEmail,
                              userpassword: createPassword,
                            })
                          ).then((res) => {
                            if (res.payload.message == "user created") {
                              dispatch(getUsers({ email })).then((res) => {
                                setUserList(res.payload.data.users);
                              });
                              setCreatePassword("");
                              setName("");
                              setconfirmPassword2("");
                              SetCreteEmail("");
                              handleCustomAlert(
                                "success",
                                "user created successfully"
                              );
                            } else if (
                              res.payload.data.message ==
                              "Email is already registered"
                            ) {
                              setCreatePassword("");
                              setName("");
                              setconfirmPassword2("");
                              SetCreteEmail("");
                              handleCustomAlert(
                                "warning",
                                "Email is already registered"
                              );
                            }
                          });
                        } else {
                          setName("");
                          setCreatePassword("");
                          setconfirmPassword2("");
                          SetCreteEmail("");
                          handleCustomAlert("error", "passwords dosent match");
                        }
                      }
                    }}
                    style={{
                      backgroundColor:
                        name == "" ||
                        email == "" ||
                        createPassword == "" ||
                        creteEmail == "" ||
                        confirmPassword2 == ""
                          ? "#ededed"
                          : "#102633",
                      color: "#ffffff",
                    }}
                  >
                    create viewer{" "}
                  </Button>
                </Grid>
              </Grid>

              <Grid
                style={{
                  marginTop: "0.4rem",
                  backgroundColor: "#ededed",
                  borderRadius: "4px",
                  position: " relative",
                  width: "20%",
                }}
                container
              ></Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Snack
        open={open}
        alertSeverity={alertSeverity}
        alertMessage={alertMessage}
        setOpen={setOpen}
      />

      <Modal
        open={deleteModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 420,
            bgcolor: "background.paper",

            boxShadow: 24,
          }}
        >
          <Typography
            id="modal-modal-description"
            style={{ textAlign: "center", padding: "10px" }}
          >
            Are you sure you want to delete the viewer
          </Typography>
          <Grid style={{ textAlign: "center" }}> {emailtoDelete} ?</Grid>

          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{
                color: "black",
                border: "1px solid #102633",
                width: "50%",
                borderRadius: 0,
              }}
              onClick={handleClose}
            >
              {" "}
              no{" "}
            </Button>
            <Button
              style={{
                color: "white",
                border: "1px solid #102633",
                width: "50%",
                borderRadius: 0,
                backgroundColor: "#102633",
              }}
              onClick={() => {
                dispatch(deleteUsers({ email: emailtoDelete })).then(() => {
                  dispatch(getUsers({ email })).then((res) => {
                    setUserList(res.payload.data.users);
                    handleClose();
                  });
                });
              }}
            >
              {" "}
              yes{" "}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
}

export default Index;

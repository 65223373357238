import { useEffect } from "react";

const DaysSelector = (props) => {
  const { selectedDays, setSelectedDays, setScheduleButtonDisabled } = props;

  useEffect(() => {
    if (selectedDays.length === 0) {
      setScheduleButtonDisabled(true);
    }
  }, [selectedDays]);

  const daysContainerStyle = {
    display: "flex",
    justifyContent: "flex-start",
    gap: "1vw",
    marginTop: "20px",
  };

  const dayStyle = {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    backgroundColor: "#bebebe",
    fontSize: "10px",
    cursor: "pointer",
    transition: "background-color: 0.3s",
  };

  const daysSelectedStyle = {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#505050",
    fontSize: "10px",
    color: "white",
    cursor: "pointer",
    transition: "background-color: 0.3s",
  };

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const toggleDay = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(
        selectedDays.filter((selectedDay) => selectedDay !== day)
      );
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const handleClick = (day) => {
    toggleDay(day);
    setScheduleButtonDisabled(false);
  };

  return (
    <div>
      <div style={daysContainerStyle}>
        {daysOfWeek.map((day) => (
          <div
            key={day}
            style={selectedDays.includes(day) ? daysSelectedStyle : dayStyle}
            onClick={() => handleClick(day)}
          >
            {day.substring(0, 3)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DaysSelector;

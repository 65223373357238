import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const mainUrl = process.env.REACT_APP_MAIN_URL;

// const errorSet = (err, thunkAPI) => {
//   err === "401" && thunkAPI.dispatch(validAuthReducer(true));
//   err === "413" && thunkAPI.dispatch(validAuthReducer(true));
// };

export const loginApi = createAsyncThunk(
  "login/post",
  async (userInfo, thunkAPI) => {
    try {
      const res = await axios.post(`${mainUrl}/auth/login`, userInfo);
      return res.data;
    } catch (err) {
      // sessionStorage.setItem("errorMessage", err.response.status);
      // errorSet(err.response.status, thunkAPI);
      return err.response;
    }
  }
);

export const generateOtp = createAsyncThunk(
  "login/post",
  async (userInfo, thunkAPI) => {
    try {
      const res = await axios.post(`${mainUrl}/auth/generateOtp`, userInfo);

      return res.data;
    } catch (err) {
      return err.response;
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "login/post",
  async (userInfo, thunkAPI) => {
    try {
      const res = await axios.post(`${mainUrl}/auth/verifyOtp`, userInfo);

      return res.data;
    } catch (err) {
      return err.response;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "login/post",
  async (userInfo, thunkAPI) => {
    try {
      const res = await axios.put(`${mainUrl}/auth/resetPassword`, userInfo);
      return res.data;
    } catch (err) {
      return err.response;
    }
  }
);

export const changePassword = createAsyncThunk(
  "login/post",
  async (userInfo, thunkAPI) => {
    try {
      const res = await axios.put(`${mainUrl}/auth/changePassword`, userInfo);
      return res.data;
    } catch (err) {
      return err.response;
    }
  }
);

export const getUsers = createAsyncThunk(
  "getUsers",
  async (userInfo, thunkAPI) => {
    const { authInfo } = thunkAPI.getState();
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${authInfo.loginToken.token}`,
        },
      };
      const res = await axios.get(
        `${mainUrl}/auth/viewuser?email=${authInfo.email}`,
        config
      );

      return res;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
);

export const deleteUsers = createAsyncThunk(
  "deleteUsers",
  async (userInfo, thunkAPI) => {
    const { authInfo } = thunkAPI.getState();
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${authInfo.loginToken.token}`,
        },
      };

      const res = await axios.delete(
        `${mainUrl}/auth/deleteuser?email=${authInfo.email}&emailToDelete=${userInfo.email}`,
        config
      );

      return res;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
);

export const createuser = createAsyncThunk(
  "login/post",
  async (userInfo, thunkAPI) => {
    try {
      const res = await axios.post(`${mainUrl}/auth/createuser`, userInfo);

      return res.data;
    } catch (err) {
      return err.response;
    }
  }
);

export const loginSlice = createSlice({
  name: "loginSlice",
  initialState: {},
  reducers: {},
  extraReducers: {},
});

export const {} = loginSlice.actions;
export default loginSlice.reducer;

import React, { useRef, useState, useEffect } from "react";
import { Grid, Modal, useMediaQuery } from "@mui/material";
import LinearProgress from "@mui/joy/LinearProgress";
import { useSelector } from "react-redux";
import { parseFilename } from "../../utils";
import BootstrapButton from "../../components/button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Button from "@mui/material/Button";
import { uploadVideo, getMachineData } from "../../services/inferenceService";
import moment from "moment";
import SelectCameraModal from "./selectCameraModal";
function UploadButton({ disabled, onUploadComplete, setInferenceState }) {
  const inputFileRef = useRef(null);

  // states
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentUploadingFile, setCurrentUploadingFile] = useState(null);
  const [cameraModal, setCameraModal] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const matchesLg = useMediaQuery("(min-width:1536px)");
  const cancelFileUpload = useRef(null);

  const {
    email,
    loginToken: { token },
  } = useSelector((state) => state.authInfo);

  const handleUploadClick = () => {
    if (disabled) return;
    inputFileRef.current.value = "";
    inputFileRef.current.click();
  };

  const obj = {
    199: "cam_13",
    1100: "cam_14",
    1101: "cam_15",
    1102: "cam_16",
    1103: "cam_17",
    1104: "cam_18",
    1105: "cam_19",
    1106: "cam_20",
  };

  const handleFileChange = async (e) => {
    getMachineData({ email, token }).then((res) => {
      if (res.data.message == "machine are available") {
        const selectedFileLocal = e.target.files[0];
        setSelectedFile(selectedFileLocal);
        if (selectedFileLocal) {
          let fileName = selectedFileLocal.name;
          const parsedFileName = parseFilename(fileName);

          if (!parsedFileName) {
            setCameraModal(true);
          } else {
            setCurrentUploadingFile(selectedFileLocal);
            uploadVideo(
              selectedFileLocal,
              token,
              email,
              setUploadProgress,
              cancelFileUpload,
              fileName
            ).then((res) => {
              const videoId = res.data.videoId;
              onUploadComplete(selectedFileLocal, videoId);
              setUploadProgress(0);
            });
          }
        } else {
          alert("Please select a file.");
        }
      } else {
        setInferenceState(4);
      }
    });
  };

  useEffect(() => {
    if (selectedCamera) {
      var currentDate = moment().format("YYYY-MM-DD");
      const name = `${currentDate}_${obj[selectedCamera]}_video[${selectedCamera}].mp4`;
      setCameraModal(false);
      setCurrentUploadingFile(selectedFile);
      uploadVideo(
        selectedFile,
        token,
        email,
        setUploadProgress,
        cancelFileUpload,
        name
      ).then((res) => {
        const videoId = res.data.videoId;
        onUploadComplete(selectedFile, videoId);
        setUploadProgress(0);
      });
    }
  }, [selectedCamera]);

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
    inputFileRef.current.value = "";
    setUploadProgress(0);
    setCurrentUploadingFile(null);
  };
  const handleClose = () => {
    setCameraModal(false);
  };

  return (
    <>
      <input
        type="file"
        accept="video/*"
        style={{ display: "none" }}
        ref={inputFileRef}
        onChange={handleFileChange}
      />
      <BootstrapButton onClick={handleUploadClick} disabled={disabled}>
        Upload Video <FileUploadIcon color="white" />
      </BootstrapButton>

      {/* Progressbar model */}

      <Modal
        open={uploadProgress > 0.1}
        // open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 420,
            bgcolor: "background.paper",
            padding: "1rem",
            boxShadow: 24,
          }}
        >
          <Grid style={{ padding: "0.75rem" }}>
            <Grid container style={{ display: "flex", alignItems: "center" }}>
              <Grid
                item
                xs={1}
                style={{ fontSize: matchesLg ? "12px" : "8px" }}
              >
                {" "}
              </Grid>

              <Grid item xs={8}>
                <LinearProgress
                  determinate
                  variant="outlined"
                  value={uploadProgress}
                  sx={{
                    "--LinearProgress-radius": "0px",
                    "--LinearProgress-progressThickness": "1.8vh",
                    borderColor: `${`#4EC1C1`}`,
                    color: `${`#A4DFDF`}`,
                    height: "2vh",
                  }}
                ></LinearProgress>
              </Grid>
              <Grid
                item
                xs={1}
                style={{ fontSize: matchesLg ? "12px" : "8px" }}
              ></Grid>
              <Grid
                item
                xs={1}
                style={{ fontSize: matchesLg ? "12px" : "8px" }}
              >
                {" "}
                {`${uploadProgress}%`}
              </Grid>
              <Grid item xs={12} style={{ marginTop: "25px" }}>
                video file : {currentUploadingFile?.name}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: "25px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => cancelUpload()}
                  style={{
                    width: "100%",
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  cancel upload{" "}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ padding: "0.75rem" }}>
            Inference starts automatically once upload is completed
          </Grid>
        </Grid>
      </Modal>

      <SelectCameraModal
        open={cameraModal}
        handleClose={handleClose}
        selectedCamera={selectedCamera}
        onChange={setSelectedCamera}
      />
    </>
  );
}

export default UploadButton;

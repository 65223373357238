import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const BootstrapButton = styled(Button)(({ disabled }) => ({
  color: "#fff",
  boxShadow: "none",
  textTransform: "none",
  fontSize: 10,
  width: "9vw",
  gap: "0.4vw",
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: disabled ? "lightgrey" : "#102633",
  borderColor: "grey",
  "&:hover": {
    backgroundColor: disabled ? "lightgrey" : "#102658",
    borderColor: "grey",
    boxShadow: "none",
    cursor: disabled ? "not-allowed" : "pointer",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: disabled ? "lightgrey" : "#0062cc",
    borderColor: "grey",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
}));

BootstrapButton.defaultProps = { disabled: false };

export default BootstrapButton;

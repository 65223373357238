import React, { useState, useEffect } from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import Floor from "../../assets/FloorPlan.png";
import MiniGraph from "./miniGraph";
import UploadButton from "./uploadButton";
import StartLiveButton from "./startLiveButton";

function InformationArea({
  inferenceState,
  onUploadComplete,
  onSelectLive,
  setInferenceState,
}) {
  function convertToNewFormat(data) {
    const result = { 3: 0, 4: 0, 5: 0 };

    for (const key in data) {
      const item = data[key];
      const classValue = item.class;
      result[classValue] = 1;
    }

    return result;
  }
  const matchesLg = useMediaQuery("(min-width:1536px)");

  const improvementTime = useSelector(
    (state) => state.authInfo.improvementTime
  );

  const [obj, setObj] = useState({ 3: 0, 4: 0, 5: 0 });

  const total = obj[3] + obj[4] + obj[5];

  useEffect(() => {
    setObj(convertToNewFormat(improvementTime));
  }, [improvementTime]);
  return (
    <Grid
      style={{
        height: " 22%",
        padding: "1.25rem 0.625rem 0.625rem 1.25rem",
      }}
    >
      <Grid
        container
        style={{
          height: " 100%",
          backgroundColor: "white",
          borderTop: "1px solid  #9A9A9A",
          borderBottom: "1px solid  #9A9A9A",
          borderLeft: "1px solid  #9A9A9A",
        }}
      >
        {/* Start Upload and webcam icon */}
        <Grid
          item
          xs={2.5}
          style={{
            padding: "0vh 1vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            borderRight: "1px solid #9A9A9A",
            height: " 100%",
          }}
        >
          <UploadButton
            disabled={inferenceState}
            onUploadComplete={onUploadComplete}
            setInferenceState={setInferenceState}
          />
          <StartLiveButton
            disabled={inferenceState}
            onSelectLive={onSelectLive}
          />
        </Grid>
        {/* End Upload and webcam icon */}

        {/* Start Floor plan */}
        <Grid
          xs={3.5}
          style={{
            padding: "1rem 0.5rem",
            borderRight: "1px solid #9A9A9A",
          }}
        >
          <Typography style={{ fontSize: matchesLg ? "12px" : "8px" }}>
            {" "}
            Floor Plan showing camera positions{" "}
          </Typography>

          <img src={Floor} alt="" style={{ width: "100%", height: "90%" }} />
        </Grid>
        {/* Start total production */}
        <Grid xs={5} style={{ padding: "1vh 1vw", height: "100%" }}>
          <Grid style={{ height: " 20%" }}>Total production</Grid>

          <Grid
            style={{
              height: "80%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <>
              <MiniGraph
                value={obj[4]}
                borderColor={"#F57805"}
                color={"#FBC99B"}
                percentage={total == 0 ? 0 : (obj[4] / total) * 100}
                type={"Z400"}
              />
              <MiniGraph
                value={obj[3]}
                borderColor={"#FF6384"}
                color={"#FFB5C5"}
                percentage={total == 0 ? 0 : (obj[3] / total) * 100}
                type={"Z200"}
              />
              <MiniGraph
                value={obj[5]}
                borderColor={"#4EC1C1"}
                color={"#A4DFDF"}
                percentage={total == 0 ? 0 : (obj[5] / total) * 100}
                type={"Z200 SMALL"}
              />
            </>
          </Grid>
        </Grid>
        {/* End total production */}

        <Grid
          xs={1}
          style={{
            backgroundColor: "#102633",
            color: "white",
            height: "100%",
            width: "100%",
            fontSize: "4rem",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            borderRight: "5px solid  #102633",
          }}
        >
          {total}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InformationArea;

import LinearProgress from "@mui/joy/LinearProgress";
import { Grid, useMediaQuery, Typography } from "@mui/material";

function MiniGraph({ min, max, borderColor, color, percentage, type, value }) {
  const matchesLg = useMediaQuery("(min-width:1536px)");

  return (
    <Grid container style={{ display: "flex", alignItems: "center" }}>
      <Grid
        item
        xs={3}
        style={{ fontSize: matchesLg ? "12px" : "8px", fontWeight: "700" }}
      >
        {type}
      </Grid>
      <Grid item xs={1.8} style={{ fontSize: matchesLg ? "12px" : "8px" }}>
        {`${percentage}%`}
      </Grid>
      <Grid item xs={6.7}>
        <LinearProgress
          determinate
          variant="outlined"
          value={percentage}
          sx={{
            "--LinearProgress-radius": "0px",
            "--LinearProgress-progressThickness": "1.8vh",
            borderColor: `${borderColor}`,
            color: `${color}`,
            height: "2vh",
          }}
        ></LinearProgress>
      </Grid>
      <Grid
        item
        xs={0.5}
        style={{ fontSize: matchesLg ? "12px" : "8px", textAlign: "end" }}
      >
        {" "}
        {value}{" "}
      </Grid>
    </Grid>
  );
}

export default MiniGraph;

import React, { useState, useRef, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import Navbar from "../shared/Navbar";
import Appbar from "../shared/Appbar";
import AvgGraph from "./Avggraph";
import Mangraph from "./mangraph";
import UnitGraph from "./unitGraph";
import DateSelector from "../shared/DateSelector";
import DownloadIcon from "@mui/icons-material/Download";
import { DotLoader } from "react-spinners";

import {
  availabledate,
  getHistoryGraphData,
  setSecondUser,
  downloadHistoricalCSV,
} from "../../slices/authInfo";
import { useDispatch, useSelector } from "react-redux";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function Index() {
  const [allowedDates, setAllowedDates] = useState();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();

  const [graphdataAVG, setGraphdataAVG] = useState();
  const [graphdataUNIT, setGraphdataUNIT] = useState();
  const [graphdataMAN, setGraphdataMAN] = useState();
  const [manAvg, setManAvg] = useState();

  const [avgGraph, setAvgGraph] = useState(true);
  const [manGraph, setManGraph] = useState(true);
  const [unitGraph, setUnitGraph] = useState(true);
  const [graphCount, setGraphCount] = useState(0);
  const [fileName, setFileName] = useState();
  const email = useSelector((state) => state?.authInfo.email);
  const [loading, setLoading] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    const graphcountfunc = () => {
      if (unitGraph && manGraph && avgGraph) {
        setGraphCount(3);
        return;
      }
      if (manGraph && unitGraph) {
        setGraphCount(2);
        return;
      }
      if (manGraph && avgGraph) {
        setGraphCount(2);
        return;
      }
      if (unitGraph && avgGraph) {
        setGraphCount(2);
        return;
      }
      if (unitGraph) {
        setGraphCount(1);
        return;
      }
      if (manGraph) {
        setGraphCount(1);
        return;
      }
      if (avgGraph) {
        setGraphCount(1);
        return;
      }
    };
    graphcountfunc();
  }, [unitGraph, manGraph, avgGraph]);

  useEffect(() => {
    dispatch(availabledate()).then((res) => {
      if (
        res?.payload?.response?.data?.message.includes("Another User logged in")
      ) {
        dispatch(setSecondUser(true));
      } else {
        setAllowedDates(
          res?.payload?.available_dates?.filter(
            (date) => date != "Invalid date"
          )
        );
      }
    });
  }, []);

  useEffect(() => {
    if (allowedDates) {
      setStartDate(allowedDates[0]);
      setEndDate(allowedDates[allowedDates.length - 1]);
      setMinDate(allowedDates[0]);
      setMaxDate(allowedDates[allowedDates.length - 1]);
      callData(allowedDates[0], allowedDates[allowedDates.length - 1]);
    }
  }, [allowedDates]);

  function callData(startDate, endDate) {
    setLoading(true);

    dispatch(
      getHistoryGraphData({
        start: startDate,
        end: endDate,
        type: "average operator efficiency",
      })
    ).then((res) => {
      if (
        res?.payload?.response?.data?.message.includes("Another User logged in")
      ) {
        setLoading(false);
        dispatch(setSecondUser(true));
      } else {
        setGraphdataAVG(res.payload.data);
        setLoading(false);
        dispatch(
          getHistoryGraphData({
            start: startDate,
            end: endDate,
            type: "unit number",
          })
        ).then((res) => {
          setGraphdataUNIT(res.payload.data);
          dispatch(
            getHistoryGraphData({
              start: startDate,
              end: endDate,
              type: "man hour",
            })
          ).then((res) => {
            setGraphdataMAN(res.payload.data);
            setManAvg(res.payload.avg_man_hour_value);
          });
        });
      }
    });
  }

  useEffect(() => {
    setFileName(`data-${startDate}to${endDate}`);
  }, [endDate, startDate]);

  return (
    <Grid
      style={{
        height: "100vh",
        minWidth: "1024px",
        minHeight: "640px",
      }}
    >
      <Grid style={{ height: "10%" }}>
        <Appbar />
        <Navbar />
      </Grid>
      {loading && (
        <Grid
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.25)",
            zIndex: 555,
          }}
        >
          <DotLoader color="#141414" />
        </Grid>
      )}
      <Grid
        container
        item
        xs={12}
        style={{ height: "90%", backgroundColor: "#E3E3E3" }}
      >
        {/* date selector */}

        <DateSelector
          availabledate={allowedDates}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          maxDate={maxDate}
          minDate={minDate}
          setGraphdataAVG={setGraphdataAVG}
          setGraphdataUNIT={setGraphdataUNIT}
          setGraphdataMAN={setGraphdataMAN}
          setManAvg={setManAvg}
          callData={callData}
        />

        {graphCount < 3 && (
          <Grid
            style={{
              height: "5%",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {!avgGraph && (
              <Grid
                onClick={() => setAvgGraph(true)}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  borderRadius: "20px",
                  width: "240px",

                  cursor: "pointer",
                  margin: "0px 25px",
                  fontSize: "14px",
                }}
              >
                Average Operator Efficiency
                <VisibilityOffIcon />
              </Grid>
            )}

            {!unitGraph && (
              <Grid
                onClick={() => setUnitGraph(true)}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  borderRadius: "20px",
                  width: "240px",
                  cursor: "pointer",
                  margin: "0px 25px",
                }}
              >
                Unit Number
                <VisibilityOffIcon />
              </Grid>
            )}

            {!manGraph && (
              <Grid
                onClick={() => setManGraph(true)}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  borderRadius: "20px",
                  width: "240px",

                  cursor: "pointer",
                  margin: "0px 25px",
                }}
              >
                Man Hour Value
                <VisibilityOffIcon />
              </Grid>
            )}
          </Grid>
        )}

        {graphCount == 3 && (
          <Grid style={{ height: "86%", width: "100%" }}>
            <Grid
              style={{
                height: "50%",
                display: "flex",
                width: "100%",
              }}
            >
              <Grid
                style={{
                  width: "50%",
                  height: "100%",
                  padding: "0.5rem 0.5rem 0.5rem 1rem",
                }}
              >
                <Mangraph
                  graphdata={graphdataMAN}
                  manAvg={manAvg}
                  manGraph={manGraph}
                  setManGraph={setManGraph}
                  graphCount={graphCount}
                />
              </Grid>
              <Grid
                style={{
                  width: "50%",
                  height: "100%",
                  padding: "0.5rem 1rem 0.5rem 0.5rem",
                }}
              >
                <UnitGraph
                  graphdata={graphdataUNIT}
                  setUnitGraph={setUnitGraph}
                  unitGraph={unitGraph}
                  graphCount={graphCount}
                />
              </Grid>
            </Grid>
            <Grid style={{ height: "50%", padding: "0.5rem 1rem 1rem 1rem" }}>
              <AvgGraph
                graphdata={graphdataAVG}
                avgGraph={avgGraph}
                setAvgGraph={setAvgGraph}
                graphCount={graphCount}
              />
            </Grid>
          </Grid>
        )}

        {graphCount == 2 && (
          <Grid style={{ height: "80%", width: "100%" }}>
            {manGraph && (
              <Grid
                style={{
                  height: "50%",
                  display: "flex",
                  width: "100%",
                  padding: "1rem",
                }}
              >
                <Mangraph
                  graphdata={graphdataMAN}
                  manAvg={manAvg}
                  manGraph={manGraph}
                  setManGraph={setManGraph}
                  graphCount={graphCount}
                />
              </Grid>
            )}
            {unitGraph && (
              <Grid style={{ height: "50%", padding: "1rem" }}>
                <UnitGraph
                  graphdata={graphdataUNIT}
                  setUnitGraph={setUnitGraph}
                  unitGraph={unitGraph}
                  graphCount={graphCount}
                />
              </Grid>
            )}

            {avgGraph && (
              <Grid style={{ height: "50%", padding: "1rem" }}>
                <AvgGraph
                  graphdata={graphdataAVG}
                  avgGraph={avgGraph}
                  setAvgGraph={setAvgGraph}
                  graphCount={graphCount}
                />
              </Grid>
            )}
          </Grid>
        )}

        {graphCount == 1 && (
          <Grid style={{ height: "80%", width: "100%" }}>
            <Grid style={{ height: "100%", display: "flex", width: "100%" }}>
              <Grid style={{ width: "100%", height: "100%", padding: "1rem" }}>
                {manGraph && (
                  <Mangraph
                    graphdata={graphdataMAN}
                    manAvg={manAvg}
                    manGraph={manGraph}
                    setManGraph={setManGraph}
                    graphCount={graphCount}
                  />
                )}
                {unitGraph && (
                  <UnitGraph
                    graphdata={graphdataUNIT}
                    setUnitGraph={setUnitGraph}
                    unitGraph={unitGraph}
                    graphCount={graphCount}
                  />
                )}
                {avgGraph && (
                  <AvgGraph
                    graphdata={graphdataAVG}
                    avgGraph={avgGraph}
                    setAvgGraph={setAvgGraph}
                    graphCount={graphCount}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default Index;

// Dependencies imports
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useMediaQuery, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";

function Improvement() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  function convertDataStructure(inputData) {
    const outputData = {};
    if (inputData) {
      Object?.keys(inputData)?.forEach((key) => {
        const unit = parseInt(key.match(/\d+/)[0], 10);
        outputData[unit] = {
          unit: unit,
          type: checktype(inputData[key].class),
          time: inputData[key].function,
        };
      });

      return outputData;
    }
  }
  const checktype = (type) => {
    if (type == 3) {
      return "Z200";
    }

    if (type == 4) {
      return "Z400";
    }

    if (type == 5) {
      return "Z200 Small";
    }
  };
  const improvementTime =
    useSelector((state) => state?.authInfo?.improvementTime) || {};

  const obj = convertDataStructure(improvementTime);

  let arr = Object.keys(obj) || [];
  let graphdata = [];
  for (let x of arr) {
    graphdata.push(obj[x]);
  }

  const [chartKey, setChartKey] = useState(0);
  const matchesLg = useMediaQuery("(min-width:1500px)");
  const matchesXlg = useMediaQuery("(min-width:1600px)");

  const options = {
    maintainAspectRatio: false,
    barPercentage: 15,
    categoryPercentage: 0.05,
    responsive: true,

    scales: {
      x: {
        ticks: {
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
        title: {
          display: true,
          text: "Time (sec)  → ",
          color: "#000000",
          font: {
            size: matchesLg ? 16 : 10,
            padding: 0,
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        align: "end",
        labels: {
          boxWidth: 10,
          usePointStyle: true,
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
      },
    },
  };
  const dispatch = useDispatch();

  const generateChartData = (data) => {
    const Z400 = [];
    const Z200 = [];
    const Z200_small = [];
    const Unit = [];

    data?.forEach((operator) => {
      Unit.push(operator.unit);
      if (operator.type === "Z200") {
        Z200.push(operator.time);
        Z400.push(0);
        Z200_small.push(0);
      }
      if (operator.type === "Z400") {
        Z400.push(operator.time);
        Z200.push(0);
        Z200_small.push(0);
      }
      if (operator.type === "Z200 Small") {
        Z400.push(0);
        Z200.push(0);
        Z200_small.push(operator.time);
      }
    });

    let final = [
      {
        label: "Z400",
        data: Z400,
        backgroundColor: "rgba(251, 201, 155, 0.6)",
        borderColor: "rgba(251, 201, 155, 1)",
        borderWidth: 1,
      },
      {
        label: "Z200",
        data: Z200,
        backgroundColor: "rgba(255, 181, 197, 0.6)",
        borderColor: "rgba(255, 181, 197, 1)",
        borderWidth: 1,
      },
      {
        label: "Z200 SMALL",
        data: Z200_small,
        backgroundColor: "rgba(164, 223, 223, 0.6)",
        borderColor: "rgba(164, 223, 223, 1)",
        borderWidth: 1,
      },
    ];

    return {
      labels: Unit,
      datasets: final,
    };
  };
  const [index, setindex] = useState(0);
  useEffect(() => {
    setindex(0);
  }, [graphdata]);

  const [currentPage, setCurrentPage] = useState(1);

  let chartData = generateChartData(
    graphdata?.slice(0 + (currentPage - 1) * 30, currentPage * 30)
  );

  let totalPages = Math.ceil(graphdata?.length / 30);
  useEffect(() => {
    setCurrentPage(totalPages);
  }, [totalPages, chartData]);
  const dots = Array.from({ length: totalPages }, (_, index) => index + 1);

  const handleDotClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Grid
      item
      xs={12}
      container
      style={{ height: "100%", border: "1px solid  #9A9A9A" }}
    >
      <Grid
        item
        xs={12}
        style={{
          height: "100%",
          position: "relative",
          backgroundColor: "white",
        }}
      >
        <Grid
          style={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: "16px",
            height: "10%",
            height: "10%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          TOTAL IMPROVEMENT TIME
        </Grid>

        <Grid container style={{ width: "100%", height: "85%" }}>
          <Grid item xs={12} style={{ width: "100%", height: "100%" }}>
            <Bar
              key={chartKey}
              options={options}
              data={chartData}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Grid>
        </Grid>
        <Grid
          style={{ height: "5%", display: "flex", justifyContent: " center" }}
        >
          <div className="dot-pagination">
            {dots.map((dot) => (
              <span
                key={dot}
                className={`dot ${dot === currentPage ? "active" : ""}`}
                onClick={() => handleDotClick(dot)}
              />
            ))}
          </div>
        </Grid>
        <Grid
          style={{
            position: "absolute",
            bottom: 8,
            left: "10%",
            width: "15vw",
            fontSize: matchesLg ? 16 : 12,
          }}
        >
          Units →
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Improvement;

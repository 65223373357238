import React, { useState, useEffect } from 'react';

const objectColors = {
    "person": {
        "color": "#FF0000",
        "label": "Person"
    },
    "machine": {
        "color": "#00FF00",
        "label": "Machine"
    },
    "toolbox": {
        "color": "#ADD8E690",
        "label": "Toolbox"
    },
}

function useCanvas() {
    const canvasRef = React.useRef(null);

    const drawBoundingBoxes = (ctx, boundingBoxes, canvasWidth, canvasHeight) => {
        const scaleX = canvasWidth / 1280; // Calculate scaling factor for width
        const scaleY = canvasHeight / 720; // Calculate scaling factor for height
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);

        // window.requestAnimationFrame(() => {
        //     ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        // });
        boundingBoxes.forEach((box) => {
            const {
                kind,
                bounding_box: [x1, y1, w, h],
            } = box;

            const x2 = w - x1;
            const y2 = h - y1;

            const adjustedX1 = x1 * scaleX;
            const adjustedY1 = y1 * scaleY;
            const adjustedX2 = x2 * scaleX;
            const adjustedY2 = y2 * scaleY;

            // Adjusted bounding box coordinates
            const adjustedBoundingBox = [adjustedX1, adjustedY1, adjustedX2, adjustedY2];

            // Draw the bounding box
            ctx.strokeStyle = objectColors[kind].color;
            if (kind === "toolbox") {
                ctx.fillStyle = objectColors[kind].color;
                ctx.fillRect(...adjustedBoundingBox);
            }

            let label = objectColors[kind].label;

            if (kind === "person") {
                label = box.person;
            } else if (kind === "machine") {
                label = box.machine;
            } else if (kind === "toolbox") {
                label = "Toolbox"
            }


            ctx.lineWidth = 2; // Set the line width
            ctx.beginPath();
            ctx.rect(...adjustedBoundingBox);
            ctx.stroke();

            // Optionally, label the bounding box
            ctx.font = '20px Arial';
            // text background color
            ctx.fillStyle = objectColors[kind].color;
            ctx.fillRect(adjustedX1, adjustedY1 - 25, ctx.measureText(label).width + 10, 25);
            if (kind === "machine") {
                ctx.fillStyle = "#000000";
            }
            else {
                ctx.fillStyle = "#ffffff";
            }
            ctx.fillText(label, adjustedX1, adjustedY1 - 5);
        });
    }

    return {
        canvasRef,
        drawBoundingBoxes
    };
}

export default useCanvas;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Appbar from "../shared/Appbar";
import Navbar from "../shared/Navbar";
import { Grid } from "@mui/material";
import ImprovementGraph from "./improvementGraph";
import InformationArea from "./infromationArea";
import OperatorGraph from "./operatorGraph";
import Player from "./player";

import {
  setOperatorEfficency,
  setImprovementTime,
} from "../../slices/authInfo";

function InferencePage() {
  // inference state, 0: no inference, 1: busy, 2 webcam, 3 upload video
  const [inferenceState, setInferenceState] = useState(0);
  const [socket, setSocket] = useState(null);
  const [video, setVideo] = useState(null);
  const [liveWebcam, setLiveWebcam] = useState(null);

  const dispatch = useDispatch();

  const onSelectLive = (camera) => {
    setInferenceState(2);
  };

  const onUploadComplete = (file, videoId) => {
    setInferenceState(3);
    setVideo({
      file,
      videoId,
    });
  };

  //updating states for operator efficency grtaph
  useEffect(() => {
    dispatch(
      setOperatorEfficency({
        left: {
          Idle: 0,
          function: 0,
          wait: 0,
        },
        right: {
          Idle: 0,
          function: 0,
          wait: 0,
        },
      })
    );

    dispatch(setImprovementTime({}));
  }, []);

  return (
    <div style={{ height: "100vh", minWidth: "1024px", minHeight: "640px" }}>
      <div style={{ height: "10%" }}>
        <Appbar />
        <Navbar />
      </div>

      {/* page container start */}
      <Grid
        container
        style={{
          height: "90%",
          backgroundColor: "#ededed",
        }}
      >
        {/* Left bar start  */}
        <Grid item xs={7}>
          {/*  */}
          <InformationArea
            inferenceState={inferenceState}
            onSelectLive={onSelectLive}
            onUploadComplete={onUploadComplete}
            setInferenceState={setInferenceState}
          />
          <Player
            inferenceState={inferenceState}
            video={video}
            setInferenceState={setInferenceState}
          />
        </Grid>
        {/* Left bar end  */}

        {/* Right graphs start */}
        <Grid item xs={5}>
          <Grid style={{ height: "50%", padding: "1rem" }}>
            {<ImprovementGraph />}
          </Grid>
          <Grid style={{ height: "50%", padding: "1rem" }}>
            <OperatorGraph />
          </Grid>
        </Grid>
        {/* Right graphs start */}

        {/* Right bar start  */}
      </Grid>
      {/* Page Container End */}
    </div>
  );
}

export default InferencePage;

import React, { useRef, useEffect, useState } from "react";

import { SyncLoader } from "react-spinners";
import ReactPlayer from "react-player";
import { useDispatch } from "react-redux";
import useCanvas from "./../hooks/useCanvas";
import { Button, Grid } from "@mui/material";
import {
  setOperatorEfficency,
  setImprovementTime,
} from "../../slices/authInfo";
import { getVideoData } from "./../../services/inferenceService";

function VideoPlayer({
  playerContainerRef,
  video,
  frameData,
  setFrameData,
  inferenceState,
  setInferenceState,
  reference,
  setEndState,
}) {
  const videoRef = useRef(null);
  const videContainerRef = useRef(null);
  const dispatch = useDispatch();

  const [videoURL, setVideoURL] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
  const [maxChunk, setMaxChunk] = useState(0);
  const [videoLength, setVideoLength] = useState(null);
  const timePerChunk = 30;
  const { canvasRef, resetCanvas, drawBoundingBoxes } = useCanvas();

  useEffect(() => {
    const handleResize = () => {
      const width = playerContainerRef.current.offsetWidth;
      const height = playerContainerRef.current.offsetHeight;
      videoRef.current.width = width;
      videoRef.current.height = height;
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const clear = () => {
    setCurrentChunkIndex();
    setMaxChunk();
    setIsPlaying();
    setVideoURL();
    dispatch(setOperatorEfficency({}));
    dispatch(setImprovementTime({}));
  };
  useEffect(() => {
    setCurrentChunkIndex();
    setMaxChunk();
    setIsPlaying();
    setVideoURL();
    dispatch(setOperatorEfficency({}));
    dispatch(setImprovementTime({}));
  }, []);

  useEffect(() => {
    // create object url for the video
    const createObjectURL = (file) => {
      if (window.webkitURL) {
        return window.webkitURL.createObjectURL(file);
      } else if (window.URL && window.URL.createObjectURL) {
        return window.URL.createObjectURL(file);
      } else {
        return null;
      }
    };

    if (video) {
      const url = createObjectURL(video.file);
      videoRef.current.url = url;
      setVideoURL(url);

      // Set the video length in the state if available
      if (video.file.duration) {
        setVideoLength(video.file.duration);
      }
    }
  }, [video]);

  useEffect(() => {
    const interval = setInterval(() => {
      // get current time
      const currentTime = videoRef.current.getCurrentTime();

      // get current time in .1 second
      const currentTimeInTenth = parseFloat(currentTime).toFixed(1);

      // console.log(currentTimeInTenth * 1, videoLength * 1);
      if (videoLength && currentTimeInTenth * 1 >= videoLength * 1) {
        setEndState(3);
        setIsPlaying(false);
        clearInterval(interval);
      }

      const chunk = Math.floor(currentTimeInTenth / timePerChunk);
      setCurrentChunkIndex(chunk);
      const frame = frameData[String(currentTimeInTenth)] || {};

      // draw bounding boxes
      const boundingBoxes = frame?.bounding_boxes || [];

      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      if (boundingBoxes.length > 0) {
        drawBoundingBoxes(ctx, boundingBoxes, canvas.width, canvas.height);
      }

      // update graph
      const side_efficiency = frame?.side_efficiency;
      if (side_efficiency) {
        dispatch(setOperatorEfficency(side_efficiency));
      }

      const machines = frame?.machines;
      if (machines) {
        dispatch(setImprovementTime(machines));
      }

      const updatedObject = { ...frameData };
      delete updatedObject[String(currentTimeInTenth)];
      setFrameData(updatedObject);
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [videoRef, frameData]);
  const [retry, setRetry] = useState(0);
  const maxRetries = 12;

  //general function to get chunk data and retries if data is not fetched
  const getChunkData = (idx, videoID, retryCount = 0) => {
    getVideoData(idx, videoID).then((data) => {
      if (data?.response?.status === 404) {
        if (retryCount < maxRetries) {
          // Retrying to fetch the data again if the data is not available
          setRetry((prev) => prev + 1);
          setTimeout(() => {
            getChunkData(idx, videoID, retryCount + 1);
          }, 2000);
        } else {
          setRetry(0);
          setIsPlaying(false);
          setEndState(1);
          console.log("Exceeded maximum retries. Data not available.");
        }
      } else {
        setRetry(0);
        if (idx === 0) {
          setIsPlaying(true);
          setTimeout(() => {
            getChunkData(1, videoID);
          }, 5000);
        }

        setMaxChunk(data.data.maxchunk);
        setFrameData((prev) => {
          return { ...prev, ...data.data.data };
        });
      }
    });
  };

  // Calling the first chunk data initially
  useEffect(() => {
    getChunkData(0, video.videoId);
  }, []);
  // calling next chunks
  useEffect(() => {
    if (currentChunkIndex >= 1 && currentChunkIndex < maxChunk) {
      getChunkData(currentChunkIndex + 1, video.videoId);
    } else {
    }
  }, [currentChunkIndex]);
  return (
    <div>
      <div ref={videContainerRef}>
        <button
          ref={reference}
          style={{ display: "none" }}
          onClick={() => clear()}
        ></button>
        <canvas
          style={{
            position: "absolute",
            top: 0,
            left: 10,
            zIndex: 2,
            width: "97%",
            height: "100%",
          }}
          width={"1280"}
          height={"720"}
          ref={canvasRef}
        ></canvas>
        <ReactPlayer
          ref={videoRef}
          onReady={() => {
            const time = videoRef.current.getDuration();
            setVideoLength(parseFloat(time).toFixed(1));
          }}
          url={videoURL}
          playing={isPlaying}
          width={"97%"}
          height={"100%"}
          style={{
            position: "relative",
            top: 0,
            left: 10,
            zIndex: 1,
          }}
        />
      </div>
    </div>
  );
}
export default VideoPlayer;

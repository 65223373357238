import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const mainUrl = process.env.REACT_APP_MAIN_URL;
console.log(process.env.REACT_APP_ENVIRONMENT);
const errorSet = (err, thunkAPI) => {
  err === "401" && thunkAPI.dispatch(validAuthReducer(true));
  err === "413" && thunkAPI.dispatch(validAuthReducer(true));
};

export const loginApi = createAsyncThunk(
  "login/post",
  async (userInfo, thunkAPI) => {
    try {
      const res = await axios.post(`${mainUrl}/auth/login`, userInfo);
      return res.data;
    } catch (err) {
      sessionStorage.setItem("errorMessage", err.response.status);
      errorSet(err.response.status, thunkAPI);
      return err.response;
    }
  }
);

export const logoutApi = createAsyncThunk(
  "logout/post",
  async (userInfo, thunkAPI) => {
    const { authInfo } = thunkAPI.getState();
    try {
      const res = await axios.post(
        `${mainUrl}/logout?email=${authInfo.email}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authInfo.loginToken.token}`,
          },
        }
      );
      return res.data.token;
    } catch (err) {
      return err.response;
    }
  }
);

export const availabledatesinference = createAsyncThunk(
  "videoList",
  async (userInfo, thunkAPI) => {
    const { authInfo } = thunkAPI.getState();
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${authInfo.loginToken.token}`,
        },
      };
      const res = await axios.get(
        ` ${mainUrl}/getdates?email=${authInfo.email}`,
        config
      );

      return res;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
);

export const availableVideo = createAsyncThunk(
  "videoList",
  async (arg, thunkAPI) => {
    const { authInfo } = thunkAPI.getState();
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${authInfo.loginToken.token}`,
        },
      };
      const res = await axios.get(
        ` ${mainUrl}/videolist?email=${authInfo.email}&date=${arg.date}`,
        config
      );
      return res;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
);

export const getConfiguration = createAsyncThunk(
  "configurationList",
  async (arg, thunkAPI) => {
    const { authInfo } = thunkAPI.getState();
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${authInfo.loginToken.token}`,
        },
      };
      const res = await axios.get(
        `${mainUrl}/schedule_list?email=${authInfo.email}`,
        config
      );
      return res;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
);

export const updateConfiguration = createAsyncThunk(
  "updateConfiguration",
  async (userInfo, thunkAPI) => {
    const { authInfo } = thunkAPI.getState();
    try {
      const res = await axios.post(
        `${mainUrl}/schedule?email=${authInfo.email}`,
        userInfo,
        {
          headers: {
            Authorization: `Bearer ${authInfo.loginToken.token}`,
          },
        }
      );
      return res.data;
    } catch (err) {
      return err.response;
    }
  }
);

export const deleteConfiguration = createAsyncThunk(
  "deleteConfiguration",
  async (arg, thunkAPI) => {
    const { authInfo } = thunkAPI.getState();
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${authInfo.loginToken.token}`,
        },
      };
      const res = await axios.delete(
        `${mainUrl}/schedule?email=${authInfo.email}`,
        config
      );
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const selectedVideo = createAsyncThunk(
  "selectedVideo",
  async (arg, thunkAPI) => {
    const { authInfo } = thunkAPI.getState();
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${authInfo.loginToken.token}`,
        },
      };
      const res = await axios.get(
        ` ${mainUrl}/start-streaming?code=sometoken132&videoFile=${arg.video}&email=${authInfo.email}`,
        config
      );
      return res;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
);

export const stopVideo = createAsyncThunk(
  "stopvideo",
  async (arg, thunkAPI) => {
    const { authInfo } = thunkAPI.getState();
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${authInfo.loginToken.token}`,
        },
      };
      const res = await axios.get(
        `${mainUrl}/stop-streaming?email=${authInfo.email}`,
        config
      );

      return res;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
);

export const Live = createAsyncThunk("get/dates", async (arg, thunkAPI) => {
  const { authInfo } = thunkAPI.getState();

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${authInfo.loginToken.token}`,
      },
    };
    const res = await axios.get(
      `${mainUrl}/live-cam?email=${authInfo.email}`,
      config
    );
    return res.data;
  } catch (err) {
    return err;
  }
});

export const stopLive = createAsyncThunk("get/dates", async (arg, thunkAPI) => {
  const { authInfo } = thunkAPI.getState();

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${authInfo.loginToken.token}`,
      },
    };
    const res = await axios.get(
      `${mainUrl}/live-stop?email=${authInfo.email}`,
      config
    );
    return res.data;
  } catch (err) {
    return err;
  }
});

export const availabledate = createAsyncThunk(
  "get/dates",
  async (arg, thunkAPI) => {
    const { authInfo } = thunkAPI.getState();
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${authInfo.loginToken.token}`,
        },
      };
      const res = await axios.get(
        `${mainUrl}/availabledate?email=${authInfo.email}`,
        config
      );
      return res.data;
    } catch (err) {
      return err;
    }
  }
);

export const getGraphData = createAsyncThunk(
  "get/graphData",
  async (arg, thunkAPI) => {
    const { authInfo } = thunkAPI.getState();

    try {
      let config = {
        headers: {
          Authorization: `Bearer ${authInfo.loginToken.token}`,
        },
      };
      const res = await axios.get(
        `${mainUrl}/getgraphdata?email=${authInfo.email}&date=${arg.date}&type=${arg.type}`,
        config
      );
      return res.data;
    } catch (err) {
      return err;
    }
  }
);

export const getHistoryGraphData = createAsyncThunk(
  "get/graphData",
  async (arg, thunkAPI) => {
    const { authInfo } = thunkAPI.getState();

    try {
      let config = {
        headers: {
          Authorization: `Bearer ${authInfo.loginToken.token}`,
        },
      };
      const res = await axios.get(
        `${mainUrl}/getgraphdata?email=${authInfo.email}&start_date=${arg.start}&end_date=${arg.end}&type=${arg.type}`,
        config
      );
      return res.data;
    } catch (err) {
      return err;
    }
  }
);

export const getChunkData = createAsyncThunk(
  "get/graphData",
  async (arg, thunkAPI) => {
    const { authInfo } = thunkAPI.getState();

    try {
      let config = {
        headers: {
          Authorization: `Bearer ${authInfo.loginToken.token}`,
        },
      };
      const res = await axios.get(
        `${mainUrl}/getChunkData?idx=${arg.idx}&fileName=${arg.fileName}`,
        config
      );
      return res.data;
    } catch (err) {
      return err;
    }
  }
);

export const DailyReports = createAsyncThunk(
  "get/graphData",
  async (arg, thunkAPI) => {
    const { authInfo } = thunkAPI.getState();

    try {
      let config = {
        headers: {
          Authorization: `Bearer ${authInfo.loginToken.token}`,
        },
        responseType: "blob",
      };
      const res = await axios.get(
        `${mainUrl}/downloadDailyReportCSV?date=${arg.selectedDate}&email=${arg.email}`,
        config
      );
      return res.data;
    } catch (err) {
      return err;
    }
  }
);

export const downloadHistoricalCSV = createAsyncThunk(
  "get/graphData",
  async (arg, thunkAPI) => {
    const { authInfo } = thunkAPI.getState();

    try {
      let config = {
        headers: {
          Authorization: `Bearer ${authInfo.loginToken.token}`,
        },
        responseType: "blob",
      };
      const res = await axios.get(
        `${mainUrl}/getHistoricalCSV?start_date=${arg.startDate}&end_date=${arg.endDate}&email=${arg.email}`,
        config
      );
      return res.data;
    } catch (err) {
      return err;
    }
  }
);

export const getProductLifeCycleData = createAsyncThunk(
  "get/graphData",
  async (arg, thunkAPI) => {
    const { authInfo } = thunkAPI.getState();

    try {
      let config = {
        headers: {
          Authorization: `Bearer ${authInfo.loginToken.token}`,
        },
      };
      const res = await axios.get(
        `${mainUrl}/getproductLifecycle?date=${arg.date}&email=${arg.email}`,
        config
      );
      return res.data;
    } catch (err) {
      return err;
    }
  }
);

export const authInfoSlice = createSlice({
  name: "authInfo",
  initialState: {
    email: "",
    loginToken: "",
    hasStated: false,
    roomid: "",
    showLoader: false,
    initialDate: undefined,
    startDate: undefined,
    endDate: undefined,
    daily: true,
    historical: 1,
    secondUser: false,
    inference: false,
    operatorEfficency: {
      left: {
        Idle: 0,
        function: 0,
        wait: 0,
      },
      right: {
        Idle: 0,
        function: 0,
        wait: 0,
      },
    },
    improvementTime: [],
    productLifeCycle: [],
  },
  reducers: {
    setEmails: (state, action) => {
      state.email = action.payload;
    },
    setHasStarted: (state, action) => {
      state.hasStated = action.payload;
    },
    setInference: (state, action) => {
      state.inference = action.payload;
    },
    setroomid: (state, action) => {
      state.roomid = action.payload;
    },
    setToken: (state, action) => {
      state.loginToken = action.payload;
    },
    setLoading: (state, action) => {
      state.showLoader = action.payload;
    },
    setInitialDate: (state, action) => {
      state.initialDate = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setDaily: (state, action) => {
      state.daily = action.payload;
    },
    setHistorical: (state, action) => {
      state.historical = action.payload;
    },
    setSecondUser: (state, action) => {
      state.secondUser = action.payload;
    },
    setOperatorEfficency: (state, action) => {
      state.operatorEfficency = action.payload;
    },
    setImprovementTime: (state, action) => {
      state.improvementTime = action.payload;
    },
    setProductLifeCycle: (state, action) => {
      state.productLifeCycle = action.payload;
    },
  },
  extraReducers: {
    // [loginApi.pending]: (state, action) => {
    //   state.showLoader = true;
    //   state.failure = false;
    //   state.success = false;
    //   state.pending = true;
    // },
    // [loginApi.fulfilled]: (state, action) => {
    //   state.authInfo = action.payload;
    //   state.showLoader = false;
    //   state.invalidAuth = false;
    //   state.failure = false;
    //   state.success = false;
    //   state.pending = false;
    // },
    // [loginApi.rejected]: (state, action) => {
    //   state.failure = true;
    //   state.showLoader = false;
    //   state.success = false;
    //   state.pending = false;
    // },
  },
});

export const {
  validAuthReducer,
  setEmails,
  setInference,
  setroomid,
  setToken,
  setLoading,
  setInitialDate,
  setStartDate,
  setEndDate,
  setHistorical,
  setDaily,
  setSecondUser,
  setHasStarted,
  setOperatorEfficency,
  setImprovementTime,
  setProductLifeCycle,
} = authInfoSlice.actions;
export default authInfoSlice.reducer;

import React, { useState, useEffect, useRef } from "react";
import { Typography, Grid, useMediaQuery, Modal, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setInference, stopVideo } from "../../slices/authInfo";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { DotLoader } from "react-spinners";
function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matchesLg = useMediaQuery("(min-width:1536px)");
  const [index, setIndex] = useState(0);
  const [dropdown, setdropdown] = useState(false);
  const inference = useSelector((state) => state.authInfo.inference);
  const [open, setOpen] = useState(false);
  const [selection, setselection] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    if (location.pathname === "/") {
      setIndex(0);
    }
    if (location.pathname === "/config") {
      setIndex(0);
    }
    if (location.pathname === "/inference") {
      setIndex(1);
    }
    if (location.pathname === "/daily") {
      setIndex(2);
    }
    if (location.pathname === "/history") {
      setIndex(2);
    }
    if (location.pathname === "/settings") {
      setIndex(3);
    }
  }, [location.pathname]);
  const secondButtonRef = useRef(null);
  const loading = useSelector((state) => state?.authInfo.showLoader);
  const env = process.env.REACT_APP_ENVIRONMENT;
  return (
    <Grid
      container
      style={{
        backgroundColor: "#FFFFFF",
        height: "40%",
        borderBottom: "1px solid black",
        paddingLeft: "1.25rem",
      }}
    >
      <Grid
        item
        xs={1.25}
        md={1}
        xl={0.8}
        style={{ display: "flex", alignItems: "end", justifyContent: "center" }}
      >
        <Typography
          style={{
            borderBottom: index === 0 ? " 0.25vh solid black" : "",
            fontWeight: index === 0 ? "bold" : "",
            fontSize: matchesLg ? "13px" : "10px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/config")}
        >
          Config{" "}
        </Typography>{" "}
      </Grid>
      {/* <Grid
        item
        xs={1.25}
        md={1}
        xl={0.8}
        style={{ display: "flex", alignItems: "end", justifyContent: "center" }}
      >
        <Typography
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
          }}
        >
          Inference{" "}
        </Typography>{" "}
      </Grid> */}
      <Grid
        item
        xs={1.25}
        md={1}
        xl={0.8}
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Typography
          style={{
            borderBottom: index === 2 ? "0.25vh solid black" : "",
            fontWeight: index === 2 ? "bold" : "",
            fontSize: matchesLg ? "13px" : "10px",
            cursor: "pointer",
            marginRight: "4px",
          }}
          ref={secondButtonRef}
          onClick={() => setdropdown(!dropdown)}
        >
          {" "}
          Reports{" "}
        </Typography>
        {!dropdown ? (
          <KeyboardArrowDownIcon
            style={{ cursor: "pointer" }}
            onClick={(event) => {
              event.stopPropagation();
              secondButtonRef.current.click();
            }}
          />
        ) : (
          <KeyboardArrowUpIcon
            style={{ cursor: "pointer" }}
            onClick={(event) => {
              event.stopPropagation();
              secondButtonRef.current.click();
            }}
          />
        )}
        {dropdown && (
          <Grid
            style={{
              position: "absolute",
              bottom: -70,
              backgroundColor: "white",
              padding: "0.7rem",
              width: matchesLg ? "10rem" : "7rem",
              boxShadow: "0px 3px 6px #00000029",
              zIndex: "992",
            }}
          >
            <Grid
              style={{
                padding: "5px",
                cursor: "pointer",
                fontSize: matchesLg ? "13px" : "10px",
              }}
              onClick={() => {
                if (inference) {
                  setselection("/daily");
                  handleOpen();
                } else {
                  navigate("/daily");
                }
              }}
            >
              {" "}
              Daily Report{" "}
            </Grid>
            <Grid
              style={{
                padding: "5px",
                cursor: "pointer",
                fontSize: matchesLg ? "13px" : "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                if (inference) {
                  setselection("/history");
                  handleOpen();
                } else {
                  navigate("/history");
                }
              }}
            >
              {" "}
              Historical Report{" "}
            </Grid>
          </Grid>
        )}
      </Grid>
      {dropdown && (
        <Grid
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: "5",
          }}
          onClick={() => setdropdown(false)}
        >
          {" "}
        </Grid>
      )}
      {/* <Grid
        item
        xs={1.25}
        md={1}
        xl={0.8}
        style={{ display: "flex", alignItems: "end", justifyContent: "center" }}
      >
        <Typography
          style={{
            borderBottom: index === 3 ? " 0.25vh solid black" : "",
            fontWeight: index === 3 ? "bold" : "",
            fontSize: matchesLg ? "13px" : "10px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/settings")}
        >
          Settings{" "}
        </Typography>{" "}
      </Grid> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 320,
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          <Typography
            id="modal-modal-description"
            style={{ textAlign: "center", padding: "10px" }}
          >
            stop Inference to proceed
          </Typography>

          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{
                color: "black",
                border: "1px solid #102633",
                width: "50%",
                borderRadius: 0,
              }}
              onClick={handleClose}
            >
              {" "}
              cancel{" "}
            </Button>
            <Button
              style={{
                color: "white",
                border: "1px solid #102633",
                width: "50%",
                borderRadius: 0,
                backgroundColor: "#102633",
              }}
              onClick={() => {
                dispatch(setInference(false));
                dispatch(stopVideo());
                navigate(selection);
              }}
            >
              {" "}
              proceed{" "}
            </Button>
          </Grid>
        </Grid>
      </Modal>

      <Modal
        open={loading}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <DotLoader color="#141414" />
        </Grid>
      </Modal>
    </Grid>
  );
}

export default Navbar;

import React, { useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import moment from "moment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useSelector, useDispatch } from "react-redux";
import {
  setLoading,
  getGraphData,
  setInitialDate,
  setDaily,
  availabledate,
  setSecondUser,
  DailyReports,
  getProductLifeCycleData,
  setImprovementTime,
  setProductLifeCycle,
} from "../../slices/authInfo";

function DateSelector({
  label,
  setSelectedDate,
  availabledate,
  selectedDate,
  minDate,
  maxDate,
  disabled,
  setData,
  setData2,
  setLifeCycleData,
  setavg,
  setEntry,
  call,
}) {
  const dispatch = useDispatch();
  const datePickerRef = useRef(null);
  const email = useSelector((state) => state?.authInfo.email);

  useEffect(() => {
    const input = datePickerRef.current && datePickerRef.current.input;
    if (input) {
      input.addEventListener("keydown", function (e) {
        e.preventDefault();
      });
    }
  }, []);

  const isDayBlocked = (date) => {
    return availabledate.some((disabledDate) => {
      return moment(date).format("DD-MM-YYYY") === disabledDate;
    });
  };

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  return (
    <div
      style={{
        position: "relative",
        width: "200px",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "-5px",
          left: "5px",
          backgroundColor: !disabled ? "#ffffff" : "#F8F8F8",
          zIndex: "5",
          padding: "1px 5px",
          fontSize: "9px",
        }}
      >
        {label}
      </div>
      <div
        onClick={handleIconClick}
        style={{
          position: "absolute",
          zIndex: "5",
          right: "0px",
          top: "2.5px",
          cursor: "pointer",
          height: "4vh",
          minHeight: "25px",
          maxHeight: "35px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <CalendarTodayIcon sx={{ color: !disabled ? "#000000" : "#c8c8c8" }} />
      </div>

      <DatePicker
        placeholderText="MM-DD-YYYY"
        ref={datePickerRef}
        dateFormat="MM/dd/yyyy"
        className="custom-datepicker"
        style={{ cursor: "pointer", width: "100%" }}
        selected={selectedDate && moment(selectedDate, "DD-MM-YYYY").toDate()}
        onChange={(date) => {
          call(moment(date).format("DD-MM-YYYY"));
          setSelectedDate(moment(date).format("DD-MM-YYYY"));
        }}
        minDate={moment(minDate, "DD-MM-YYYY").toDate()}
        maxDate={moment(maxDate, "DD-MM-YYYY").toDate()}
        disabled={disabled}
        filterDate={availabledate && isDayBlocked}
      />
    </div>
  );
}

export default DateSelector;

// Dependencies imports graph
import { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useMediaQuery, Button } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VisibilityIcon from "@mui/icons-material/Visibility";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Graph({ graphdata, type, setOperatorGraph, graphCount }) {
  const chartRef = useRef(null);
  const [chartKey, setChartKey] = useState(0);
  const matchesLg = useMediaQuery("(min-width:1300px)");
  const matchesXlg = useMediaQuery("(min-width:1600px)");
  const [index, setindex] = useState(0);

  useEffect(() => {
    setindex(0);
  }, [graphdata]);

  const generateChartData = (data) => {
    const improvementTime = [];
    const inactiveTime = [];
    const wasteTime = [];
    const improvementTimeUnit = [];
    const operators = [];

    data?.forEach((operator) => {
      improvementTime.push(operator.improvement_time / 60);
      inactiveTime.push(operator.inactive_time / 60);
      wasteTime.push(operator.waste_time / 60);
      improvementTimeUnit.push(operator.improvement_time_unit / 60);
      operators.push(`Cam${operator.operators}`);
    });

    return {
      labels: operators,
      datasets: [
        {
          label: "Improvement Time",
          data: improvementTime,
          backgroundColor: "#4AB6DF",
          borderColor: "#4AB6DF",
          borderWidth: 1,
          minBarLength: "2",
        },
        {
          label: "Wait Time",
          data: wasteTime,
          backgroundColor: "#7CFDA7",
          borderColor: "#0AA53E",
          borderWidth: 1,
          minBarLength: "2",
        },
        {
          label: "Idle Time",
          data: inactiveTime,
          backgroundColor: "#F26565",
          borderColor: "#033A31",
          borderWidth: 1,
          minBarLength: "2",
        },

        {
          label: "Improvement Time / Unit",
          data: improvementTimeUnit,
          backgroundColor: "#249482",
          borderColor: "#033A31",
          borderWidth: 1,
          minBarLength: "2",
        },
      ],
    };
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
      },
      y: {
        grid: {
          display: true,
        },

        ticks: {
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
        title: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        align: "end",
        labels: {
          padding: 20,
          boxWidth: 40,
          usePointStyle: true,
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
        itemMargin: 10,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (tooltipItem, data) {
            return tooltipItem.dataset.label;
          },
          afterLabel: function (tooltipItem, data) {
            return convertSecondsToTime(tooltipItem.raw * 60);
          },
        },
      },
    },
  };

  function findMinMax(objectsArray, propertyName) {
    if (!objectsArray || objectsArray.length === 0 || !propertyName) {
      return null; // Handle empty array or invalid input
    }

    // Initialize variables to store min and max values and corresponding objects
    let min = Infinity;
    let max = -Infinity;
    let minObject = null;
    let maxObject = null;

    // Iterate through the array to find min and max values
    for (const obj of objectsArray) {
      const propertyValue = parseFloat(obj[propertyName]);

      if (!isNaN(propertyValue)) {
        if (propertyValue < min) {
          min = propertyValue;
          minObject = obj;
        }

        if (propertyValue > max) {
          max = propertyValue;
          maxObject = obj;
        }
      }
    }

    // Return an object containing both min and max objects
    return {
      min: minObject,
      max: maxObject,
    };
  }
  const [imp, setimp] = useState();
  const [impPerUNit, setimpPerUNit] = useState();
  const [wait, setWait] = useState();
  const [idle, setidle] = useState();

  useEffect(() => {
    setimp(findMinMax(graphdata, "improvement_time"));
    setimpPerUNit(findMinMax(graphdata, "improvement_time_unit"));
    setWait(findMinMax(graphdata, "waste_time"));
    setidle(findMinMax(graphdata, "inactive_time"));
  }, [graphdata]);

  const graphCount3 = useMediaQuery("(min-width:1200px)");
  const graphCount2 = useMediaQuery("(min-width:1350px)");
  const graphCount1 = useMediaQuery("(min-width:1450px)");
  function fontSize() {
    if (graphCount == 3) {
      if (graphCount3) {
        return "9px";
      } else {
        return "7px";
      }
    }

    if (graphCount == 2) {
      if (graphCount2) {
        return "12px";
      } else {
        return "9px";
      }
    }
    if (graphCount == 1) {
      if (graphCount1) {
        return "13px";
      } else {
        return "9px";
      }
    }
  }
  function convertSecondsToTime(seconds) {
    if (isNaN(seconds) || seconds < 0) {
      return "Invalid input";
    }

    const hours = `${Math.floor(seconds / 3600)}`;
    const minutes = `${Math.floor((seconds % 3600) / 60)}`;
    const sec = `${Math.floor(seconds % 60)}`;

    let hours2;
    let minutes2;
    let seconds2;
    if (hours?.length == 1) {
      hours2 = `0${hours}`;
    } else {
      hours2 = `${hours}`;
    }

    if (minutes?.length == 1) {
      minutes2 = `0${minutes}`;
    } else {
      minutes2 = `${minutes}`;
    }

    if (sec?.length == 1) {
      seconds2 = `0${sec}`;
    } else {
      seconds2 = `${sec}`;
    }

    return `${hours2}:${minutes2}:${seconds2}`;
  }

  function padding() {
    if (graphCount == 1) return "0.5rem 1rem 1rem 1rem";
    if (graphCount == 3) return "0.5rem 1rem 0.5rem 0.5rem";
    if (graphCount == 2) return "0.5rem 1rem 1rem 1rem";
  }

  return (
    <Grid
      item
      xs={12}
      container
      style={{
        padding: padding(),
        backgroundColor: "#E3E3E3",
        height: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        container
        style={{
          width: "100%",
          height: "100%",
          paddingBottom: "0.25rem",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          border: "1px solid #9A9A9A",
          backgroundColor: "white",
        }}
      >
        <Grid
          container
          style={{
            height: "10%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={11}
            style={{
              width: "90%",
              textAlign: "center",
              fontWeight: "500",
              fontSize: "16px",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid>Operator Efficiency</Grid>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              height: "100%",
              fontWeight: "500",
              fontSize: "14px",
              textAlign: "right",
            }}
          >
            <Button
              style={{
                color: graphCount == 1 ? "#666" : "black",
                height: "100%",
                cursor: graphCount == 1 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (graphCount > 1) {
                  setOperatorGraph(false);
                }
              }}
            >
              <VisibilityIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid
          style={{
            fontSize: fontSize(),
            paddingLeft: "16px",
            fontWeight: "bold",
            height: graphCount == 1 ? "1%" : "5%",
          }}
        >
          {" "}
          Min/Max Time
        </Grid>
        {graphdata && (
          <Grid
            container
            style={{
              height: graphCount == 1 ? "6%" : "15%",
              display: "flex",
            }}
          >
            <Grid
              container
              style={{
                width: "100%",
                display: "flex",
                padding: "0rem 1vw",
                margin: "0.25rem 0rem",
              }}
            >
              {
                <Grid
                  style={{
                    width: graphCount == 3 ? "50%" : "25%",
                    display: "flex",
                    border: "1px solid black",
                  }}
                >
                  <Grid
                    style={{
                      width: "8%",
                      backgroundColor: "#4AB6DF",
                      borderRight: "1px solid black",
                    }}
                  ></Grid>
                  <Grid
                    style={{
                      width: "90%",
                      textAlign: "center",
                      alignItem: "center",
                      display: "flex",
                    }}
                  >
                    <Grid
                      style={{
                        width: "30%",
                        fontSize: fontSize(),

                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Improvement
                    </Grid>
                    <Grid
                      style={{
                        width: "50%",
                        fontSize: fontSize(),
                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        padding: "1px",
                      }}
                    >
                      <Grid
                        style={{
                          borderRight: "1px solid #000000",
                          borderLeft: "1px solid #000000",
                          width: "100%",
                        }}
                      >
                        {imp?.min?.operators}/{imp?.max?.operators}
                      </Grid>
                    </Grid>
                    <Grid
                      style={{
                        width: "45%",
                        fontSize: fontSize(),
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      {convertSecondsToTime(imp?.min.improvement_time)}/
                      {convertSecondsToTime(imp?.max.improvement_time)}
                    </Grid>
                  </Grid>
                </Grid>
              }
              {
                <Grid
                  item
                  style={{
                    display: "flex",
                    width: graphCount == 3 ? "50%" : "25%",
                    border: "1px solid black",
                  }}
                >
                  <Grid
                    style={{
                      width: "8%",
                      backgroundColor: "#7CFDA7",
                      borderRight: "1px solid black",
                    }}
                  ></Grid>
                  <Grid
                    style={{
                      width: "92%",
                      textAlign: "center",
                      alignItem: "center",
                      display: "flex",
                    }}
                  >
                    <Grid
                      style={{
                        width: "30%",
                        fontSize: fontSize(),

                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Wait
                    </Grid>
                    <Grid
                      style={{
                        width: "50%",
                        fontSize: fontSize(),
                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        padding: "1px",
                      }}
                    >
                      <Grid
                        style={{
                          borderRight: "1px solid #000000",
                          borderLeft: "1px solid #000000",
                          width: "100%",
                        }}
                      >
                        {wait?.min.operators}/{wait?.max.operators}
                      </Grid>
                    </Grid>
                    <Grid
                      style={{
                        width: "45%",
                        fontSize: fontSize(),
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      {convertSecondsToTime(wait?.min.waste_time)}/
                      {convertSecondsToTime(wait?.max.waste_time)}
                    </Grid>
                  </Grid>
                </Grid>
              }

              {
                <Grid
                  style={{
                    width: graphCount == 3 ? "50%" : "25%",
                    display: "flex",
                    border: "1px solid black",
                  }}
                >
                  <Grid
                    style={{
                      width: "8%",
                      backgroundColor: "#F26565",
                      borderRight: "1px solid black",
                    }}
                  ></Grid>
                  <Grid
                    style={{
                      width: "90%",
                      textAlign: "center",
                      alignItem: "center",
                      display: "flex",
                    }}
                  >
                    <Grid
                      style={{
                        width: "30%",
                        fontSize: fontSize(),

                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Idle
                    </Grid>
                    <Grid
                      style={{
                        width: "50%",
                        fontSize: fontSize(),
                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        padding: "1px",
                      }}
                    >
                      <Grid
                        style={{
                          borderRight: "1px solid #000000",
                          borderLeft: "1px solid #000000",
                          width: "100%",
                        }}
                      >
                        {idle?.min.operators}/{idle?.max.operators}
                      </Grid>
                    </Grid>
                    <Grid
                      style={{
                        width: "45%",
                        fontSize: fontSize(),
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {convertSecondsToTime(idle?.min.inactive_time)}/
                      {convertSecondsToTime(idle?.max.inactive_time)}
                    </Grid>
                  </Grid>
                </Grid>
              }

              <Grid
                item
                style={{
                  display: "flex",
                  width: graphCount == 3 ? "50%" : "25%",
                  border: "1px solid black",
                }}
              >
                <Grid
                  style={{
                    width: "8%",
                    backgroundColor: "#68D8C6",
                    borderRight: "1px solid black",
                  }}
                ></Grid>
                <Grid
                  style={{
                    width: "92%",
                    textAlign: "center",
                    alignItem: "center",
                    display: "flex",
                  }}
                >
                  <Grid
                    style={{
                      width: "30%",
                      fontSize: fontSize(),

                      fontWeight: "bold",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Imp /unit
                  </Grid>
                  <Grid
                    style={{
                      width: "50%",
                      fontSize: fontSize(),
                      fontWeight: "bold",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      padding: "1px",
                    }}
                  >
                    <Grid
                      style={{
                        borderRight: "1px solid #000000",
                        borderLeft: "1px solid #000000",
                        width: "100%",
                      }}
                    >
                      {impPerUNit?.min.operators}/{impPerUNit?.max.operators}
                    </Grid>
                  </Grid>
                  <Grid
                    style={{
                      width: "45%",
                      fontSize: fontSize(),
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    {convertSecondsToTime(
                      impPerUNit?.min.improvement_time_unit
                    )}
                    /
                    {convertSecondsToTime(
                      impPerUNit?.max.improvement_time_unit
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          item
          xs={12}
          style={{
            height: graphCount == 1 ? "75%" : "70%",

            position: "relative",
            backgroundColor: "white",
          }}
        >
          <Grid container style={{ width: "100%", height: "100%" }}>
            <Grid
              item
              xs={graphCount == 3 ? 0.5 : 0.25}
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "6vh",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Grid
                style={{
                  position: "absolute",
                  transform: "rotate(-90deg)",
                  bottom: "65%",
                  fontSize: "12px",
                  width: "12vh",
                  minWidth: "80px",
                  fontWeight: "400",
                }}
              >
                {"Time (min) →"}
              </Grid>
            </Grid>
            <Grid
              item
              xs={graphCount == 3 ? 11.25 : 11.5}
              style={{ width: "100%", height: "100%" }}
            >
              <Bar
                key={chartKey}
                ref={chartRef}
                options={options}
                data={generateChartData(
                  graphdata?.slice(0 + index * 30, (index + 1) * 30)
                )}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </Grid>
            <Grid
              item
              xs={0.25}
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "6vh",
                justifyContent: "center",
              }}
            ></Grid>
          </Grid>

          <Grid
            style={{
              position: "absolute",
              bottom: 15,
              width: "15%",
              left: "4vw",

              fontSize: "12px",
            }}
          >
            Operators →
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Graph;

// Predefined mapping of camera IDs to mask values
const camIdNameMap = {
    "13": "199",
    "14": "1100",
    "15": "1101",
    "16": "1102",
    "17": "1103",
    "18": "1104",
    "19": "1105",
    "20": "1106",
};

function findKeyByValue(obj, valueToFind) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === valueToFind) {
            return key;
        }
    }
    return null;
}

function parseFilename(filePath) {
    const filename = filePath.split('/').pop(); // Extract the filename
    // Define the regex pattern
    const pattern = /(\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]))(_cam_(\d+))?(_video\[(\d+)\])?\s*\.mp4/;
    // Attempt to match the regex pattern to the filename
    const match = filename.match(pattern);

    if (match) {
        const [, date, , , , cameraId, , mask] = match;
        if (!cameraId && !mask) {
            // Return null if camera ID and mask don't match
            return null;
        }
        if (!cameraId) {
            // Find camera ID by mask using a predefined mapping
            cameraId = findKeyByValue(camIdNameMap, mask);
        }

        if (!mask) {
            // Find mask by camera ID using a predefined mapping
            mask = camIdNameMap[cameraId];
        }

        return {
            date,
            cameraId,
            mask,
            filename,
            filePath,
        };
    } else {
        return null;
    }
}

export { parseFilename } 
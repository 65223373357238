import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useEffect } from "react";
import Home from "./pages/inference/Index";
import Login from "./pages/login/Login";
import Daily from "./pages/Daily/index";
import Config from "./pages/Config/Config";
import History from "./pages/historical/index";
import InferencePage from "./pages/inferenceNew/index";
import Settings from "./pages/settings/index";
import ForgotPassword from "./pages/login/forgotPassword";
import { useSelector } from "react-redux";

function App() {
  const authInfo = useSelector((state) => state.authInfo.loginToken);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/inference"
          element={
            authInfo === "" ? <Navigate replace to={`/`} /> : <InferencePage />
          }
        />

        <Route
          path="/daily"
          element={authInfo === "" ? <Navigate replace to={`/`} /> : <Daily />}
        />
        <Route
          path="/history"
          element={
            authInfo === "" ? <Navigate replace to={`/`} /> : <History />
          }
        />
        <Route
          path="/config"
          element={authInfo === "" ? <Navigate replace to={"/"} /> : <Config />}
        />

        <Route
          path="/settings"
          element={
            authInfo === "" ? <Navigate replace to={"/"} /> : <Settings />
          }
        />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        {/* < Route path="*" element={authInfo === "" ? <Navigate replace to={`/`} /> : <Home />} /> */}

        {/* < Route path="/batch" element={authInfo === "" ? <Navigate replace to={`/`} /> : <Batch />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;

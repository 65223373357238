// Dependency imports
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import {
  Grid,
  Button,
  Tooltip,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { SyncLoader } from "react-spinners";
import Webcam from "react-webcam";
import ReactPlayer from "react-player";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Papa, { parse } from "papaparse";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import VideocamIcon from "@mui/icons-material/Videocam";
import Floor from "../../assets/FloorPlan.png";
import LinearProgress from "@mui/joy/LinearProgress";
import axios, { CancelToken, isCancel } from "axios";

// Local imports
import Appbar from "../shared/Appbar";
import Navbar from "../shared/Navbar";
import Dates from "./Dates";
import OperatorGraph from "./OperatorGraph";
import Improvement from "./Improvement";
import MiniGraphs from "./miniGraphs";

import Novideo from "../../assets/novideo.png";
import {
  selectedVideo,
  availableVideo,
  stopVideo,
  availabledatesinference,
  setSecondUser,
  setInference,
  stopLive,
  getChunkData,
  Live,
} from "../../slices/authInfo";

const mainUrl = process.env.REACT_APP_MAIN_URL;

function Home() {
  const [img, setImg] = useState();
  const [cam, setcam] = useState();
  const [work, setWork] = useState(0);
  const [idle, setIdle] = useState(0);
  const [wait, setWait] = useState(0);
  const [work2, setWork2] = useState(0);
  const [idle2, setIdle2] = useState(0);
  const [wait2, setWait2] = useState(0);
  const [obj, setobj] = useState({});
  const [isHovered, setIsHovered] = useState(false);
  const divRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [availabledates, setavailabledates] = useState();
  const [selectedDate, setselecteddates] = useState();
  const [videos, setvideos] = useState();
  const [videoEnded, setVideoEnded] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const event = useSelector((state) => state.authInfo.loginToken.client_event);
  const token = useSelector((state) => state.authInfo.loginToken.token);
  const email = useSelector((state) => state.authInfo.email);
  const [livevideo, setlivevideo] = useState(false);
  const [uploadVideo, setUploadVideo] = useState(false);
  const [lastFrame, setLastFrame] = useState(false);
  const roomid = useSelector((state) => state.authInfo.roomid);
  const inference = useSelector((state) => state.authInfo.inference);
  const [total, settotal] = useState(0);
  const [socket, setSocket] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [data, setdata] = useState([]);
  const frameCountRef = useRef(0);
  const [boxCoordinates, setBoxCoordinates] = useState([]);
  const [machinesBusy, setMachinesBusy] = useState(false);
  const [liveTrigger, setLiveTrigger] = useState(false);
  const [emitEvent, setEmitEvent] = useState("");
  const dispatch = useDispatch();
  const livewebcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [videoURL, setVideoURL] = useState(null);
  const [videoCamName, setVideoCamName] = useState();
  const [currentTime, setCurrentTime] = useState(0);
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [cameraModal, setCameraModal] = useState(false);
  const [maskModal, setMaskModal] = useState(false);
  const [liveCam, setLiveCam] = useState(false);
  const matchesLg = useMediaQuery("(min-width:1536px)");
  const inputFileRef = useRef(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [file, setFile] = useState("");
  const cancelFileUpload = useRef(null);
  const [checkLive, setCheckLive] = useState(false);

  const [miniobj, setminiobj] = useState({
    Z200: 0,
    Z400: 0,
    Z200_small: 0,
  });

  const checka = (check) => {
    if (check === 0) {
      return 0;
    } else {
      return Number((check * 100) / total).toFixed(2);
    }
  };
  let uploadRequest;
  const handleFileChange = (e) => {
    localVideo(e);
    const selectedFile = inputFileRef.current.files[0];
    setFile(selectedFile);
    if (!selectedFile) {
      alert("Please select a file.");
      return;
    } else {
      if (stringBetweenSquare(selectedFile.name)) {
        uploadVideofunc(
          selectedFile,
          stringBetweenSquare(selectedFile.name),
          false
        );
      } else {
        setMaskModal(true);
      }
    }
  };
  const uploadVideofunc = async (videoFile, name, type) => {
    // connectSocket();
    const formData = new FormData();
    formData.append("testvideo", videoFile);
    setMachinesBusy();
    try {
      uploadRequest = axios.post(
        `${mainUrl}/Upload/video?email=${email}&videoFile=${
          type ? videoFile : videoFile.name
        }&cam_type=${name}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
          cancelToken: new CancelToken(
            (cancel) => (cancelFileUpload.current = cancel)
          ),
        }
      );

      await uploadRequest.then((res) => {
        recursive(0, videoFile.name);

        dispatch(setInference(true));
        setUploadProgress(0);
      });
    } catch (error) {
      console.log(error);
      if (isCancel(error)) {
        // Handle request cancellation here
        console.log("Request canceled:", error.message);
      } else {
        setUploadProgress(0);
        setMachinesBusy(error.response?.data?.message);

        if (isCancel(error)) {
          alert(error.message);
        }
      }
    }
  };
  const [chunkData, setChunkData] = useState({});

  function recursive(index, name) {
    dispatch(
      getChunkData({ idx: index, fileName: name.replace(/\.mp4$/, "") })
    ).then((res) => {
      if (res.payload) {
        index = index + 1;
        setChunkData((prev) => ({ ...prev, ...res }));
      } else {
      }
      setTimeout(() => {
        recursive(index, name);
      }, 1000);
    });
  }

  function stringBetweenSquare(inputString) {
    const regex = /\[(.*?)\]/;
    const match = regex.exec(inputString);

    if (match && match[1]) {
      return match[1];
    }

    return null;
  }
  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
    inputFileRef.current.value = "";
    setUploadProgress(0);
    socket?.disconnect();
  };
  const videoRef = useRef(null);
  const updateDimensions = () => {
    const paddingLeft = parseFloat(
      getComputedStyle(divRef.current).paddingLeft
    );
    const paddingRight = parseFloat(
      getComputedStyle(divRef.current).paddingRight
    );

    setWidth(divRef.current.offsetWidth - (paddingLeft + paddingRight));

    let height =
      (9 * (divRef.current.offsetWidth - (paddingLeft + paddingRight))) / 16;

    setHeight(height);
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const checktype = (type) => {
    if (type == 3) {
      return "Z200";
    }

    if (type == 4) {
      return "Z400";
    }

    if (type == 5) {
      return "Z200 Small";
    }
  };

  const connectSocket = () => {
    const newsocket = io(mainUrl, { auth: { token: token, username: email } });
    setSocket(newsocket);
  };

  useEffect(() => {
    return () => {
      if (socket) socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.emit("join-room", roomid);
      socket.on("frame-data", (base64Frame) => {
        console.log("base64Frame", base64Frame);
        // if (inference && !livevideo) {
        //   base64Frame.forEach((data) => {
        //     setdata((prevData) => [...prevData, data]);
        //   });
        // }
        // if (inference && livevideo) {
        //   base64Frame.forEach((data) => {
        //     update(data);
        //   });
        // }
      });
      socket.on("machine_status", (data) => {
        if (data == "unavailable") {
          setMachinesBusy(true);
        }
        {
          setMachinesBusy(false);
        }
      });
    }
  }, [inference, socket]);

  useEffect(() => {
    let intervalId;
    const loop = () => {
      intervalId = setInterval(() => {
        let time = Math.floor(videoRef.current?.getCurrentTime() * 10);
        setCurrentIndex(time);
      }, 100);
    };
    if (!isPaused) {
      loop();
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isPaused]);

  useEffect(() => {
    if (livevideo || uploadVideo) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const imageSize = { width: 1280, height: 720 };
      const canvasSize = { width: width, height: height };

      const objectMapping = {
        P: { label: "Person", color: "#FF0000" },
        M: { label: "Machine", color: "green" },
        t: { label: "Tool box", color: "#ADD8E690" },
      };

      boxCoordinates.forEach((box) => {
        if (box !== undefined) {
          const x1 = box["x"];
          const y1 = box["y"];
          const x2 = box["width"] - box["x"];
          const y2 = box["height"] - box["y"];

          const scaledX1 = (x1 / imageSize.width) * canvasSize.width;
          const scaledY1 = (y1 / imageSize.height) * canvasSize.height;
          const scaledX2 = (x2 / imageSize.width) * canvasSize.width;
          const scaledY2 = (y2 / imageSize.height) * canvasSize.height;
          const objectKey = box["key"];
          const objectInfo = objectMapping[objectKey[0]];

          if (objectInfo) {
            ctx.strokeStyle = objectInfo.color;
            ctx.lineWidth = 2;
            ctx.font = "12px Arial";
            let text;
            let textWidth;
            let textHeight;

            let bgX;
            let bgY;
            let bgWidth;
            let bgHeight;

            if (box["key"][0] === "t") {
              text = "";
              ctx.fillStyle = "#ADD8E690";
              ctx.fillRect(scaledX1, scaledY1, scaledX2, scaledY2);
            } else {
              text = box["key"];
              textWidth = ctx.measureText(text).width;
              textHeight = 12;
              bgX = scaledX1;
              bgY = scaledY1 - textHeight - 5;
              bgWidth = textWidth;
              bgHeight = textHeight;
              ctx.strokeRect(scaledX1, scaledY1, scaledX2, scaledY2);
            }

            ctx.fillStyle = "black";
            ctx.fillRect(bgX, bgY, bgWidth, bgHeight);
            ctx.fillStyle = "white";

            ctx.fillText(text, scaledX1, scaledY1 - 5);
          }
        }
      });
    }
  }, [boxCoordinates]);

  const captureAndSend = () => {
    if (livewebcamRef.current?.getScreenshot() && socket) {
      const imageSrc = livewebcamRef.current.getScreenshot({
        width: 1920,
        height: 1080,
      });

      socket.emit("frame", {
        base64: imageSrc,
        cam_type: liveCam,
        frame_count: frameCountRef.current,
      });
      frameCountRef.current++;
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
      clear();

      dispatch(setInference(false));
      dispatch(stopVideo()).then((res) => {});
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    let intervalId;
    if (liveTrigger) {
      intervalId = setInterval(captureAndSend, 500);
    }
    return () => clearInterval(intervalId);
  }, [socket, liveTrigger, emitEvent]);

  useEffect(() => {
    if (data.length - currentIndex < 2) {
      setIsPaused(true);
    }

    if (data.length - currentIndex > 2) {
      setUploadVideo(true);
      setIsPaused(false);
    }

    update(data[currentIndex]);
  }, [data, currentIndex]);

  const update = (base64Frame) => {
    if (base64Frame?.data === "streaming_stopped") {
      setUploadVideo(null);
      setVideoEnded(true);
    } else {
      if (inference && base64Frame != undefined) {
        const imageData = base64Frame?.frame;
        const blob = new Blob([imageData], { type: "image/png" });
        const imageUrl = URL.createObjectURL(blob);
        setImg({ url: imageUrl });
        let data = JSON?.parse(base64Frame?.data);

        const frameData = JSON.parse(base64Frame.data);

        const boundingBoxData = Object.values(frameData.result)[0];

        setBoxCoordinates(null);
        if (boundingBoxData) {
          const newBoxCoordinates = Object.entries(boundingBoxData)
            .map(([key, person]) => {
              if (person.bbox !== undefined) {
                const [x, y, width, height] = person.bbox;
                return { x, y, width, height, key };
              }
              return null;
            })
            .filter(Boolean);
          if (newBoxCoordinates !== "undefined") {
            setBoxCoordinates(newBoxCoordinates);
          }
        }

        let result = data.result;

        if (videoRef.current) {
          const currentTime = videoRef.current.getCurrentTime();
        }

        let frame = result[Object.keys(result)[0]];
        let keys = Object.keys(frame);
        let person = keys.filter((name) => name.startsWith("P"));
        let machine = keys.filter((name) => name.startsWith("M"));
        for (let i = 0; i <= person.length - 1; i++) {
          let currentPerson = frame[person[i]];
          let currentmachine = frame[machine[i]];
          if (currentPerson.side === "left") {
            if (currentPerson.Action === "function") {
              setWork((prevWork) => prevWork + 0.1);
            }
            if (currentPerson.Action === "wait") {
              setWait((prevWait) => prevWait + 0.1);
            }
            if (currentPerson.Action === "Idle") {
              setIdle((prevIdle) => prevIdle + 0.1);
            }
          }
          if (currentPerson.side === "right") {
            if (currentPerson.Action === "function") {
              setWork2((prevWork) => prevWork + 0.1);
            }
            if (currentPerson.Action === "wait") {
              setWait2((prevWait) => prevWait + 0.1);
            }
            if (currentPerson.Action === "Idle") {
              setIdle2((prevIdle) => prevIdle + 0.1);
            }
          }

          if (currentPerson.MID) {
            let CMID = currentPerson.MID;
            setobj((prevState) => {
              if (prevState[CMID]) {
                return {
                  ...prevState,

                  [CMID]: {
                    ...prevState[CMID],

                    time: prevState[CMID].time + 0.1,
                  },
                };
              } else {
                return {
                  ...prevState,

                  [CMID]: {
                    unit: CMID,

                    type: checktype(currentmachine?.class),

                    time: 0.1,
                  },
                };
              }
            });
          }
        }

        for (let i = 0; i < machine.length; i++) {
          let currentmachine = frame[machine[i]];
          let CMID = currentmachine.ID.toString().trim();
          if (currentmachine.class) {
          }
        }
      }
    }
  };

  const update2 = (data) => {
    setBoxCoordinates(null);

    setWork(data.side_efficiency.left.function);
    setIdle(data.side_efficiency.left.Idle);
    setWait(data.side_efficiency.left.wait);

    setWork(data.side_efficiency.right.function);
    setIdle(data.side_efficiency.right.Idle);
    setWait(data.side_efficiency.right.wait);

    const newData = data.bounding_box.map((item) => {
      const key =
        item.kind === "person" ? "P" : item.kind === "machine" ? "M" : "t"; // Map kind to key
      return {
        x: item.bounding_box[0], // X-coordinate of the top-left corner
        y: item.bounding_box[1], // Y-coordinate of the top-left corner
        width: item.bounding_box[2] - item.bounding_box[0], // Width of the box
        height: item.bounding_box[3] - item.bounding_box[1], // Height of the box
        key: key, // Object type key
      };
    });

    setBoxCoordinates(newData);
  };
  useEffect(() => {
    if (inference) {
      dispatch(setInference(false));
      dispatch(stopVideo()).then((res) => {
        clear();
      });
    }
  }, []);

  function triggerLive() {
    setCheckLive(true);
    if (cameras?.length > 1) {
      setCameraModal(true);
    } else {
      setMaskModal(true);
    }
  }

  function triggerLiveAfterCameraSelection() {
    setlivevideo(true);
    dispatch(setInference(true));
    dispatch(Live()).then((res) => {
      setEmitEvent(res.payload?.live_client_event);
      setLiveTrigger(true);
      setMachinesBusy(res.payload?.response?.data?.message);
    });
  }

  const clear = () => {
    setdata([]);
    setUploadVideo(false);
    setCurrentIndex(0);
    setWork(0);
    setIdle(0);
    setWait(0);
    setWork2(0);
    setIdle2(0);
    setWait2(0);
    setobj({});
    setImg();
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  function getObjectLength(obj) {
    if (!obj || typeof obj !== "object") {
      throw new Error("Invalid input. Expected an object.");
    }

    let count = 0;

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        count++;
      }
    }
    return count;
  }

  useEffect(() => {
    settotal(getObjectLength(obj));
  }, [obj]);

  function localVideo(event) {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setVideoURL(url);
      setVideoCamName(file.name);
    }
  }

  useEffect(() => {
    async function getCameras() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setCameras(videoDevices);
        setSelectedCamera(videoDevices[0]);
      } catch (error) {
        console.error("Error getting cameras:", error);
      }
    }
    getCameras();
  }, []);

  const handleCameraChange = (event) => {
    const selectedDeviceId = event.target.value;
    const selectedDevice = cameras.find(
      (device) => device.deviceId === selectedDeviceId
    );
    setSelectedCamera(selectedDevice);
    setCameraModal(false);
    setMaskModal(true);
  };

  function handelMaskChange(event) {
    setVideoCamName(event.target.value);
    setMaskModal(false);
    setLiveCam(event.target.value);
    if (checkLive) {
      triggerLiveAfterCameraSelection();
    } else {
      uploadVideofunc(file, event.target.value, false);
    }
  }

  function handleClose2() {
    setCameraModal(false);
  }
  function handleClose3() {
    setMaskModal(false);
  }

  useEffect(() => {
    if (cameras?.length == 1) {
      setSelectedCamera(cameras[0]);
    }
  }, []);

  const handleDownloadCSV = (data) => {
    const dataArray = [];

    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        dataArray.push(data[key]);
      }
    }

    const headers = Object.keys(dataArray[0]);
    const csv = Papa.unparse({
      fields: headers,
      data: dataArray,
    });

    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 10,
    width: "9vw",
    gap: "0.4vw",
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#102633",
    borderColor: "grey",
    "&:hover": {
      backgroundColor: "#102658",
      borderColor: "grey",
      boxShadow: "none",
      cursor: inference ? "not-allowed" : "pointer",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0062cc",
      borderColor: "grey",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  });

  const BootstrapDisabledButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 10,
    width: "9vw",
    gap: "0.4vw",
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "lightgrey",
    borderColor: "grey",
    color: "grey",
    "&:hover": {
      backgroundColor: "lightgrey",
      borderColor: "grey",
      boxShadow: "none",
      cursor: inference ? "not-allowed" : "pointer",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "lightgrey",
      borderColor: "grey",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  });
  let arr = Object.keys(obj);
  let graphdata = [];
  for (let x of arr) {
    graphdata.push(obj[x]);
  }
  useEffect(() => {
    setminiobj({
      Z200: 0,
      Z400: 0,
      Z200_small: 0,
    });
    for (let x of graphdata) {
      if (x.type == "Z400") {
        setminiobj((prev) => {
          return {
            ...prev,
            Z400: prev.Z400 + 1,
          };
        });
      }
      if (x.type == "Z200") {
        setminiobj((prev) => {
          return {
            ...prev,
            Z200: prev.Z200 + 1,
          };
        });
      }
      if (x.type == "Z200 Small") {
        setminiobj((prev) => {
          return {
            ...prev,
            Z200_small: prev.Z200_small + 1,
          };
        });
      }
    }
  }, [obj]);
  return (
    <div style={{ height: "100vh", minWidth: "1024px", minHeight: "640px" }}>
      <div style={{ height: "10%" }}>
        <Appbar />
        <Navbar />
      </div>

      <Grid
        container
        style={{
          height: "90%",
          backgroundColor: "#ededed",
        }}
      >
        <Grid item xs={7}>
          <Grid
            style={{
              height: " 22%",

              padding: "1.25rem 0.625rem 0.625rem 1.25rem",
            }}
          >
            <Grid
              container
              style={{
                height: " 100%",
                backgroundColor: "white",
                borderTop: "1px solid  #9A9A9A",
                borderBottom: "1px solid  #9A9A9A",
                borderLeft: "1px solid  #9A9A9A",
              }}
            >
              <Grid
                item
                xs={2.5}
                style={{
                  padding: "0vh 1vw",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                  borderRight: "1px solid #9A9A9A",
                  height: " 100%",
                }}
              >
                {inference ? (
                  <BootstrapDisabledButton variant="contained" disableElevation>
                    Upload Video
                    <FileUploadIcon color="white" />
                  </BootstrapDisabledButton>
                ) : (
                  <BootstrapButton
                    onClick={() => {
                      inputFileRef.current.value = "";
                      setdata([]);
                      setLastFrame(false);
                      setMachinesBusy();
                      inputFileRef.current.click();
                    }}
                    variant="contained"
                  >
                    Upload Video
                    <FileUploadIcon color="white" />
                  </BootstrapButton>
                )}

                {inference ? (
                  <BootstrapDisabledButton variant="contained" disableElevation>
                    Start Live
                    <VideocamIcon color="white" />
                  </BootstrapDisabledButton>
                ) : (
                  <BootstrapButton
                    onClick={() => {
                      connectSocket();
                      triggerLive();
                    }}
                    variant="contained"
                    disableElevation
                  >
                    Start Live
                    <VideocamIcon color="white" />
                  </BootstrapButton>
                )}
              </Grid>
              <Grid
                xs={3.5}
                style={{
                  padding: "1rem 0.5rem",
                  borderRight: "1px solid #9A9A9A",
                }}
              >
                <Typography style={{ fontSize: matchesLg ? "12px" : "8px" }}>
                  {" "}
                  Floor Plan showing camera positions{" "}
                </Typography>

                <img
                  src={Floor}
                  alt=""
                  style={{ width: "100%", height: "90%" }}
                />
              </Grid>
              <Grid xs={5} style={{ padding: "1vh 1vw", height: "100%" }}>
                <Grid style={{ height: " 20%" }}>Total production</Grid>

                <Grid
                  style={{
                    height: "80%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <>
                    <MiniGraphs
                      min={miniobj.Z400}
                      max={90}
                      color1={"#F57805"}
                      color2={"#FBC99B"}
                      value={checka(miniobj.Z400)}
                      type={"Z400"}
                    />
                    <MiniGraphs
                      min={miniobj.Z200}
                      max={45}
                      color1={"#FF6384"}
                      color2={"#FFB5C5"}
                      value={checka(miniobj.Z200)}
                      type={"Z200"}
                    />
                    <MiniGraphs
                      min={miniobj.Z200_small}
                      max={25}
                      color1={"#4EC1C1"}
                      color2={"#A4DFDF"}
                      value={checka(miniobj.Z200_small)}
                      type={"Z200 SMALL"}
                    />
                  </>
                </Grid>
              </Grid>
              <Grid
                xs={1}
                style={{
                  backgroundColor: "#102633",
                  color: "white",
                  height: "100%",
                  width: "100%",
                  fontSize: "4rem",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  borderRight: "5px solid  #102633",
                }}
              >
                {total}
              </Grid>

              <input
                type="file"
                accept="video/*"
                style={{ display: "none" }}
                ref={inputFileRef}
                onChange={handleFileChange}
              />
              {/* modal for upload progress */}
              <Modal
                open={uploadProgress > 0.1}
                // open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Grid
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 420,
                    bgcolor: "background.paper",
                    padding: "1rem",
                    boxShadow: 24,
                  }}
                >
                  <Grid style={{ padding: "0.75rem" }}>
                    <Grid
                      container
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid
                        item
                        xs={1}
                        style={{ fontSize: matchesLg ? "12px" : "8px" }}
                      >
                        {" "}
                      </Grid>

                      <Grid item xs={8}>
                        <LinearProgress
                          determinate
                          variant="outlined"
                          value={uploadProgress}
                          sx={{
                            "--LinearProgress-radius": "0px",
                            "--LinearProgress-progressThickness": "1.8vh",
                            borderColor: `${`#4EC1C1`}`,
                            color: `${`#A4DFDF`}`,
                            height: "2vh",
                          }}
                        ></LinearProgress>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{ fontSize: matchesLg ? "12px" : "8px" }}
                      ></Grid>
                      <Grid
                        item
                        xs={1}
                        style={{ fontSize: matchesLg ? "12px" : "8px" }}
                      >
                        {" "}
                        {`${uploadProgress}%`}
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "25px" }}>
                        video file : {file.name}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginTop: "25px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          onClick={() => cancelUpload()}
                          style={{
                            width: "100%",
                            backgroundColor: "black",
                            color: "white",
                          }}
                        >
                          cancel upload{" "}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid style={{ padding: "0.75rem" }}>
                    Inference starts automatically once upload is completed
                  </Grid>
                </Grid>
              </Modal>
            </Grid>
          </Grid>

          <Grid
            ref={divRef}
            style={{
              height: " 78%",
              padding: "0.625rem 0.625rem 1.25rem 1.25rem",
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute" }}>
              {livevideo && !machinesBusy && (
                <div>
                  <canvas
                    ref={canvasRef}
                    width={width}
                    height={height}
                    style={{
                      border: "none",
                      position: "absolute",
                      zIndex: "100",
                    }}
                  />
                  <Webcam
                    audio={false}
                    mirrored={true}
                    ref={livewebcamRef}
                    width={width}
                    height={height}
                    forceScreenshotSourceSize="true"
                    videoConstraints={{ deviceId: selectedCamera?.deviceId }}
                  />
                </div>
              )}
              {uploadVideo && !machinesBusy && (
                <div>
                  <canvas
                    ref={canvasRef}
                    width={width}
                    height={height}
                    style={{
                      border: "none",
                      position: "absolute",
                      zIndex: "100",
                    }}
                  />
                  <ReactPlayer
                    ref={videoRef}
                    url={videoURL}
                    playing={!isPaused}
                    width={width}
                    height={height}
                  />
                </div>
              )}
            </div>
            <Grid
              style={{
                backgroundColor: "transparent",
                width: "100%",
                height: "100%",
                border: "1px solid #9A9A9A",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                zIndex: "999",
              }}
            >
              <Grid
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  height: "5rem",
                  display: "flex",
                  justifyContent: "center",
                  zIndex: "999",
                }}
              >
                {inference && isHovered && (
                  <Button
                    disabled={!inference}
                    style={{
                      border: "1px solid black",

                      color: "white",

                      backgroundColor: "black",

                      height: "70%",
                    }}
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    stop inference{" "}
                  </Button>
                )}
              </Grid>

              {inference && (
                <Grid
                  style={{
                    top: 0,
                    position: "absolute",
                    width: "100%",
                    height: "10%",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "rgba(255,255,255,0.75)",
                    }}
                  >
                    {videoCamName}
                  </span>
                </Grid>
              )}

              {machinesBusy && (
                <Grid
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    paddingTop: "2rem",
                    paddingBottom: "2rem",
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "white",
                  }}
                >
                  {` ${machinesBusy}, Please  try again later`}
                </Grid>
              )}

              {inference && !img && !livevideo && (
                <Grid
                  style={{
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {!livevideo && <SyncLoader color="#36d7b7" />}

                  {!machinesBusy && livevideo && data.length !== 0 && (
                    <SyncLoader color="#36d7b7" />
                  )}
                </Grid>
              )}

              {!inference && !machinesBusy && !uploadVideo && (
                <Grid style={{ width: "100%", zIndex: "999" }}>
                  <Grid style={{ textAlign: "center" }}>
                    <img
                      src={Novideo}
                      alt="img"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </Grid>

                  <Grid style={{ width: "100%", textAlign: "center" }}>
                    please upload a video or select a camera to start inference
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={5}>
          <Grid
            style={{
              height: "50%",

              padding: "1.25rem 1.25rem 0.625rem 0.625rem",
            }}
          >
            {" "}
            <Improvement obj={obj} />{" "}
          </Grid>

          <Grid
            style={{
              height: "50%",

              padding: "0.625rem 1.25rem 1.25rem 0.625rem",
            }}
          >
            {" "}
            <OperatorGraph
              work={work}
              wait={wait}
              idle={idle}
              work2={work2}
              wait2={wait2}
              idle2={idle2}
            />{" "}
          </Grid>
          {/* modal to ask weather to stop inferencing in the middle */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Grid
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 320,
                bgcolor: "background.paper",
                boxShadow: 24,
              }}
            >
              <Typography
                id="modal-modal-description"
                style={{ textAlign: "center", padding: "10px" }}
              >
                Do you want to stop Inferencing ?
              </Typography>

              <Grid
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  style={{
                    color: "black",
                    border: "1px solid #102633",
                    width: "33%",
                    borderRadius: 0,
                  }}
                  onClick={() => {
                    handleDownloadCSV({
                      left: { wait: wait, idle: idle, work: work },
                      right: { wait: wait2, idle: idle2, work: work2 },
                    });
                    handleDownloadCSV([{ ...obj }]);
                    frameCountRef.current = 0;
                    socket.disconnect();
                    dispatch(setInference(false));
                    clear();
                    setIsPaused(true);
                    setVideoEnded(false);
                    setlivevideo(false);
                    setdata([]);
                    handleClose();
                    setCheckLive(false);

                    dispatch(stopVideo()).then((res) => {});
                  }}
                >
                  download csv
                </Button>

                <Button
                  style={{
                    color: "white",
                    border: "1px solid #102633",
                    width: "33%",
                    borderRadius: 0,
                    backgroundColor: "#102633",
                  }}
                  onClick={() => {
                    frameCountRef.current = 0;
                    socket.disconnect();
                    dispatch(setInference(false));
                    clear();
                    setIsPaused(true);
                    setVideoEnded(false);
                    setlivevideo(false);
                    setdata([]);
                    handleClose();
                    dispatch(stopVideo()).then((res) => {});
                  }}
                >
                  {" "}
                  stop{" "}
                </Button>

                <Button
                  style={{
                    color: "black",
                    border: "1px solid #102633",
                    width: "33%",
                    borderRadius: 0,
                  }}
                  onClick={handleClose}
                >
                  cancel
                </Button>
              </Grid>
            </Grid>
          </Modal>
          {/* modal to check weather video has been ended */}
          <Modal
            open={videoEnded && !livevideo}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Grid
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 320,
                bgcolor: "background.paper",
                boxShadow: 24,
              }}
            >
              <Typography
                id="modal-modal-description"
                style={{ textAlign: "center", padding: "10px" }}
              >
                inference of video has been completed
              </Typography>

              <Grid
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  style={{
                    color: "white",

                    border: "1px solid #102633",

                    width: "50%",

                    borderRadius: 0,

                    backgroundColor: "#102633",
                  }}
                  onClick={() => {
                    frameCountRef.current = 0;
                    socket.disconnect();
                    dispatch(setInference(false));
                    clear();
                    setIsPaused(true);
                    setVideoEnded(false);
                    setlivevideo(false);
                    setdata([]);
                    handleClose();
                    setCheckLive(false);

                    dispatch(stopVideo()).then((res) => {});
                  }}
                >
                  proceed
                </Button>
                <Button
                  style={{
                    color: "white",
                    border: "1px solid #102633",
                    width: "50%",
                    borderRadius: 0,
                    backgroundColor: "#102633",
                  }}
                  onClick={() => {
                    handleDownloadCSV({
                      left: { wait: wait, idle: idle, work: work },
                      right: { wait: wait2, idle: idle2, work: work2 },
                    });

                    handleDownloadCSV([{ ...obj }]);

                    frameCountRef.current = 0;
                    socket?.disconnect();
                    dispatch(setInference(false));
                    clear();
                    setIsPaused(true);
                    setVideoEnded(false);
                    setlivevideo(false);
                    setdata([]);
                    handleClose();
                    dispatch(stopVideo()).then((res) => {});
                  }}
                >
                  download CSV
                </Button>
              </Grid>
            </Grid>
          </Modal>
          {/* modal to check for multiple cameras in computer */}
          <Modal
            open={cameraModal}
            onClose={handleClose2}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Grid
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 380,
                bgcolor: "background.paper",
                boxShadow: 24,
                padding: "1rem 2rem",
                borderRadius: "6px",
              }}
            >
              <Grid style={{ padding: "1.25rem 0rem" }}>
                please select a camera to continue
              </Grid>
              <FormControl fullWidth style={{ paddingBottom: "0.25rem" }}>
                <InputLabel id="demo-simple-select-label">camera</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCamera}
                  label="camera"
                  onChange={handleCameraChange}
                  size={"small"}
                >
                  {cameras.map((camera) => (
                    <MenuItem key={camera.deviceId} value={camera.deviceId}>
                      {camera.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Modal>

          {/* modal to add the mask */}
          <Modal
            open={maskModal}
            onClose={handleClose3}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Grid
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 380,
                bgcolor: "background.paper",
                boxShadow: 24,
                padding: "1rem 2rem",
                borderRadius: "6px",
              }}
            >
              <Grid style={{ padding: "1.25rem 0rem" }}>
                please select a camera in the factory to continue
              </Grid>
              <FormControl fullWidth style={{ paddingBottom: "0.25rem" }}>
                <InputLabel id="demo-simple-select-label">camera</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCamera}
                  label="camera"
                  onChange={handelMaskChange}
                  size={"small"}
                >
                  <MenuItem value={"199"}>cam13</MenuItem>
                  <MenuItem value={"1100"}>cam14</MenuItem>
                  <MenuItem value={"1101"}>cam15</MenuItem>
                  <MenuItem value={"1102"}>cam16</MenuItem>
                  <MenuItem value={"1103"}>cam17</MenuItem>
                  <MenuItem value={"1104"}>cam18</MenuItem>
                  <MenuItem value={"1105"}>cam19</MenuItem>
                  <MenuItem value={"1106"}>cam20</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Modal>
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;

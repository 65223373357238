import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function Snack({ open, alertSeverity, alertMessage, setOpen }) {
  const CustomizableAlert = React.forwardRef(function CustomizableAlert(
    props,
    ref
  ) {
    const { onClose, severity, message, ...other } = props;

    return (
      <MuiAlert
        elevation={6}
        ref={ref}
        variant="filled"
        onClose={onClose}
        severity={severity}
        {...other}
      >
        {message}
      </MuiAlert>
    );
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <CustomizableAlert
        severity={alertSeverity}
        message={alertMessage}
        sx={{ width: "100%" }}
      />
    </Snackbar>
  );
}

export default Snack;

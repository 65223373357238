// Dependencies imports
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button, Modal, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// Local imports
import {
  logoutApi,
  stopVideo,
  setSecondUser,
  setEmails,
  setInference,
  setroomid,
  setToken,
  setInitialDate,
  setHasStarted,
  setLoading,
  setDaily,
} from "../../slices/authInfo";
import Logo from "../../assets/newlogo.png";
import Settings from "../settings/index";
function Appbar() {
  const [open, setOpen] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [forgotPassswordModal, setForgotPasswordModal] = useState(false);

  const [settingsModal, setSettingsModal] = useState(false);

  const handleOpen = () => setOpen(!open);
  const handleClose = () => setLogoutModal(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const secondUser = useSelector((state) => state.authInfo.secondUser);
  const inference = useSelector((state) => state.authInfo.inference);

  const logout = (type) => {
    if (inference) {
      dispatch(stopVideo());
    }
    dispatch(logoutApi()).then((res) => {
      dispatch(setEmails(""));
      dispatch(setHasStarted(false));
      dispatch(setInference(false));
      dispatch(setroomid(""));
      dispatch(setToken(""));
      dispatch(setLoading(false));
      dispatch(setInitialDate(""));
      dispatch(setSecondUser(false));
      dispatch(setDaily(true));

      if (type == "forgot") {
        navigate("/forgotPassword");
      } else {
        navigate("/");
      }
      dispatch(setSecondUser(false));
      localStorage.clear();
    });
  };
  const email = useSelector((state) => state?.authInfo.email);
  const email2 = useSelector((state) => state?.authInfo.loginToken);

  const [profileExpanded, setProfileExpanded] = useState(false);
  const [ChangePasswordExpanded, setChangePasswordExpanded] = useState(false);

  function closeModal() {
    setSettingsModal(false);
  }

  return (
    <Grid
      container
      style={{
        height: "60%",
        backgroundColor: "#102633",
        minWidth: "1024px",
        width: "100%",
      }}
    >
      <Grid
        item
        xs={6.5}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {" "}
        <img src={Logo} alt="" style={{ height: "80%" }} />{" "}
      </Grid>

      <Grid
        container
        item
        xs={5.5}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Grid item xs={4} xl={3} style={{ color: "#ffffff" }}>
          {" "}
          Welcome {email2.name} !!{" "}
        </Grid>
        <Grid
          item
          xl={2}
          xs={1}
          style={{
            color: "white",
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {" "}
          <Button style={{ color: "white" }} onClick={handleOpen}>
            {" "}
            <AccountCircleIcon />{" "}
          </Button>{" "}
          {/* info panel  */}
          {open && (
            <Grid
              style={{
                position: "absolute",
                backgroundColor: "#ffffff",
                color: "#000000",
                top: 30,
                right: 20,
                width: "300px",
                padding: "16px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                borderRadius: "4px",
                zIndex: 100,
              }}
            >
              <Grid>
                <Grid style={{ fontWeight: "500" }}> {email2.name} </Grid>
                <Grid
                  container
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0px",
                  }}
                >
                  <Grid style={{ marginRight: "10px" }}>
                    {" "}
                    <AccountCircleIcon style={{ fontSize: 40 }} />{" "}
                  </Grid>
                  <Grid style={{}}>
                    <Grid style={{ fontSize: "12px" }}> {email2.role} </Grid>
                    <Grid style={{ fontSize: "12px" }}> {email} </Grid>
                  </Grid>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #666",
                    paddingBottom: "10px",
                    fontSize: "12px",
                  }}
                >
                  License expires on :{" "}
                  <span style={{ color: "#176963 " }}>03/31/2024 </span>
                </Grid>

                <Grid>
                  <Grid
                    onClick={() => {
                      setSettingsModal(true);
                      setProfileExpanded(true);
                    }}
                    style={{
                      fontSize: "12px",
                      color: "#176963",
                      paddingTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    Account Settings
                  </Grid>
                  <Grid
                    style={{
                      fontSize: "12px",
                      color: "#176963",
                      padding: "10px 0px",
                      borderBottom: "1px solid #666",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSettingsModal(true);
                      setChangePasswordExpanded(true);
                    }}
                  >
                    {" "}
                    Change Password
                  </Grid>
                  <Grid style={{ paddingTop: "10px" }}>
                    <Button
                      onClick={() => setLogoutModal(true)}
                      startIcon={<LogoutIcon />}
                      style={{ color: "#176963", fontSize: "12px" }}
                    >
                      Log Out
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      {open && (
        <Grid
          style={{
            position: "fixed",

            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: "5",
          }}
          onClick={() => setOpen(false)}
        >
          {" "}
        </Grid>
      )}

      {/* logout modal */}

      <Modal
        open={logoutModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 320,
            bgcolor: "background.paper",

            boxShadow: 24,
          }}
        >
          <Typography
            id="modal-modal-description"
            style={{ textAlign: "center", padding: "10px" }}
          >
            Are you sure you want to logout ?
          </Typography>

          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{
                color: "black",
                border: "1px solid #102633",
                width: "50%",
                borderRadius: 0,
              }}
              onClick={handleClose}
            >
              {" "}
              no{" "}
            </Button>
            <Button
              style={{
                color: "white",
                border: "1px solid #102633",
                width: "50%",
                borderRadius: 0,
                backgroundColor: "#102633",
              }}
              onClick={logout}
            >
              {" "}
              yes{" "}
            </Button>
          </Grid>
        </Grid>
      </Modal>

      {/* forgot passowrd modal */}

      <Modal
        open={forgotPassswordModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 320,
            bgcolor: "background.paper",

            boxShadow: 24,
          }}
        >
          <Typography
            id="modal-modal-description"
            style={{ textAlign: "center", padding: "10px" }}
          >
            you will be logged out from the application to continue this process
          </Typography>

          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{
                color: "black",
                border: "1px solid #102633",
                width: "50%",
                borderRadius: 0,
              }}
              onClick={() => {
                setForgotPasswordModal(false);
                setSettingsModal(true);
              }}
            >
              {" "}
              no{" "}
            </Button>
            <Button
              style={{
                color: "white",
                border: "1px solid #102633",
                width: "50%",
                borderRadius: 0,
                backgroundColor: "#102633",
              }}
              onClick={() => logout("forgot")}
            >
              {" "}
              yes{" "}
            </Button>
          </Grid>
        </Grid>
      </Modal>

      {/* second user modal */}

      <Modal
        open={secondUser}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 320,
            bgcolor: "background.paper",

            boxShadow: 24,
          }}
        >
          <Typography
            id="modal-modal-description"
            style={{ textAlign: "center", padding: "10px" }}
          >
            Another user has logged in with same credentials
          </Typography>

          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{
                color: "white",
                border: "1px solid #102633",
                width: "100%",
                borderRadius: 0,
                backgroundColor: "#102633",
              }}
              onClick={logout}
            >
              {" "}
              logout{" "}
            </Button>
          </Grid>
        </Grid>
      </Modal>

      {/* settings modal */}
      <Modal
        open={settingsModal}
        onClose={() => {
          setChangePasswordExpanded(false);
          setProfileExpanded(false);
          setSettingsModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80vw",
            bgcolor: "background.paper",

            boxShadow: 24,
          }}
        >
          <Settings
            setSettingsModal={setSettingsModal}
            profileExpanded={profileExpanded}
            setProfileExpanded={setProfileExpanded}
            setChangePasswordExpanded={setChangePasswordExpanded}
            ChangePasswordExpanded={ChangePasswordExpanded}
            forgotPassswordModal={forgotPassswordModal}
            setForgotPasswordModal={setForgotPasswordModal}
          />
        </Grid>
      </Modal>
    </Grid>
  );
}

export default Appbar;

import React, { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useMediaQuery } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VisibilityIcon from "@mui/icons-material/Visibility";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
function Avggraph({ graphdata, avgGraph, setAvgGraph, graphCount }) {
  const [chartKey, setChartKey] = useState(0);
  const matchesLg = useMediaQuery("(min-width:1300px)");
  const matchesXlg = useMediaQuery("(min-width:1600px)");

  const generateChartData = (data) => {
    const improvementTime = [];
    const inactiveTime = [];
    const wasteTime = [];
    const improvementTimeUnit = [];
    const operators = [];

    data?.forEach((operator) => {
      improvementTime.push(operator.improvement_time / 60);
      inactiveTime.push(operator.inactive_time / 60);
      wasteTime.push(operator.waste_time / 60);
      improvementTimeUnit.push(operator.improvement_time_unit / 60);
      operators.push(`Cam${operator.operator}`);
    });

    return {
      labels: operators,
      datasets: [
        {
          label: "Improvement Time",
          data: improvementTime,
          backgroundColor: "#4AB6DF",
          borderColor: "#4AB6DF",
          borderWidth: 1,
          minBarLength: "2",
        },
        {
          label: "Wait Time",
          data: wasteTime,
          backgroundColor: "#7CFDA7",
          borderColor: "#0AA53E",
          borderWidth: 1,
          minBarLength: "2",
        },
        {
          label: "Idle Time",
          data: inactiveTime,
          backgroundColor: "#F26565",
          borderColor: "#033A31",
          borderWidth: 1,
          minBarLength: "2",
        },

        {
          label: "Improvement Time / Unit",
          data: improvementTimeUnit,
          backgroundColor: "#249482",
          borderColor: "#033A31",
          borderWidth: 1,
          minBarLength: "2",
        },
      ],
    };
  };

  const [index, setindex] = useState(0);
  useEffect(() => {
    setindex(0);
  }, [graphdata]);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        maxRotation: 0,
        minRotation: 0,
        grid: {
          display: false,
        },

        ticks: {
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
      },
      y: {
        grid: {
          display: true,
        },

        ticks: {
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
        title: {
          display: false,
          text: "Time (sec)  → ",
          color: "#000000",
          font: {
            size: matchesLg ? 12 : 8,
            padding: 0,
            weight: "bold",
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        align: "end",
        labels: {
          padding: 20,
          boxWidth: 40,
          usePointStyle: true,
          font: {
            size: matchesLg ? 12 : 8,
          },
        },
        itemMargin: 10,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (tooltipItem, data) {
            return tooltipItem.dataset.label;
          },
          afterLabel: function (tooltipItem, data) {
            return convertSecondsToTime(tooltipItem.raw * 60);
          },
        },
      },
    },
  };

  function convertSecondsToTime(seconds) {
    if (isNaN(seconds) || seconds < 0) {
      return "Invalid input";
    }

    const hours = `${Math.floor(seconds / 3600)}`;
    const minutes = `${Math.floor((seconds % 3600) / 60)}`;
    const sec = `${Math.floor(seconds % 60)}`;

    let hours2;
    let minutes2;
    let seconds2;
    if (hours?.length == 1) {
      hours2 = `0${hours}`;
    } else {
      hours2 = `${hours}`;
    }

    if (minutes?.length == 1) {
      minutes2 = `0${minutes}`;
    } else {
      minutes2 = `${minutes}`;
    }

    if (sec?.length == 1) {
      seconds2 = `0${sec}`;
    } else {
      seconds2 = `${sec}`;
    }

    return `${hours2}:${minutes2}:${seconds2}`;
  }
  function change(a) {
    if (a && index < Math.floor(graphdata?.length / 30)) {
      setindex(index + 1);
    }
    if (!a && index > 0) {
      setindex(index - 1);
    }
  }

  return (
    <Grid
      item
      xs={12}
      container
      style={{
        border: "1px solid #9A9A9A",
        height: "100%",
      }}
    >
      <Grid
        container
        item
        xs={12}
        style={{
          height: "100%",
          position: "relative",
          backgroundColor: "white",
          paddingBottom: "0.5rem",
        }}
      >
        <Grid
          container
          style={{
            height: "10%",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={11}
            style={{
              width: "90%",
              textAlign: "center",
              fontWeight: "500",
              fontSize: "16px",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid> AVERAGE OPERATOR EFFICIENCY</Grid>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              height: "100%",
              fontWeight: "500",
              fontSize: "14px",
              textAlign: "right",
            }}
          >
            <Button
              style={{
                height: "100%",
                color: graphCount == 1 ? "#666" : "black",
                cursor: graphCount == 1 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (graphCount > 1) setAvgGraph(false);
              }}
            >
              <VisibilityIcon />
            </Button>
          </Grid>
        </Grid>

        <Grid container style={{ width: "100%", height: "90%" }}>
          <Grid
            item
            xs={0.35}
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "6vh",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Button
              disabled={index == 0}
              sx={{
                width: "1.5vw",
                height: "1.5vw",
                minWidth: "0px",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                backgroundColor:
                  index === 0 ? "lightgray" : "rgba(16, 38, 51, 1)",
                "&:hover": {
                  backgroundColor: "rgba(16, 38, 51, 1) !important",
                },
              }}
              onClick={() => change(false)}
            >
              {" "}
              <ChevronLeftIcon sx={{ color: "white" }} />
            </Button>
            <Grid
              style={{
                position: "absolute",
                transform: "rotate(-90deg)",
                bottom: "30%",
                fontWeight: "bold",
                fontSize: matchesXlg ? 16 : 12,
                width: "120px",
              }}
            >
              {"Time (min) →"}
            </Grid>
          </Grid>
          <Grid item xs={11.3} style={{ width: "100%", height: "100%" }}>
            <Bar
              key={chartKey}
              options={options}
              data={generateChartData(
                graphdata?.slice(0 + index * 30, (index + 1) * 30)
              )}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Grid>
          <Grid
            item
            xs={0.35}
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "6vh",
              justifyContent: "center",
            }}
          >
            <Button
              disabled={
                index == Math.floor(graphdata?.length / 30) ||
                graphdata == undefined
              }
              sx={{
                width: "1.5vw",
                height: "1.5vw",
                minWidth: "0px",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                backgroundColor:
                  index >= Math.floor(graphdata?.length / 30)
                    ? "lightgray"
                    : "rgba(16, 38, 51, 1)",
                "&:hover": {
                  backgroundColor: "rgba(16, 38, 51, 1) !important",
                },
              }}
              onClick={() => change(true)}
            >
              {" "}
              <ChevronRightIcon sx={{ color: "white" }} />
            </Button>
          </Grid>
        </Grid>

        <Grid
          style={{
            position: "absolute",
            bottom: 15,
            width: "15%",
            left: "8vw",
            fontWeight: "500",
            fontSize: matchesXlg ? "16px" : "13px",
          }}
        >
          Operators →
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Avggraph;
